function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT = "GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";

// Fetches CAFCI share prices for a particular fund class and date between from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCafciPreciosByClaseIdAndFechaBetween = function fetchCafciPreciosByClaseIdAndFechaBetween(claseId, fromFecha, toFecha) {
  var queryString = urlSearchParams({
    size: 999,
    sort: "fecha,desc",
    projection: "chart",
    claseId: claseId,
    fromFecha: fromFecha,
    toFecha: toFecha
  });
  return _defineProperty({
    claseId: claseId,
    fromFecha: fromFecha,
    toFecha: toFecha
  }, CALL_API, {
    types: [GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "cafciPrecios/search/findByClaseIdAndFechaBetween".concat(queryString)
  });
};

// Fetches CAFCI share prices for a particular fund class and date between unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCafciPreciosByClaseIdAndFechaBetween = function loadCafciPreciosByClaseIdAndFechaBetween(claseId, fromFecha, toFecha) {
  return function (dispatch, getState) {
    var key = [claseId, fromFecha, toFecha].join("-");
    var cafciPrecios = getState().entities.cafciPrecios.byClaseIdAndFechaBetween[key];
    if (cafciPrecios) return null;
    return dispatch(fetchCafciPreciosByClaseIdAndFechaBetween(claseId, fromFecha, toFecha));
  };
};
export var GET_CAFCIPRECIOS_REQUEST = "GET_CAFCIPRECIOS_REQUEST";
export var GET_CAFCIPRECIOS_SUCCESS = "GET_CAFCIPRECIOS_SUCCESS";
export var GET_CAFCIPRECIOS_FAILURE = "GET_CAFCIPRECIOS_FAILURE";
export var loadCafciPrecios = function loadCafciPrecios(page, size, idFilterValue, fromDateFilterValue, toDateFilterValue, fromCreatedAtFilterValue, toCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "instant,desc",
      // projection: 'withFundClassAndCurrency',
      idFilterValue: idFilterValue,
      fromDateFilterValue: fromDateFilterValue,
      toDateFilterValue: toDateFilterValue,
      fromCreatedAtFilterValue: fromCreatedAtFilterValue,
      toCreatedAtFilterValue: toCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CAFCIPRECIOS_REQUEST, GET_CAFCIPRECIOS_SUCCESS, GET_CAFCIPRECIOS_FAILURE],
      endpoint: "cafciPrecios/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTCAFCIPRECIOS_REQUEST = "COLLECTCAFCIPRECIOS_REQUEST";
export var COLLECTCAFCIPRECIOS_SUCCESS = "COLLECTCAFCIPRECIOS_SUCCESS";
export var COLLECTCAFCIPRECIOS_FAILURE = "COLLECTCAFCIPRECIOS_FAILURE";
export var RESET_COLLECTCAFCIPRECIOS = "RESET_COLLECTCAFCIPRECIOS";
export var collectCafciPrecios = function collectCafciPrecios(cafciClaseIds, fromInstant, toInstant) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      cafciClaseIds: cafciClaseIds,
      fromInstant: fromInstant,
      toInstant: toInstant
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTCAFCIPRECIOS_REQUEST, COLLECTCAFCIPRECIOS_SUCCESS, COLLECTCAFCIPRECIOS_FAILURE],
      endpoint: "cafciPrecios/collect".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTCAFCIPRECIOS_REQUEST = "ABORTCOLLECTCAFCIPRECIOS_REQUEST";
export var ABORTCOLLECTCAFCIPRECIOS_SUCCESS = "ABORTCOLLECTCAFCIPRECIOS_SUCCESS";
export var ABORTCOLLECTCAFCIPRECIOS_FAILURE = "ABORTCOLLECTCAFCIPRECIOS_FAILURE";
export var RESET_ABORTCOLLECTCAFCIPRECIOS = "RESET_ABORTCOLLECTCAFCIPRECIOS";
export var abortCollectCafciPrecios = function abortCollectCafciPrecios() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTCAFCIPRECIOS_REQUEST, ABORTCOLLECTCAFCIPRECIOS_SUCCESS, ABORTCOLLECTCAFCIPRECIOS_FAILURE],
      endpoint: "cafciPrecios/abortCollect",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTCAFCIPRECIOSCONTEXT_REQUEST = "GET_COLLECTCAFCIPRECIOSCONTEXT_REQUEST";
export var GET_COLLECTCAFCIPRECIOSCONTEXT_SUCCESS = "GET_COLLECTCAFCIPRECIOSCONTEXT_SUCCESS";
export var GET_COLLECTCAFCIPRECIOSCONTEXT_FAILURE = "GET_COLLECTCAFCIPRECIOSCONTEXT_FAILURE";
export var RESET_GET_COLLECTCAFCIPRECIOSCONTEXT = "RESET_GET_COLLECTCAFCIPRECIOSCONTEXT";
export var loadCollectCafciPreciosContext = function loadCollectCafciPreciosContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTCAFCIPRECIOSCONTEXT_REQUEST, GET_COLLECTCAFCIPRECIOSCONTEXT_SUCCESS, GET_COLLECTCAFCIPRECIOSCONTEXT_FAILURE],
      endpoint: "cafciPrecios/collectContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};