export var CHECK_BOOSTS = "CHECK_BOOSTS";
export var checkBoost = function checkBoost(id) {
  return {
    id: id,
    type: CHECK_BOOSTS
  };
};
export var UNCHECK_BOOSTS = "UNCHECK_BOOSTS";
export var uncheckBoost = function uncheckBoost(id) {
  return {
    id: id,
    type: UNCHECK_BOOSTS
  };
};
export var CHECK_ALL_BOOSTS = "CHECK_ALL_BOOSTS";
export var checkAllBoost = function checkAllBoost() {
  return {
    type: CHECK_ALL_BOOSTS
  };
};
export var UNCHECK_ALL_BOOSTS = "UNCHECK_ALL_BOOSTS";
export var uncheckAllBoost = function uncheckAllBoost() {
  return {
    type: UNCHECK_ALL_BOOSTS
  };
};