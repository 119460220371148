import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var documentTypeApiCalls = {
  fetchDocumentTypesByIdIn: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.ids.join(",");
    },
    types: [ActionTypes.GET_DOCUMENTTYPESBYIDIN_REQUEST, ActionTypes.GET_DOCUMENTTYPESBYIDIN_SUCCESS, ActionTypes.GET_DOCUMENTTYPESBYIDIN_FAILURE, ActionTypes.RESET_GET_DOCUMENTTYPESBYIDIN]
  })
};
export default documentTypeApiCalls;