import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyGoalInvestmentItemUserBalanceApiCalls = {
  loadBrokerCurrencyGoalInvestmentItemUserBalancesByGoalIdAndUserIdAndDate: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.date].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT]
  }),
  loadBrokerCurrencyGoalInvestmentItemUserBalancesByUserIdAndDate: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.date].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_REQUEST, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_SUCCESS, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT]
  }),
  loadBrokerCurrencyGoalInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.goalId === -1 ? null : action.goalId, action.investmentItemId, action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  }),
  loadBrokerCurrencyGoalInvestmentItemUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstant: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.goalId || "null", action.userId, action.instant].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_REQUEST, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_SUCCESS, ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT]
  })
};
export default currencyGoalInvestmentItemUserBalanceApiCalls;