import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var goalApiCalls = {
  postGoal: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOAL_REQUEST, ActionTypes.POST_GOAL_SUCCESS, ActionTypes.POST_GOAL_FAILURE, ActionTypes.RESET_POST_GOAL]
  }),
  postGoalDueDate: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALDUEDATE_REQUEST, ActionTypes.POST_GOALDUEDATE_SUCCESS, ActionTypes.POST_GOALDUEDATE_FAILURE, ActionTypes.RESET_POST_GOALDUEDATE]
  }),
  postGoalPicture: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALPICTURE_REQUEST, ActionTypes.POST_GOALPICTURE_SUCCESS, ActionTypes.POST_GOALPICTURE_FAILURE, ActionTypes.RESET_POST_GOALPICTURE]
  }),
  postGoalAmounts: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALAMOUNTS_REQUEST, ActionTypes.POST_GOALAMOUNTS_SUCCESS, ActionTypes.POST_GOALAMOUNTS_FAILURE, ActionTypes.RESET_POST_GOALAMOUNTS]
  }),
  postGoalMembers: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALMEMBERS_REQUEST, ActionTypes.POST_GOALMEMBERS_SUCCESS, ActionTypes.POST_GOALMEMBERS_FAILURE, ActionTypes.RESET_POST_GOALMEMBERS]
  }),
  postGoalExclusionPreferences: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALEXCLUSIONPREFERENCES_REQUEST, ActionTypes.POST_GOALEXCLUSIONPREFERENCES_SUCCESS, ActionTypes.POST_GOALEXCLUSIONPREFERENCES_FAILURE, ActionTypes.RESET_POST_GOALEXCLUSIONPREFERENCES]
  })
};
export default goalApiCalls;