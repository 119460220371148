import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var walletViewApiCalls = {
  fetchWalletViewByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_WALLETVIEWBYUSERID_REQUEST, ActionTypes.GET_WALLETVIEWBYUSERID_SUCCESS, ActionTypes.GET_WALLETVIEWBYUSERID_FAILURE, ActionTypes.RESET_GET_WALLETVIEWBYUSERID]
  })
};
export default walletViewApiCalls;