function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT = "RESET_GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT";

// Fetches balances for a particular goal and user and instant from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBrokerCurrencyUserBalancesByBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat = function fetchBrokerCurrencyUserBalancesByBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat(brokerId, currencyId, userId, fromInstant, toInstant, dateFormat) {
  var queryString = urlSearchParams({
    projection: "chart",
    brokerId: brokerId,
    currencyId: currencyId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    brokerId: brokerId,
    currencyId: currencyId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat
  }, CALL_API, {
    types: [GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "brokerCurrencyUserBalances/search/findByBroker_IdAndCurrency_IdAndUser_IdAndInstantBetweenGroupByDateFormatOrderByInstantDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular goal and user and instant unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBrokerCurrencyUserBalancesByBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat = function loadBrokerCurrencyUserBalancesByBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat(brokerId, currencyId, userId, fromInstant, toInstant, dateFormat, timeZoneName, forceUpdate) {
  return function (dispatch, getState) {
    var key = [brokerId, currencyId, userId, fromInstant, toInstant, dateFormat].join("-");
    var balances = getState().entities.brokerCurrencyUserBalances.byBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat[key];
    if (balances && !forceUpdate) return null;
    return dispatch(fetchBrokerCurrencyUserBalancesByBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat(brokerId, currencyId, userId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};