import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var notificationTabApiCalls = {
  loadNotificationTabByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_NOTIFICATIONTABBYUSERID_REQUEST, ActionTypes.GET_NOTIFICATIONTABBYUSERID_SUCCESS, ActionTypes.GET_NOTIFICATIONTABBYUSERID_FAILURE, ActionTypes.RESET_GET_NOTIFICATIONTABBYUSERID]
  })
};
export default notificationTabApiCalls;