import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var priceLineChartDatasetApiCalls = {
  fetchPriceLineChartDatasetsByInvestmentItemId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.investmentItemId.toString();
    },
    types: [ActionTypes.GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_REQUEST, ActionTypes.GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_SUCCESS, ActionTypes.GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_FAILURE, ActionTypes.RESET_GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID]
  })
};
export default priceLineChartDatasetApiCalls;