function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_REQUEST = "GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_REQUEST";
export var GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_SUCCESS = "GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_SUCCESS";
export var GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_FAILURE = "GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_FAILURE";
export var RESET_GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID = "RESET_GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID";

// Fetches event reactions for a particular event id and user÷ and followee from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchEventReactionsByEventIdAndAuthUserId = function fetchEventReactionsByEventIdAndAuthUserId(eventId, userId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    eventId: eventId,
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    eventId: eventId,
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_REQUEST, GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_SUCCESS, GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_FAILURE],
    endpoint: "eventReactions/search/findByEventIdAndAuthUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches event reactions for a particular event id and user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadEventReactionsByEventIdAndAuthUserId = function loadEventReactionsByEventIdAndAuthUserId(eventId, userId, maxId, sinceId, count) {
  return function (dispatch, getState) {
    var key = [eventId, userId].join("-");
    var call = getState().apiCalls.loadEventReactionsByEventIdAndAuthUserId[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchEventReactionsByEventIdAndAuthUserId(eventId, userId, maxId, sinceId, count));
  };
};
export var POST_EVENTREACTION_REQUEST = "POST_EVENTREACTION_REQUEST";
export var POST_EVENTREACTION_SUCCESS = "POST_EVENTREACTION_SUCCESS";
export var POST_EVENTREACTION_FAILURE = "POST_EVENTREACTION_FAILURE";
export var RESET_POST_EVENTREACTION = "RESET_POST_EVENTREACTION";
export var postEventReaction = function postEventReaction(eventReaction) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      eventReaction: eventReaction
    }, CALL_API, {
      types: [POST_EVENTREACTION_REQUEST, POST_EVENTREACTION_SUCCESS, POST_EVENTREACTION_FAILURE],
      endpoint: "eventReactions",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(eventReaction),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostEventReaction = function resetPostEventReaction() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_EVENTREACTION
    });
  };
};
export var DELETE_EVENTREACTION_REQUEST = "DELETE_EVENTREACTION_REQUEST";
export var DELETE_EVENTREACTION_SUCCESS = "DELETE_EVENTREACTION_SUCCESS";
export var DELETE_EVENTREACTION_FAILURE = "DELETE_EVENTREACTION_FAILURE";
export var RESET_DELETE_EVENTREACTION = "RESET_DELETE_EVENTREACTION";
export var deleteEventReaction = function deleteEventReaction(eventReaction) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      eventReaction: eventReaction
    }, CALL_API, {
      types: [DELETE_EVENTREACTION_REQUEST, DELETE_EVENTREACTION_SUCCESS, DELETE_EVENTREACTION_FAILURE],
      endpoint: "reactions/".concat(eventReaction.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var resetDeleteEventReaction = function resetDeleteEventReaction() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DELETE_EVENTREACTION
    });
  };
};