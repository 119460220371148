import * as ActionTypes from "../../actions";
var campaignRows = function campaignRows() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_CAMPAIGNS_SUCCESS:
      return action.response._embedded ? action.response._embedded.campaigns : [];
    case ActionTypes.POST_CAMPAIGN_SUCCESS:
      var embedded = action.response._embedded;
      return embedded ? state.map(function (campaign) {
        return campaign.id === embedded.id ? embedded : campaign;
      }) : state;
    case ActionTypes.DELETE_CAMPAIGN_SUCCESS:
      return state.filter(function (campaign) {
        return campaign.id !== action.campaign.id;
      });
    default:
      return state;
  }
};
export default campaignRows;