import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemFollowerApiCalls = {
  postInvestmentItemFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.investmentItemFollower.userId, action.investmentItemFollower.investmentItemId].join("-");
    },
    types: [ActionTypes.POST_INVESTMENTITEMFOLLOWER_REQUEST, ActionTypes.POST_INVESTMENTITEMFOLLOWER_SUCCESS, ActionTypes.POST_INVESTMENTITEMFOLLOWER_FAILURE, ActionTypes.RESET_POST_INVESTMENTITEMFOLLOWER]
  }),
  deleteInvestmentItemFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.investmentItemFollower.id.toString();
    },
    types: [ActionTypes.DELETE_INVESTMENTITEMFOLLOWER_REQUEST, ActionTypes.DELETE_INVESTMENTITEMFOLLOWER_SUCCESS, ActionTypes.DELETE_INVESTMENTITEMFOLLOWER_FAILURE, ActionTypes.RESET_DELETE_INVESTMENTITEMFOLLOWER]
  })
};
export default investmentItemFollowerApiCalls;