export var CHECK_PRICEROW = "CHECK_PRICEROW";
export var checkPriceRow = function checkPriceRow(id) {
  return {
    id: id,
    type: CHECK_PRICEROW
  };
};
export var UNCHECK_PRICEROW = "UNCHECK_PRICEROW";
export var uncheckPriceRow = function uncheckPriceRow(id) {
  return {
    id: id,
    type: UNCHECK_PRICEROW
  };
};
export var CHECK_ALL_PRICEROWS = "CHECK_ALL_PRICEROWS";
export var checkAllPriceRows = function checkAllPriceRows() {
  return {
    type: CHECK_ALL_PRICEROWS
  };
};
export var UNCHECK_ALL_PRICEROWS = "UNCHECK_ALL_PRICEROWS";
export var uncheckAllPriceRows = function uncheckAllPriceRows() {
  return {
    type: UNCHECK_ALL_PRICEROWS
  };
};