import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyApiCalls = {
  loadCurrencies: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CURRENCIES_REQUEST, ActionTypes.GET_CURRENCIES_SUCCESS, ActionTypes.GET_CURRENCIES_FAILURE, ActionTypes.RESET_GET_CURRENCIES]
  }),
  loadCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyGoalUserBalancesGoalId, action.currencyGoalUserBalancesUserId].join("-");
    },
    types: [ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_REQUEST, ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_SUCCESS, ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_FAILURE, ActionTypes.RESET_GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID]
  }),
  loadCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyGoalUserBalancesGoalId, action.currencyGoalUserBalancesUserId, action.currencyGoalUserBalancesInstant].join("-");
    },
    types: [ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_REQUEST, ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_SUCCESS, ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_FAILURE, ActionTypes.RESET_GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE]
  }),
  loadCurrenciesByCurrencyBalancesNotEmpty: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_REQUEST, ActionTypes.GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_SUCCESS, ActionTypes.GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_FAILURE, ActionTypes.RESET_GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY]
  }),
  postCurrency: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_CURRENCY_REQUEST, ActionTypes.POST_CURRENCY_SUCCESS, ActionTypes.POST_CURRENCY_FAILURE, ActionTypes.RESET_POST_CURRENCY]
  })
};
export default currencyApiCalls;