import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var cafciClaseEntities = function cafciClaseEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_CAFCICLASEVIEWBYINVESTMENTITEMIDANDAUTHUSERID_SUCCESS:
      return merge({}, state, {
        byId: action.response.cafciClaseById
      });
    default:
      return state;
  }
};
export default cafciClaseEntities;