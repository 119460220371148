export var CHECK_MYBROKERSINVESTMENTACCOUNTROWS = "CHECK_MYBROKERSINVESTMENTACCOUNTROWS";
export var checkMyBrokersInvestmentAccount = function checkMyBrokersInvestmentAccount(id) {
  return {
    id: id,
    type: CHECK_MYBROKERSINVESTMENTACCOUNTROWS
  };
};
export var UNCHECK_MYBROKERSINVESTMENTACCOUNTROWS = "UNCHECK_MYBROKERSINVESTMENTACCOUNTROWS";
export var uncheckMyBrokersInvestmentAccount = function uncheckMyBrokersInvestmentAccount(id) {
  return {
    id: id,
    type: UNCHECK_MYBROKERSINVESTMENTACCOUNTROWS
  };
};
export var CHECK_ALL_MYBROKERSINVESTMENTACCOUNTROWS = "CHECK_ALL_MYBROKERSINVESTMENTACCOUNTROWS";
export var checkAllMyBrokersInvestmentAccount = function checkAllMyBrokersInvestmentAccount() {
  return {
    type: CHECK_ALL_MYBROKERSINVESTMENTACCOUNTROWS
  };
};
export var UNCHECK_ALL_MYBROKERSINVESTMENTACCOUNTROWS = "UNCHECK_ALL_MYBROKERSINVESTMENTACCOUNTROWS";
export var uncheckAllMyBrokersInvestmentAccount = function uncheckAllMyBrokersInvestmentAccount() {
  return {
    type: UNCHECK_ALL_MYBROKERSINVESTMENTACCOUNTROWS
  };
};