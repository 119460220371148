function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_REQUEST = "GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_REQUEST";
export var GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS = "GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS";
export var GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_FAILURE = "GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_FAILURE";
export var RESET_GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID = "RESET_GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID";
export var fetchBrokerMentionListItemsByBrokerMentionBrokerIdAndAuthUserId = function fetchBrokerMentionListItemsByBrokerMentionBrokerIdAndAuthUserId(brokerMentionBrokerId, timeZoneName, authUserId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    brokerMentionBrokerId: brokerMentionBrokerId,
    timeZoneName: timeZoneName,
    authUserId: authUserId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    brokerMentionBrokerId: brokerMentionBrokerId,
    timeZoneName: timeZoneName,
    authUserId: authUserId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_REQUEST, GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS, GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_FAILURE],
    endpoint: "brokerMentionListItems/search/findByBrokerMentionBrokerIdAndAuthUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};