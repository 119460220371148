import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var watchlistItemListItemApiCalls = {
  fetchWatchlistItemListItemsByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_WATCHLISTITEMLISTITEMSBYUSERID_REQUEST, ActionTypes.GET_WATCHLISTITEMLISTITEMSBYUSERID_SUCCESS, ActionTypes.GET_WATCHLISTITEMLISTITEMSBYUSERID_FAILURE, ActionTypes.RESET_GET_WATCHLISTITEMLISTITEMSBYUSERID]
  })
};
export default watchlistItemListItemApiCalls;