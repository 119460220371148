import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var eventListItemApiCalls = {
  fetchEventListItemsByUserIdAndEventTypeNameIn: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.eventTypeNames].join("-");
    },
    types: [ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_REQUEST, ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS, ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_FAILURE, ActionTypes.RESET_GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN]
  })
};
export default eventListItemApiCalls;