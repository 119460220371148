import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var boostListItemApiCalls = {
  fetchBoostListItemsByBoostUserIdAndBoostStateInAndBoostDeletedAtIsNull: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.boostUserId, action.boostStates].join("-");
    },
    types: [ActionTypes.GET_BOOSTLISTITEMBYBOOSTUSERIDANDBOOSTSTATEINANDDELETEDATISNULL_REQUEST, ActionTypes.GET_BOOSTLISTITEMBYBOOSTUSERIDANDBOOSTSTATEINANDDELETEDATISNULL_SUCCESS, ActionTypes.GET_BOOSTLISTITEMBYBOOSTUSERIDANDBOOSTSTATEINANDDELETEDATISNULL_FAILURE, ActionTypes.RESET_GET_BOOSTLISTITEMBYBOOSTUSERIDANDBOOSTSTATEINANDDELETEDATISNULL]
  })
};
export default boostListItemApiCalls;