function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BOOSTSBYSTATEANDUSERID_REQUEST = "GET_BOOSTSBYSTATEANDUSERID_REQUEST";
export var GET_BOOSTSBYSTATEANDUSERID_SUCCESS = "GET_BOOSTSBYSTATEANDUSERID_SUCCESS";
export var GET_BOOSTSBYSTATEANDUSERID_FAILURE = "GET_BOOSTSBYSTATEANDUSERID_FAILURE";
export var RESET_GET_BOOSTSBYSTATEANDUSERID = "RESET_GET_BOOSTSBYSTATEANDUSERID";

// Fetches boosts for a particular  user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBoostsByStateAndUserId = function fetchBoostsByStateAndUserId(state, userId) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    projection: "full",
    state: state,
    userId: userId
  });
  return _defineProperty({
    state: state,
    userId: userId
  }, CALL_API, {
    types: [GET_BOOSTSBYSTATEANDUSERID_REQUEST, GET_BOOSTSBYSTATEANDUSERID_SUCCESS, GET_BOOSTSBYSTATEANDUSERID_FAILURE],
    endpoint: "boosts/search/findByStateAndUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches boosts for a particular user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBoostsByStateAndUserId = function loadBoostsByStateAndUserId(state, userId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [state, userId].join("-");
    var boosts = getState().entities.boosts.byStateAndUserId[key];
    if (boosts && !forceUpdate) return null;
    var call = getState().apiCalls.loadBoostsByStateAndUserId[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchBoostsByStateAndUserId(state, userId));
  };
};
export var GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_REQUEST = "GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_REQUEST";
export var GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_SUCCESS = "GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_SUCCESS";
export var GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_FAILURE = "GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_FAILURE";
export var RESET_GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY = "RESET_GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY";

// Fetches boosts for a particular  user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty = function fetchBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty(state, currencyId, userId) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    projection: "full",
    state: state,
    currencyId: currencyId,
    userId: userId
  });
  return _defineProperty({
    state: state,
    currencyId: currencyId,
    userId: userId
  }, CALL_API, {
    types: [GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_REQUEST, GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_SUCCESS, GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_FAILURE],
    endpoint: "boosts/search/findByStateAndCurrency_IdAndUser_IdAndOperationsIsEmpty".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches boosts for a particular user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty = function loadBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty(state, currencyId, userId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [state, currencyId, userId].join("-");
    var boosts = getState().entities.boosts.byStateAndCurrencyIdAndUserIdAndOperationsIsEmpty[key];
    if (boosts && !forceUpdate) return null;
    var call = getState().apiCalls.loadBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty(state, currencyId, userId));
  };
};
export var GET_BOOSTS_REQUEST = "GET_BOOSTS_REQUEST";
export var GET_BOOSTS_SUCCESS = "GET_BOOSTS_SUCCESS";
export var GET_BOOSTS_FAILURE = "GET_BOOSTS_FAILURE";
export var loadBoosts = function loadBoosts(page, size, idFilterValue, userIdFilterValue, currencyIdFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "withCurrencyAndUserAndOperations",
      idFilterValue: idFilterValue,
      userIdFilterValue: userIdFilterValue,
      currencyIdFilterValue: currencyIdFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BOOSTS_REQUEST, GET_BOOSTS_SUCCESS, GET_BOOSTS_FAILURE],
      endpoint: "boosts/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_BOOST_REQUEST = "POST_BOOST_REQUEST";
export var POST_BOOST_SUCCESS = "POST_BOOST_SUCCESS";
export var POST_BOOST_FAILURE = "POST_BOOST_FAILURE";
export var RESET_POST_BOOST = "RESET_POST_BOOST";
export var postBoost = function postBoost(boost) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_BOOST_REQUEST, POST_BOOST_SUCCESS, POST_BOOST_FAILURE],
      endpoint: "boosts",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(boost),
        credentials: "same-origin"
      }
    }));
  };
};
export var DELETE_BOOSTS_REQUEST = "DELETE_BOOSTS_REQUEST";
export var DELETE_BOOSTS_SUCCESS = "DELETE_BOOSTS_SUCCESS";
export var DELETE_BOOSTS_FAILURE = "DELETE_BOOSTS_FAILURE";
export var RESET_DELETE_BOOST = "RESET_DELETE_BOOST";
export var deleteBoosts = function deleteBoosts(boosts) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_BOOSTS_REQUEST, DELETE_BOOSTS_SUCCESS, DELETE_BOOSTS_FAILURE],
      endpoint: "boosts",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(boosts),
        credentials: "same-origin"
      }
    }));
  };
};