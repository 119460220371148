import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentAccountApiCalls = {
  postInvestmentAccount: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.investmentAccount.brokerId, action.investmentAccount.userId].join("-");
    },
    types: [ActionTypes.POST_INVESTMENTACCOUNT_REQUEST, ActionTypes.POST_INVESTMENTACCOUNT_SUCCESS, ActionTypes.POST_INVESTMENTACCOUNT_FAILURE, ActionTypes.RESET_POST_INVESTMENTACCOUNT]
  }),
  enableInvestmentAccount: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.investmentAccount.id.toString();
    },
    types: [ActionTypes.ENABLEINVESTMENTACCOUNT_REQUEST, ActionTypes.ENABLEINVESTMENTACCOUNT_SUCCESS, ActionTypes.ENABLEINVESTMENTACCOUNT_FAILURE, ActionTypes.RESET_ENABLEINVESTMENTACCOUNT]
  }),
  loadInvestmentAccountsByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_INVESTMENTACCOUNTSBYUSERID_REQUEST, ActionTypes.GET_INVESTMENTACCOUNTSBYUSERID_SUCCESS, ActionTypes.GET_INVESTMENTACCOUNTSBYUSERID_FAILURE, ActionTypes.RESET_GET_INVESTMENTACCOUNTSBYUSERID]
  })
};
export default investmentAccountApiCalls;