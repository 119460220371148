function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_REQUEST = "GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_REQUEST";
export var GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_SUCCESS = "GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_SUCCESS";
export var GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_FAILURE = "GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_FAILURE";
export var RESET_GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID = "RESET_GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID";

// Fetches external balance for a particular broker and user.
// Relies on Redux Thunk middleware.
export var loadExternalBalancesByBrokerIdAndInvestmentItemIdAndUserId = function loadExternalBalancesByBrokerIdAndInvestmentItemIdAndUserId(brokerId, investmentItemId, userId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "basic",
      brokerId: brokerId,
      investmentItemId: investmentItemId,
      userId: userId
    });
    return dispatch(_defineProperty({
      brokerId: brokerId,
      investmentItemId: investmentItemId,
      userId: userId
    }, CALL_API, {
      types: [GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_REQUEST, GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_SUCCESS, GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_FAILURE],
      endpoint: "externalBalances/fetchByBrokerIdAndInvestmentItemIdAndUserId".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST = "COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST";
export var COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS = "COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS";
export var COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE = "COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE";
export var RESET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES = "RESET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES";
export var collectExternalBalances = function collectExternalBalances() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST, COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS, COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE],
      endpoint: "externalBalances/collect",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST = "ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST";
export var ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS = "ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS";
export var ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE = "ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE";
export var RESET_ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES = "RESET_ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES";
export var abortCollectExternalBalances = function abortCollectExternalBalances() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST, ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS, ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE],
      endpoint: "externalBalances/abortCollect",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_REQUEST = "GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_REQUEST";
export var GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_SUCCESS = "GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_SUCCESS";
export var GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_FAILURE = "GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_FAILURE";
export var RESET_GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT = "RESET_GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT";
export var loadCollectExternalBalancesContext = function loadCollectExternalBalancesContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_REQUEST, GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_SUCCESS, GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_FAILURE],
      endpoint: "externalBalances/collectContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};