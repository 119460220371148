export var SET_CafciPrecioLINECHART_INSTANTS = "SET_CafciPrecioLINECHART_INSTANTS";
export var setCafciPrecioLineChartInstants = function setCafciPrecioLineChartInstants(cafciClaseId, fromInstant, toInstant) {
  return {
    cafciClaseId: cafciClaseId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    type: SET_CafciPrecioLINECHART_INSTANTS
  };
};
export var SET_CafciPrecioLINECHART_DATATYPE = "SET_CafciPrecioLINECHART_DATATYPE";
export var setCafciPrecioLineChartDataType = function setCafciPrecioLineChartDataType(cafciClaseId, dataType) {
  return {
    cafciClaseId: cafciClaseId,
    dataType: dataType,
    type: SET_CafciPrecioLINECHART_DATATYPE
  };
};