function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_REQUEST = "GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_REQUEST";
export var GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_SUCCESS = "GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_SUCCESS";
export var GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_FAILURE = "GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_FAILURE";
export var RESET_GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN = "RESET_GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN";

// Fetches a investmentVariantsView for a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchInvestmentItemsViewByMarketCountryIdIn = function fetchInvestmentItemsViewByMarketCountryIdIn(marketCountryIds, timeZoneName, authUserId) {
  var queryString = urlSearchParams({
    marketCountryIds: marketCountryIds,
    timeZoneName: timeZoneName,
    authUserId: authUserId
  });
  return _defineProperty({
    marketCountryIds: marketCountryIds,
    timeZoneName: timeZoneName,
    authUserId: authUserId
  }, CALL_API, {
    types: [GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_REQUEST, GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_SUCCESS, GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_FAILURE],
    endpoint: "investmentItemsViews/search/findByMarketCountryIdIn".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};