import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var userMentionListItemApiCalls = {
  fetchUserMentionListItemsByUserMentionUserIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userMentionUserId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID_REQUEST, ActionTypes.GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID]
  })
};
export default userMentionListItemApiCalls;