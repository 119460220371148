import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var boostApiCalls = {
  loadBoostsByStateAndUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.state, action.userId].join("-");
    },
    types: [ActionTypes.GET_BOOSTSBYSTATEANDUSERID_REQUEST, ActionTypes.GET_BOOSTSBYSTATEANDUSERID_SUCCESS, ActionTypes.GET_BOOSTSBYSTATEANDUSERID_FAILURE, ActionTypes.RESET_GET_BOOSTSBYSTATEANDUSERID]
  }),
  loadBoostsByStateAndCurrencyIdAndUserIdAndOperationsIsEmpty: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.state, action.currencyId, action.userId].join("-");
    },
    types: [ActionTypes.GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_REQUEST, ActionTypes.GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_SUCCESS, ActionTypes.GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY_FAILURE, ActionTypes.RESET_GET_BOOSTSBYSTATEANDCURRENCYIDANDUSERIDANDOPERATIONSISEMPTY]
  })
};
export default boostApiCalls;