import merge from "lodash/merge";
import { routerReducer as routing } from "react-router-redux";
import { combineReducers } from "redux";
import * as ActionTypes from "../actions";
import apiCalls from "./apiCalls";
import entities from "./entities";
import pagination from "./pagination/pagination";
import rows from "./rows";
import form from "./form";
import authenticationStatus from "./authenticationStatus";

// Updates error message to notify about the failed fetches.
var errorMessage = function errorMessage() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    error = action.error;
  if (type === ActionTypes.RESET_ERROR_MESSAGE) return null;else if (error) return error;
  return state;
};
var register = function register() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    userToken: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_USERBYTOKEN_SUCCESS:
      return merge({}, state, {
        userToken: action.token
      });
    default:
      return state;
  }
};
var collectPricesFromCafciContext = function collectPricesFromCafciContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTPRICESFROMCAFCICONTEXT_SUCCESS) return action.response;
  return state;
};
var collectPricesFromInvertirOnlineContext = function collectPricesFromInvertirOnlineContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_SUCCESS) return action.response;
  return state;
};
var collectCafciClasesFromFrontContext = function collectCafciClasesFromFrontContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_SUCCESS) return action.response;
  return state;
};
var collectCafciPreciosContext = function collectCafciPreciosContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTCAFCIPRECIOSCONTEXT_SUCCESS) return action.response;
  return state;
};
var collectInvertirOnlineTitlesFromInvertirOnlineContext = function collectInvertirOnlineTitlesFromInvertirOnlineContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_SUCCESS) return action.response;
  return state;
};
var collectInvertirOnlinePricesContext = function collectInvertirOnlinePricesContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTINVERTIRONLINEPRICESCONTEXT_SUCCESS) return action.response;
  return state;
};
var generateOpeningBalancesContext = function generateOpeningBalancesContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_GENERATEOPENINGBALANCESCONTEXT_SUCCESS) return action.response;
  return state;
};
var generateBalancesContext = function generateBalancesContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_GENERATEBALANCESCONTEXT_SUCCESS) return action.response;
  return state;
};
var collectExternalBalancesContext = function collectExternalBalancesContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCESCONTEXT_SUCCESS) return action.response;
  return state;
};
var findRebalanceOperationsContext = function findRebalanceOperationsContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_FINDREBALANCEOPERATIONSCONTEXT_SUCCESS) return action.response;
  return state;
};
var findSplitOperationsContext = function findSplitOperationsContext() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_FINDSPLITOPERATIONSCONTEXT_SUCCESS) return action.response;
  return state;
};
var dashboardStats = function dashboardStats() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  if (type === ActionTypes.GET_DASHBOARDSTATS_SUCCESS) return action.response;
  return state;
};
var appReducer = combineReducers({
  apiCalls: apiCalls,
  entities: entities,
  rows: rows,
  errorMessage: errorMessage,
  authenticationStatus: authenticationStatus,
  routing: routing,
  form: form,
  pagination: pagination,
  register: register,
  collectPricesFromCafciContext: collectPricesFromCafciContext,
  collectInvertirOnlineTitlesFromInvertirOnlineContext: collectInvertirOnlineTitlesFromInvertirOnlineContext,
  collectPricesFromInvertirOnlineContext: collectPricesFromInvertirOnlineContext,
  collectCafciClasesFromFrontContext: collectCafciClasesFromFrontContext,
  collectCafciPreciosContext: collectCafciPreciosContext,
  collectInvertirOnlinePricesContext: collectInvertirOnlinePricesContext,
  generateOpeningBalancesContext: generateOpeningBalancesContext,
  generateBalancesContext: generateBalancesContext,
  collectExternalBalancesContext: collectExternalBalancesContext,
  findRebalanceOperationsContext: findRebalanceOperationsContext,
  findSplitOperationsContext: findSplitOperationsContext,
  dashboardStats: dashboardStats
});
var rootReducer = function rootReducer(state, action) {
  if (action.type === ActionTypes.LOGOUT_SUCCESS) state = undefined;
  return appReducer(state, action);
};
export default rootReducer;