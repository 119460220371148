import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var invertirOnlineTitleApiCalls = {
  collectInvertirOnlineTitlesFromInvertirOnline: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST, ActionTypes.COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS, ActionTypes.COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE, ActionTypes.RESET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE]
  }),
  abortCollectInvertirOnlineTitlesFromInvertirOnline: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST, ActionTypes.ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS, ActionTypes.ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE, ActionTypes.RESET_ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE]
  })
};
export default invertirOnlineTitleApiCalls;