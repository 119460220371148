import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bindTransactionApiCalls = {
  collectBindTransactions: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTBINDOPERATIONS_REQUEST, ActionTypes.COLLECTBINDOPERATIONS_SUCCESS, ActionTypes.COLLECTBINDOPERATIONS_FAILURE, ActionTypes.RESET_COLLECTBINDOPERATIONS]
  }),
  abortCollectBindTransactions: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTBINDOPERATIONS_REQUEST, ActionTypes.ABORTCOLLECTBINDOPERATIONS_SUCCESS, ActionTypes.ABORTCOLLECTBINDOPERATIONS_FAILURE, ActionTypes.RESET_ABORTCOLLECTBINDOPERATIONS]
  })
};
export default bindTransactionApiCalls;