function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_REQUEST = "GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_REQUEST";
export var GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS = "GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS";
export var GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_FAILURE = "GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_FAILURE";
export var RESET_GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID = "RESET_GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID";
export var fetchPostListItemsByPostParentIdAndAuthUserId = function fetchPostListItemsByPostParentIdAndAuthUserId(postParentId, timeZoneName, authUserId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    postParentId: postParentId,
    timeZoneName: timeZoneName,
    authUserId: authUserId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    postParentId: postParentId,
    timeZoneName: timeZoneName,
    authUserId: authUserId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_REQUEST, GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS, GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_FAILURE],
    endpoint: "postListItems/search/findByPostParentId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_REQUEST = "GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_REQUEST";
export var GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS = "GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS";
export var GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_FAILURE = "GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_FAILURE";
export var RESET_GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID = "RESET_GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID";
export var fetchPostListItemsByPostUserIdAndAuthUserId = function fetchPostListItemsByPostUserIdAndAuthUserId(postUserId, timeZoneName, authUserId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    postUserId: postUserId,
    timeZoneName: timeZoneName,
    authUserId: authUserId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    postUserId: postUserId,
    timeZoneName: timeZoneName,
    authUserId: authUserId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_REQUEST, GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS, GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_FAILURE],
    endpoint: "postListItems/search/findByPostUserIdAndAuthUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_REQUEST = "GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_REQUEST";
export var GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_SUCCESS = "GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_SUCCESS";
export var GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_FAILURE = "GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_FAILURE";
export var RESET_GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN = "RESET_GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN";
export var fetchPostListItemsByPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn = function fetchPostListItemsByPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn(postUserId, postGeolocationCountryIds, timeZoneName, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    postUserId: postUserId,
    postGeolocationCountryIds: postGeolocationCountryIds,
    timeZoneName: timeZoneName,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    postUserId: postUserId,
    postGeolocationCountryIds: postGeolocationCountryIds,
    timeZoneName: timeZoneName,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_REQUEST, GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_SUCCESS, GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_FAILURE],
    endpoint: "postListItems/search/findByPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_REQUEST = "GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_REQUEST";
export var GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS = "GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS";
export var GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_FAILURE = "GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_FAILURE";
export var RESET_GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID = "RESET_GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID";
export var fetchPostListItemsByPostIdAndAuthUserId = function fetchPostListItemsByPostIdAndAuthUserId(postId, timeZoneName, authUserId) {
  var queryString = urlSearchParams({
    postId: postId,
    timeZoneName: timeZoneName,
    authUserId: authUserId
  });
  return _defineProperty({
    postId: postId,
    timeZoneName: timeZoneName,
    authUserId: authUserId
  }, CALL_API, {
    types: [GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_REQUEST, GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS, GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_FAILURE],
    endpoint: "postListItems/search/findByPostIdAndAuthUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};