function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_REQUEST = "GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_REQUEST";
export var GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_SUCCESS = "GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_SUCCESS";
export var GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_FAILURE = "GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_FAILURE";
export var RESET_GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID = "RESET_GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID";

// Fetches a particular participant from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchParticipantByInvestmentPartyIdAndUserId = function fetchParticipantByInvestmentPartyIdAndUserId(investmentPartyId, userId) {
  var queryString = urlSearchParams({
    projection: "full",
    investmentPartyId: investmentPartyId,
    userId: userId
  });
  return _defineProperty({
    investmentPartyId: investmentPartyId,
    userId: userId
  }, CALL_API, {
    types: [GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_REQUEST, GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_SUCCESS, GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_FAILURE],
    endpoint: "participants/search/findByInvestmentParty_IdAndUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches a particular participant by userId from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadParticipantByInvestmentPartyIdAndUserId = function loadParticipantByInvestmentPartyIdAndUserId(investmentPartyId, userId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [investmentPartyId, userId].join("-");
    var participant = getState().entities.participants.byInvestmentPartyIdAndUserId[key];
    if (participant && !forceUpdate) return null;
    var call = getState().apiCalls.loadParticipantByInvestmentPartyIdAndUserId[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchParticipantByInvestmentPartyIdAndUserId(investmentPartyId, userId));
  };
};
export var DELETE_PARTICIPANT_REQUEST = "DELETE_PARTICIPANT_REQUEST";
export var DELETE_PARTICIPANT_SUCCESS = "DELETE_PARTICIPANT_SUCCESS";
export var DELETE_PARTICIPANT_FAILURE = "DELETE_PARTICIPANT_FAILURE";
export var RESET_DELETE_PARTICIPANT = "RESET_DELETE_PARTICIPANT";
export var deleteParticipant = function deleteParticipant(participant) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      participant: participant
    }, CALL_API, {
      types: [DELETE_PARTICIPANT_REQUEST, DELETE_PARTICIPANT_SUCCESS, DELETE_PARTICIPANT_FAILURE],
      endpoint: "participants/".concat(participant.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var resetDeleteParticipant = function resetDeleteParticipant() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DELETE_PARTICIPANT
    });
  };
};
export var POST_PARTICIPANT_REQUEST = "POST_PARTICIPANT_REQUEST";
export var POST_PARTICIPANT_SUCCESS = "POST_PARTICIPANT_SUCCESS";
export var POST_PARTICIPANT_FAILURE = "POST_PARTICIPANT_FAILURE";
export var RESET_POST_PARTICIPANT = "RESET_POST_PARTICIPANT";
export var postParticipant = function postParticipant(participant) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      participant: participant
    }, CALL_API, {
      types: [POST_PARTICIPANT_REQUEST, POST_PARTICIPANT_SUCCESS, POST_PARTICIPANT_FAILURE],
      endpoint: "participants",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(participant),
        credentials: "same-origin"
      }
    }));
  };
};
export var postParticipantFailure = function postParticipantFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_PARTICIPANT_FAILURE
    });
  };
};
export var resetPostParticipant = function resetPostParticipant() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_PARTICIPANT
    });
  };
};
export var GET_CONFIRMDELETEPARTICIPANT_REQUEST = "GET_CONFIRMDELETEPARTICIPANT_REQUEST";
export var GET_CONFIRMDELETEPARTICIPANT_SUCCESS = "GET_CONFIRMDELETEPARTICIPANT_SUCCESS";
export var GET_CONFIRMDELETEPARTICIPANT_FAILURE = "GET_CONFIRMDELETEPARTICIPANT_FAILURE";
export var RESET_GET_CONFIRMDELETEPARTICIPANT = "RESET_GET_CONFIRMDELETEPARTICIPANT";
export var confirmDeleteParticipant = function confirmDeleteParticipant(participantId, token) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      participantId: participantId
    }, CALL_API, {
      types: [GET_CONFIRMDELETEPARTICIPANT_REQUEST, GET_CONFIRMDELETEPARTICIPANT_SUCCESS, GET_CONFIRMDELETEPARTICIPANT_FAILURE],
      endpoint: "participants/".concat(participantId, "/confirmDelete?token=").concat(token),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var resetConfirmDeleteParticipant = function resetConfirmDeleteParticipant() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_GET_CONFIRMDELETEPARTICIPANT
    });
  };
};