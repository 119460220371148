import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var validateApiCalls = {
  postValidateDocument: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.personId, action.documentTypeId].join("-");
    },
    types: [ActionTypes.POST_VALIDATEDOCUMENT_REQUEST, ActionTypes.POST_VALIDATEDOCUMENT_SUCCESS, ActionTypes.POST_VALIDATEDOCUMENT_FAILURE, ActionTypes.RESET_POST_VALIDATEDOCUMENT]
  })
};
export default validateApiCalls;