import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var goalMentionListItemApiCalls = {
  fetchGoalMentionListItemsByGoalMentionGoalIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalMentionGoalId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID_REQUEST, ActionTypes.GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID]
  })
};
export default goalMentionListItemApiCalls;