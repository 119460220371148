import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var sentimentTypeApiCalls = {
  fetchSentimentTypes: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_SENTIMENTTYPES_REQUEST, ActionTypes.GET_SENTIMENTTYPES_SUCCESS, ActionTypes.GET_SENTIMENTTYPES_FAILURE, ActionTypes.RESET_GET_SENTIMENTTYPES]
  })
};
export default sentimentTypeApiCalls;