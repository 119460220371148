function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE";
export var RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT = "RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT";

// Fetches balances for a particular goal and user and instant from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBrokerCurrencyGoalInvestmentItemUserBalancesByGoalIdAndUserIdAndInstant = function fetchBrokerCurrencyGoalInvestmentItemUserBalancesByGoalIdAndUserIdAndInstant(goalId, userId, instant) {
  var queryString = urlSearchParams({
    projection: "full",
    goalId: goalId,
    userId: userId,
    instant: instant
  });
  return _defineProperty({
    userId: userId,
    goalId: goalId,
    instant: instant
  }, CALL_API, {
    types: [GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE],
    endpoint: "brokerCurrencyGoalInvestmentItemUserBalances/search/findByGoal_IdAndUserIdAndInstant".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular goal and user and instant unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBrokerCurrencyGoalInvestmentItemUserBalancesByGoalIdAndUserIdAndInstant = function loadBrokerCurrencyGoalInvestmentItemUserBalancesByGoalIdAndUserIdAndInstant(goalId, userId, instant, forceUpdate) {
  return function (dispatch, getState) {
    var key = [goalId, userId, instant].join("-");
    var balances = getState().entities.brokerCurrencyGoalInvestmentItemUserBalances.byGoalIdAndUserIdAndInstant[key];
    if (balances && !forceUpdate) return null;
    return dispatch(fetchBrokerCurrencyGoalInvestmentItemUserBalancesByGoalIdAndUserIdAndInstant(goalId, userId, instant));
  };
};
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT = "RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT";

// Fetches balances for a particular goal and user and instant from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBrokerCurrencyGoalInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat = function fetchBrokerCurrencyGoalInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat(brokerId, currencyId, goalId, investmentItemId, userId, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    projection: "chart",
    brokerId: brokerId,
    currencyId: currencyId,
    goalId: goalId,
    investmentItemId: investmentItemId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    brokerId: brokerId,
    currencyId: currencyId,
    goalId: goalId,
    investmentItemId: investmentItemId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  }, CALL_API, {
    types: [GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "brokerCurrencyGoalInvestmentItemUserBalances/search/findByBroker_IdAndCurrency_IdAndGoal_IdAndInvestmentItem_IdAndUser_IdAndInstantBetweenGroupByDateFormatOrderByInstantDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular goal and user and instant unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBrokerCurrencyGoalInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat = function loadBrokerCurrencyGoalInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat(brokerId, currencyId, goalId, investmentItemId, userId, fromInstant, toInstant, dateFormat, timeZoneName, forceUpdate) {
  return function (dispatch, getState) {
    var key = [brokerId, currencyId, goalId, investmentItemId, userId, fromInstant, toInstant, dateFormat].join("-");
    var balances = getState().entities.brokerCurrencyGoalInvestmentItemUserBalances.byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat[key];
    if (balances && !forceUpdate) return null;
    return dispatch(fetchBrokerCurrencyGoalInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat(brokerId, currencyId, goalId, investmentItemId, userId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_REQUEST = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_REQUEST";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_SUCCESS = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_SUCCESS";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_FAILURE = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_FAILURE";
export var RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT = "RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT";

// Fetches balances for a particular user and instant from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBrokerCurrencyGoalInvestmentItemUserBalancesByUserIdAndInstant = function fetchBrokerCurrencyGoalInvestmentItemUserBalancesByUserIdAndInstant(userId, instant) {
  var queryString = urlSearchParams({
    projection: "full",
    userId: userId,
    instant: instant
  });
  return _defineProperty({
    userId: userId,
    instant: instant
  }, CALL_API, {
    types: [GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_REQUEST, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_SUCCESS, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYUSERIDANDINSTANT_FAILURE],
    endpoint: "brokerCurrencyGoalInvestmentItemUserBalances/search/findByUserIdAndInstant".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular user and instant unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBrokerCurrencyGoalInvestmentItemUserBalancesByUserIdAndInstant = function loadBrokerCurrencyGoalInvestmentItemUserBalancesByUserIdAndInstant(userId, instant, forceUpdate) {
  return function (dispatch, getState) {
    var key = [userId, instant].join("-");
    var balances = getState().entities.brokerCurrencyGoalInvestmentItemUserBalances.byUserIdAndInstant[key];
    if (balances && !forceUpdate) return null;
    return dispatch(fetchBrokerCurrencyGoalInvestmentItemUserBalancesByUserIdAndInstant(userId, instant));
  };
};
export var GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_REQUEST = "GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_REQUEST";
export var GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_SUCCESS = "GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_SUCCESS";
export var GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_FAILURE = "GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_FAILURE";
export var RESET_GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID = "RESET_GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID";

// Fetches balances for a particular broker, currency, goal, investmentItem, and user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadFirstBrokerCurrencyGoalInvestmentItemUserBalanceByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdOrderByInstantDesc = function loadFirstBrokerCurrencyGoalInvestmentItemUserBalanceByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdOrderByInstantDesc(brokerId, currencyId, goalId, investmentItemId, userId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "full",
      brokerId: brokerId,
      currencyId: currencyId,
      goalId: goalId,
      investmentItemId: investmentItemId,
      userId: userId
    });
    return dispatch(_defineProperty({
      brokerId: brokerId,
      currencyId: currencyId,
      goalId: goalId,
      investmentItemId: investmentItemId,
      userId: userId
    }, CALL_API, {
      types: [GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_REQUEST, GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_SUCCESS, GET_FIRSTBROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERID_FAILURE],
      endpoint: "brokerCurrencyGoalInvestmentItemUserBalances/search/findFirstByBroker_IdAndCurrency_IdAndGoal_IdAndInvestmentItem_IdAndUser_IdOrderByInstantDesc".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_REQUEST = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_REQUEST";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_SUCCESS = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_SUCCESS";
export var GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_FAILURE = "GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_FAILURE";
export var RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT = "RESET_GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT";

// Fetches balances for a particular currency, goal, user, and instant unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBrokerCurrencyGoalInvestmentItemUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstant = function loadBrokerCurrencyGoalInvestmentItemUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstant(currencyId, goalId, userId, instant) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "full",
      currencyId: currencyId,
      goalId: goalId,
      userId: userId,
      instant: instant
    });
    return dispatch(_defineProperty({
      currencyId: currencyId,
      goalId: goalId,
      userId: userId,
      instant: instant
    }, CALL_API, {
      types: [GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_REQUEST, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_SUCCESS, GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANT_FAILURE],
      endpoint: "brokerCurrencyGoalInvestmentItemUserBalances/search/findByCurrency_IdAndGoal_IdAndUser_IdAndInstant".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};