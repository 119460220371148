import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var mediaApiCalls = {
  postMedia: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id + action.pigtail + "";
    },
    types: [ActionTypes.POST_MEDIA_REQUEST, ActionTypes.POST_MEDIA_SUCCESS, ActionTypes.POST_MEDIA_FAILURE, ActionTypes.RESET_POST_MEDIA]
  })
};
export default mediaApiCalls;