function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BANKHOLIDAYS_REQUEST = "GET_BANKHOLIDAYS_REQUEST";
export var GET_BANKHOLIDAYS_SUCCESS = "GET_BANKHOLIDAYS_SUCCESS";
export var GET_BANKHOLIDAYS_FAILURE = "GET_BANKHOLIDAYS_FAILURE";
export var loadBankHolidays = function loadBankHolidays(page, size, idFilterValue, countryIdFilterValue, fromDateFilterValue, toDateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "fromDate,desc",
      projection: "withCountry",
      idFilterValue: idFilterValue,
      countryIdFilterValue: countryIdFilterValue,
      fromDateFilterValue: fromDateFilterValue,
      toDateFilterValue: toDateFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BANKHOLIDAYS_REQUEST, GET_BANKHOLIDAYS_SUCCESS, GET_BANKHOLIDAYS_FAILURE],
      endpoint: "bankHolidays/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var CHECK_BANKHOLIDAYS = "CHECK_BANKHOLIDAYS";
export var checkBankHoliday = function checkBankHoliday(id) {
  return {
    id: id,
    type: CHECK_BANKHOLIDAYS
  };
};
export var UNCHECK_BANKHOLIDAYS = "UNCHECK_BANKHOLIDAYS";
export var uncheckBankHoliday = function uncheckBankHoliday(id) {
  return {
    id: id,
    type: UNCHECK_BANKHOLIDAYS
  };
};
export var CHECK_ALL_BANKHOLIDAYS = "CHECK_ALL_BANKHOLIDAYS";
export var checkAllBankHoliday = function checkAllBankHoliday() {
  return {
    type: CHECK_ALL_BANKHOLIDAYS
  };
};
export var UNCHECK_ALL_BANKHOLIDAYS = "UNCHECK_ALL_BANKHOLIDAYS";
export var uncheckAllBankHoliday = function uncheckAllBankHoliday() {
  return {
    type: UNCHECK_ALL_BANKHOLIDAYS
  };
};
export var POST_BANKHOLIDAY_REQUEST = "POST_BANKHOLIDAY_REQUEST";
export var POST_BANKHOLIDAY_SUCCESS = "POST_BANKHOLIDAY_SUCCESS";
export var POST_BANKHOLIDAY_FAILURE = "POST_BANKHOLIDAY_FAILURE";
export var RESET_POST_BANKHOLIDAY = "RESET_POST_BANKHOLIDAY";
export var postBankHoliday = function postBankHoliday(bankHoliday) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_BANKHOLIDAY_REQUEST, POST_BANKHOLIDAY_SUCCESS, POST_BANKHOLIDAY_FAILURE],
      endpoint: "bankHolidays",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bankHoliday),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostBankHoliday = function resetPostBankHoliday() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_BANKHOLIDAY
    });
  };
};
export var DELETE_BANKHOLIDAYS_REQUEST = "DELETE_BANKHOLIDAYS_REQUEST";
export var DELETE_BANKHOLIDAYS_SUCCESS = "DELETE_BANKHOLIDAYS_SUCCESS";
export var DELETE_BANKHOLIDAYS_FAILURE = "DELETE_BANKHOLIDAYS_FAILURE";
export var RESET_DELETE_BANKHOLIDAYS = "RESET_DELETE_BANKHOLIDAYS";
export var deleteBankHolidays = function deleteBankHolidays(bankHolidays) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_BANKHOLIDAYS_REQUEST, DELETE_BANKHOLIDAYS_SUCCESS, DELETE_BANKHOLIDAYS_FAILURE],
      endpoint: "bankHolidays",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bankHolidays),
        credentials: "same-origin"
      }
    }));
  };
};