function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVERTIRONLINETITLESBYNAMECONTAINING_REQUEST = "GET_INVERTIRONLINETITLESBYNAMECONTAINING_REQUEST";
export var GET_INVERTIRONLINETITLESBYNAMECONTAINING_SUCCESS = "GET_INVERTIRONLINETITLESBYNAMECONTAINING_SUCCESS";
export var GET_INVERTIRONLINETITLESBYNAMECONTAINING_FAILURE = "GET_INVERTIRONLINETITLESBYNAMECONTAINING_FAILURE";

//Fetches all CAFCI fund classes from Front CAFCI API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadInvertirOnlineTitlesByDescriptionContaining = function loadInvertirOnlineTitlesByDescriptionContaining() {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVERTIRONLINETITLESBYNAMECONTAINING_REQUEST, GET_INVERTIRONLINETITLESBYNAMECONTAINING_SUCCESS, GET_INVERTIRONLINETITLESBYNAMECONTAINING_FAILURE],
      endpoint: "invertirOnlineTitles/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVERTIRONLINETITLES_REQUEST = "GET_INVERTIRONLINETITLES_REQUEST";
export var GET_INVERTIRONLINETITLES_SUCCESS = "GET_INVERTIRONLINETITLES_SUCCESS";
export var GET_INVERTIRONLINETITLES_FAILURE = "GET_INVERTIRONLINETITLES_FAILURE";
export var loadInvertirOnlineTitles = function loadInvertirOnlineTitles(page, size, idFilterValue, fromDateFilterValue, toDateFilterValue, fromCreatedAtFilterValue, toCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "instant,desc",
      // projection: 'withFundClassAndCurrency',
      idFilterValue: idFilterValue,
      fromDateFilterValue: fromDateFilterValue,
      toDateFilterValue: toDateFilterValue,
      fromCreatedAtFilterValue: fromCreatedAtFilterValue,
      toCreatedAtFilterValue: toCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVERTIRONLINETITLES_REQUEST, GET_INVERTIRONLINETITLES_SUCCESS, GET_INVERTIRONLINETITLES_FAILURE],
      endpoint: "invertirOnlineTitles/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST = "COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST";
export var COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS = "COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS";
export var COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE = "COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE";
export var RESET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE = "RESET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE";
export var collectInvertirOnlineTitlesFromInvertirOnline = function collectInvertirOnlineTitlesFromInvertirOnline() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST, COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS, COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE],
      endpoint: "invertirOnlineTitles/collectFromInvertirOnline",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST = "ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST";
export var ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS = "ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS";
export var ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE = "ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE";
export var RESET_ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE = "RESET_ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE";
export var abortCollectInvertirOnlineTitlesFromInvertirOnline = function abortCollectInvertirOnlineTitlesFromInvertirOnline() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_REQUEST, ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_SUCCESS, ABORTCOLLECTINVERTIRONLINETITLESFROMINVERTIRONLINE_FAILURE],
      endpoint: "invertirOnlineTitles/abortCollectFromInvertirOnline",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_REQUEST = "GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_REQUEST";
export var GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_SUCCESS = "GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_SUCCESS";
export var GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_FAILURE = "GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_FAILURE";
export var RESET_GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT = "RESET_GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT";
export var loadCollectInvertirOnlineTitlesFromInvertirOnlineContext = function loadCollectInvertirOnlineTitlesFromInvertirOnlineContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_REQUEST, GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_SUCCESS, GET_COLLECTINVERTIRONLINETITLESFROMINVERTIRONLINECONTEXT_FAILURE],
      endpoint: "invertirOnlineTitles/collectFromInvertirOnlineContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};