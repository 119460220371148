import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var postReactionApiCalls = {
  postPostReaction: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postReaction.postId].join("-");
    },
    types: [ActionTypes.POST_POSTREACTION_REQUEST, ActionTypes.POST_POSTREACTION_SUCCESS, ActionTypes.POST_POSTREACTION_FAILURE, ActionTypes.RESET_POST_POSTREACTION]
  }),
  deletePostReaction: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postReaction.post.id].join("-");
    },
    types: [ActionTypes.DELETE_POSTREACTION_REQUEST, ActionTypes.DELETE_POSTREACTION_SUCCESS, ActionTypes.DELETE_POSTREACTION_FAILURE, ActionTypes.RESET_DELETE_POSTREACTION]
  })
};
export default postReactionApiCalls;