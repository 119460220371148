function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var POST_RISKPROFILE_REQUEST = "POST_RISKPROFILE_REQUEST";
export var POST_RISKPROFILE_SUCCESS = "POST_RISKPROFILE_SUCCESS";
export var POST_RISKPROFILE_FAILURE = "POST_RISKPROFILE_FAILURE";
export var RESET_POST_RISKPROFILE = "RESET_POST_RISKPROFILE";
export var postRiskProfile = function postRiskProfile(riskProfile) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      riskProfile: riskProfile
    }, CALL_API, {
      types: [POST_RISKPROFILE_REQUEST, POST_RISKPROFILE_SUCCESS, POST_RISKPROFILE_FAILURE],
      endpoint: "riskProfiles",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(riskProfile),
        credentials: "same-origin"
      }
    }));
  };
};
export var postRiskProfileFailure = function postRiskProfileFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_RISKPROFILE_FAILURE
    });
  };
};
export var resetPostRiskProfile = function resetPostRiskProfile() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_RISKPROFILE
    });
  };
};
export var GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_REQUEST = "GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_REQUEST";
export var GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_SUCCESS = "GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_SUCCESS";
export var GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_FAILURE = "GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_FAILURE";
export var RESET_GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC = "RESET_GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC";

// Fetches the last riskProfile for a particular goal and user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchFirstRiskProfileByGoalIdAndUserIdOrderByCreatedAtDesc = function fetchFirstRiskProfileByGoalIdAndUserIdOrderByCreatedAtDesc(goalId, userId) {
  var queryString = urlSearchParams({
    goalId: goalId,
    userId: userId
  });
  return _defineProperty({
    goalId: goalId,
    userId: userId
  }, CALL_API, {
    types: [GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_REQUEST, GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_SUCCESS, GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_FAILURE],
    endpoint: "riskProfiles/search/findFirstByGoal_IdAndUser_IdOrderByCreatedAtDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches the riskProfile for a particular goal and user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadFirstRiskProfileByGoalIdAndUserIdOrderByCreatedAtDesc = function loadFirstRiskProfileByGoalIdAndUserIdOrderByCreatedAtDesc(goalId, userId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [goalId, userId].join("-");
    var riskProfile = getState().entities.riskProfiles.firstByGoalIdAndUserIdOrderByCreatedAtDesc[key];
    if (riskProfile && !forceUpdate) return null;
    return dispatch(fetchFirstRiskProfileByGoalIdAndUserIdOrderByCreatedAtDesc(goalId, userId));
  };
};
export var GET_RISKPROFILES_REQUEST = "GET_RISKPROFILES_REQUEST";
export var GET_RISKPROFILES_SUCCESS = "GET_RISKPROFILES_SUCCESS";
export var GET_RISKPROFILES_FAILURE = "GET_RISKPROFILES_FAILURE";
export var loadRiskProfiles = function loadRiskProfiles(page, size, idFilterValue, goalIdFilterValue, userIdFilterValue, startDateFilterValue, endDateFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "updatedAt,desc",
      idFilterValue: idFilterValue,
      goalIdFilterValue: goalIdFilterValue,
      userIdFilterValue: userIdFilterValue,
      startDateFilterValue: startDateFilterValue,
      endDateFilterValue: endDateFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_RISKPROFILES_REQUEST, GET_RISKPROFILES_SUCCESS, GET_RISKPROFILES_FAILURE],
      endpoint: "riskProfiles/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_RISKPROFILEBYID_REQUEST = "GET_RISKPROFILEBYID_REQUEST";
export var GET_RISKPROFILEBYID_SUCCESS = "GET_RISKPROFILEBYID_SUCCESS";
export var GET_RISKPROFILEBYID_FAILURE = "GET_RISKPROFILEBYID_FAILURE";
export var RESET_GET_RISKPROFILEBYID = "RESET_GET_RISKPROFILEBYID";

// Fetches a particular investment plan from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchRiskProfileById = function fetchRiskProfileById(id) {
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_RISKPROFILEBYID_REQUEST, GET_RISKPROFILEBYID_SUCCESS, GET_RISKPROFILEBYID_FAILURE],
    endpoint: "riskProfiles/".concat(id, "?projection=full"),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches a particular investment plan by id from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadRiskProfileById = function loadRiskProfileById(id, forceUpdate) {
  return function (dispatch, getState) {
    var riskProfile = getState().entities.riskProfiles.byId[id];
    if (riskProfile && !forceUpdate) return null;
    return dispatch(fetchRiskProfileById(id));
  };
};