import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerCurrencyBalanceApiCalls = {
  loadBrokerCurrencyBalancesByBrokerIdAndCurrencyIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYBALANCESBYBROKERIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_BROKERCURRENCYBALANCESBYBROKERIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_BROKERCURRENCYBALANCESBYBROKERIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYBALANCESBYBROKERIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default brokerCurrencyBalanceApiCalls;