import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var loginApiCalls = {
  authenticationStatus: status({
    mapActionToKey: function mapActionToKey(action) {
      return "undefined";
    },
    types: [ActionTypes.GET_AUTHENTICATIONSTATUS_REQUEST, ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS, ActionTypes.GET_AUTHENTICATIONSTATUS_FAILURE, ActionTypes.REST_GET_AUTHENTICATIONSTATUS]
  })
};
export default loginApiCalls;