function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_CONTACTUS_REQUEST = "POST_CONTACTUS_REQUEST";
export var POST_CONTACTUS_SUCCESS = "POST_CONTACTUS_SUCCESS";
export var POST_CONTACTUS_FAILURE = "POST_CONTACTUS_FAILURE";
export var RESET_POST_CONTACTUS = "RESET_POST_CONTACTUS";
export var contactUs = function contactUs(name, email, comment) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("name", name);
    body.append("email", email);
    body.append("comment", comment);
    return _defineProperty({}, CALL_API, {
      types: [POST_CONTACTUS_REQUEST, POST_CONTACTUS_SUCCESS, POST_CONTACTUS_FAILURE],
      endpoint: "contactUs",
      init: {
        method: "POST",
        body: body
      }
    });
  };
};
export var resetContactUs = function resetContactUs() {
  return function (dispatch, getState) {
    return {
      type: RESET_POST_CONTACTUS
    };
  };
};
export var FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export var FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export var FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";
export var RESET_FORGOTPASSWORD = "RESET_FORGOTPASSWORD";
export var forgotPassword = function forgotPassword(email) {
  var body = new FormData();
  body.append("email", email);
  return _defineProperty({}, CALL_API, {
    types: [FORGOTPASSWORD_REQUEST, FORGOTPASSWORD_SUCCESS, FORGOTPASSWORD_FAILURE],
    endpoint: "forgotPassword",
    init: {
      method: "POST",
      body: body
    }
  });
};
export var forgotPasswordFailure = function forgotPasswordFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: FORGOTPASSWORD_FAILURE
    });
  };
};
export var resetForgotPassword = function resetForgotPassword() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORGOTPASSWORD
    });
  };
};
export var CHANGEPASSWORD_REQUEST = "CHANGEPASSWORD_REQUEST";
export var CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export var CHANGEPASSWORD_FAILURE = "CHANGEPASSWORD_FAILURE";
export var RESET_CHANGEPASSWORD = "RESET_CHANGEPASSWORD";
export var changePassword = function changePassword(password, token) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("password", password);
    body.append("token", token);
    return dispatch(_defineProperty({}, CALL_API, {
      types: [CHANGEPASSWORD_REQUEST, CHANGEPASSWORD_SUCCESS, CHANGEPASSWORD_FAILURE],
      endpoint: "changePassword",
      init: {
        method: "POST",
        body: body
      }
    }));
  };
};
export var changePasswordFailure = function changePasswordFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: CHANGEPASSWORD_FAILURE
    });
  };
};
export var resetChangePassword = function resetChangePassword() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_CHANGEPASSWORD
    });
  };
};
export var RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";

// Resets the currently visible error message.
export var resetErrorMessage = function resetErrorMessage() {
  return {
    type: RESET_ERROR_MESSAGE
  };
};

// export const GET_DASHBOARDSTATS_REQUEST = 'GET_DASHBOARDSTATS_REQUEST'
// export const GET_DASHBOARDSTATS_SUCCESS = 'GET_DASHBOARDSTATS_SUCCESS'
// export const GET_DASHBOARDSTATS_FAILURE = 'GET_DASHBOARDSTATS_FAILURE'
// export const RESET_GET_DASHBOARDSTATS = 'RESET_GET_DASHBOARDSTATS'

// export const loadDashboardStats = () => (dispatch, getState) => dispatch({
//   [CALL_API]: {
//     types: [ GET_DASHBOARDSTATS_REQUEST, GET_DASHBOARDSTATS_SUCCESS, GET_DASHBOARDSTATS_FAILURE ],
//     endpoint: `dashboardStats`,
//     init: { credentials: 'same-origin' }
//   }
// })

export var POST_SHAREINVESTMENTPARTY_REQUEST = "POST_SHAREINVESTMENTPARTY_REQUEST";
export var POST_SHAREINVESTMENTPARTY_SUCCESS = "POST_SHAREINVESTMENTPARTY_SUCCESS";
export var POST_SHAREINVESTMENTPARTY_FAILURE = "POST_SHAREINVESTMENTPARTY_FAILURE";
export var RESET_POST_SHAREINVESTMENTPARTY = "RESET_POST_SHAREINVESTMENTPARTY";
export var shareInvestmentParty = function shareInvestmentParty(investmentPartyId, inviteeEmail, inviterEmail) {
  var body = new FormData();
  body.append("investmentPartyId", investmentPartyId);
  body.append("inviteeEmail", inviteeEmail);
  body.append("inviterEmail", inviterEmail);
  return _defineProperty({}, CALL_API, {
    types: [POST_SHAREINVESTMENTPARTY_REQUEST, POST_SHAREINVESTMENTPARTY_SUCCESS, POST_SHAREINVESTMENTPARTY_FAILURE],
    endpoint: "shareInvestmentParty",
    init: {
      method: "POST",
      body: body
    }
  });
};
export var shareInvestmentPartyFailure = function shareInvestmentPartyFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_SHAREINVESTMENTPARTY_FAILURE
    });
  };
};
export var resetShareInvestmentParty = function resetShareInvestmentParty() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_SHAREINVESTMENTPARTY
    });
  };
};
export var POST_BLOCKUSER_REQUEST = "POST_BLOCKUSER_REQUEST";
export var POST_BLOCKUSER_SUCCESS = "POST_BLOCKUSER_SUCCESS";
export var POST_BLOCKUSER_FAILURE = "POST_BLOCKUSER_FAILURE";
export var RESET_POST_BLOCKUSER = "RESET_POST_BLOCKUSER";
export var postBlockUser = function postBlockUser(blockerId, blockeeId) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("blockerId", blockerId);
    body.append("blockeeId", blockeeId);
    dispatch(_defineProperty({
      blockerId: blockerId,
      blockeeId: blockeeId
    }, CALL_API, {
      types: [POST_BLOCKUSER_REQUEST, POST_BLOCKUSER_SUCCESS, POST_BLOCKUSER_FAILURE],
      endpoint: "blockUser",
      init: {
        method: "POST",
        body: body
      }
    }));
  };
};
export var postBlockUserFailure = function postBlockUserFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_BLOCKUSER_FAILURE
    });
  };
};
export var resetPostBlockUser = function resetPostBlockUser() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_BLOCKUSER
    });
  };
};
export var POST_REPORTADVERTISEMENT_REQUEST = "POST_REPORTADVERTISEMENT_REQUEST";
export var POST_REPORTADVERTISEMENT_SUCCESS = "POST_REPORTADVERTISEMENT_SUCCESS";
export var POST_REPORTADVERTISEMENT_FAILURE = "POST_REPORTADVERTISEMENT_FAILURE";
export var RESET_POST_REPORTADVERTISEMENT = "RESET_POST_REPORTADVERTISEMENT";
export var reportAdvertisement = function reportAdvertisement(advertisementId, reportCause) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("advertisementId", advertisementId);
    body.append("reportCause", reportCause);
    return dispatch(_defineProperty({
      advertisementId: advertisementId
    }, CALL_API, {
      types: [POST_REPORTADVERTISEMENT_REQUEST, POST_REPORTADVERTISEMENT_SUCCESS, POST_REPORTADVERTISEMENT_FAILURE],
      endpoint: "reportAdvertisement",
      init: {
        method: "POST",
        body: body
      }
    }));
  };
};
export var reportAdvertisementFailure = function reportAdvertisementFailure(message) {
  return {
    response: {
      message: message
    },
    type: POST_REPORTADVERTISEMENT_FAILURE
  };
};
export var resetReportAdvertisement = function resetReportAdvertisement() {
  return {
    type: RESET_POST_REPORTADVERTISEMENT
  };
};
export var POST_REPORTPOST_REQUEST = "POST_REPORTPOST_REQUEST";
export var POST_REPORTPOST_SUCCESS = "POST_REPORTPOST_SUCCESS";
export var POST_REPORTPOST_FAILURE = "POST_REPORTPOST_FAILURE";
export var RESET_POST_REPORTPOST = "RESET_POST_REPORTPOST";
export var reportPost = function reportPost(postId, reportCause) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("postId", postId);
    body.append("reportCause", reportCause);
    return dispatch(_defineProperty({
      postId: postId
    }, CALL_API, {
      types: [POST_REPORTPOST_REQUEST, POST_REPORTPOST_SUCCESS, POST_REPORTPOST_FAILURE],
      endpoint: "reportPost",
      init: {
        method: "POST",
        body: body
      }
    }));
  };
};
export var reportPostFailure = function reportPostFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_REPORTPOST_FAILURE
    });
  };
};
export var resetReportPost = function resetReportPost() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_REPORTPOST
    });
  };
};
export var POST_REPORTUSER_REQUEST = "POST_REPORTUSER_REQUEST";
export var POST_REPORTUSER_SUCCESS = "POST_REPORTUSER_SUCCESS";
export var POST_REPORTUSER_FAILURE = "POST_REPORTUSER_FAILURE";
export var RESET_POST_REPORTUSER = "RESET_POST_REPORTUSER";
export var reportUser = function reportUser(userId, reportCause) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("userId", userId);
    body.append("reportCause", reportCause);
    return dispatch(_defineProperty({
      userId: userId
    }, CALL_API, {
      types: [POST_REPORTUSER_REQUEST, POST_REPORTUSER_SUCCESS, POST_REPORTUSER_FAILURE],
      endpoint: "reportUser",
      init: {
        method: "POST",
        body: body
      }
    }));
  };
};
export var reportUserFailure = function reportUserFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_REPORTUSER_FAILURE
    });
  };
};
export var POST_REPORTINVESTMENTITEMDESCRIPTION_REQUEST = "POST_REPORTINVESTMENTITEMDESCRIPTION_REQUEST";
export var POST_REPORTINVESTMENTITEMDESCRIPTION_SUCCESS = "POST_REPORTINVESTMENTITEMDESCRIPTION_SUCCESS";
export var POST_REPORTINVESTMENTITEMDESCRIPTION_FAILURE = "POST_REPORTINVESTMENTITEMDESCRIPTION_FAILURE";
export var RESET_POST_REPORTINVESTMENTITEMDESCRIPTION = "RESET_POST_REPORTINVESTMENTITEMDESCRIPTION";
export var reportInvestmentItemDescription = function reportInvestmentItemDescription(investmentItemId, reportCause) {
  var body = new FormData();
  body.append("investmentItemId", investmentItemId);
  body.append("reportCause", reportCause);
  return _defineProperty({
    investmentItemId: investmentItemId
  }, CALL_API, {
    types: [POST_REPORTINVESTMENTITEMDESCRIPTION_REQUEST, POST_REPORTINVESTMENTITEMDESCRIPTION_SUCCESS, POST_REPORTINVESTMENTITEMDESCRIPTION_FAILURE],
    endpoint: "reportInvestmentItemDescription",
    init: {
      method: "POST",
      body: body
    }
  });
};
export var reportInvestmentItemDescriptionFailure = function reportInvestmentItemDescriptionFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_REPORTINVESTMENTITEMDESCRIPTION_FAILURE
    });
  };
};
export var resetReportInvestmentItemDescription = function resetReportInvestmentItemDescription() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_REPORTINVESTMENTITEMDESCRIPTION
    });
  };
};
export var POST_REPORTBROKERDESCRIPTION_REQUEST = "POST_REPORTBROKERDESCRIPTION_REQUEST";
export var POST_REPORTBROKERDESCRIPTION_SUCCESS = "POST_REPORTBROKERDESCRIPTION_SUCCESS";
export var POST_REPORTBROKERDESCRIPTION_FAILURE = "POST_REPORTBROKERDESCRIPTION_FAILURE";
export var RESET_POST_REPORTBROKERDESCRIPTION = "RESET_POST_REPORTBROKERDESCRIPTION";
export var reportBrokerDescription = function reportBrokerDescription(brokerId, reportCause) {
  var body = new FormData();
  body.append("brokerId", brokerId);
  body.append("reportCause", reportCause);
  return _defineProperty({
    brokerId: brokerId
  }, CALL_API, {
    types: [POST_REPORTBROKERDESCRIPTION_REQUEST, POST_REPORTBROKERDESCRIPTION_SUCCESS, POST_REPORTBROKERDESCRIPTION_FAILURE],
    endpoint: "reportBrokerDescription",
    init: {
      method: "POST",
      body: body
    }
  });
};
export var reportBrokerDescriptionFailure = function reportBrokerDescriptionFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_REPORTBROKERDESCRIPTION_FAILURE
    });
  };
};
export var resetReportBrokerDescription = function resetReportBrokerDescription() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_REPORTBROKERDESCRIPTION
    });
  };
};
export var POST_TRANSLATE_REQUEST = "POST_TRANSLATE_REQUEST";
export var POST_TRANSLATE_SUCCESS = "POST_TRANSLATE_SUCCESS";
export var POST_TRANSLATE_FAILURE = "POST_TRANSLATE_FAILURE";
export var RESET_POST_TRANSLATE = "RESET_POST_TRANSLATE";
export var translate = function translate(text, source, target) {
  var queryString = urlSearchParams({
    key: "",
    q: encodeURI(text),
    source: source,
    target: target
  });
  return _defineProperty({
    textStartingWith: textStartingWith
  }, CALL_API, {
    types: [GET_HASHTAGSBYTEXTSTARTINGWITH_REQUEST, GET_HASHTAGSBYTEXTSTARTINGWITH_SUCCESS, GET_HASHTAGSBYTEXTSTARTINGWITH_FAILURE],
    endpoint: "https://translation.googleapis.com/language/translate/v2".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var translateFailure = function translateFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_TRANSLATE_FAILURE
    });
  };
};
export var resetTranslate = function resetTranslate() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_TRANSLATE
    });
  };
};
export var GET_GEOLOCATION_REQUEST = "GET_GEOLOCATION_REQUEST";
export var GET_GEOLOCATION_SUCCESS = "GET_GEOLOCATION_SUCCESS";
export var GET_GEOLOCATION_FAILURE = "GET_GEOLOCATION_FAILURE";
export var RESET_GET_GEOLOCATION = "RESET_GET_GEOLOCATION";
export var getGeolocation = function getGeolocation() {
  return _defineProperty({}, CALL_API, {
    types: [GET_GEOLOCATION_REQUEST, GET_GEOLOCATION_SUCCESS, GET_GEOLOCATION_FAILURE],
    endpoint: "geolocations"
  });
};
export var getGeolocationFailure = function getGeolocationFailure(message) {
  return {
    response: {
      message: message
    },
    type: GET_GEOLOCATION_FAILURE
  };
};
export var resetGetGeolocation = function resetGetGeolocation() {
  return {
    type: RESET_GET_GEOLOCATION
  };
};
export * from "./addressActions";
export * from "./advertisementActions";
export * from "./authenticationStatusActions";
export * from "./afipActivityActions";
export * from "./balanceActions";
export * from "./balanceLineChartDatasetActions";
export * from "./bankAccountActions";
export * from "./bankAccountTypeActions";
export * from "./bankActions";
export * from "./bankHolidayActions";
export * from "./bindTransactionActions";
export * from "./bookmarkActions";
export * from "./bookmarkListItemActions";
export * from "./boostActions";
export * from "./boostBrokerCurrencyBalanceActions";
export * from "./boostListItemActions";
export * from "./boostRowActions";
export * from "./brokerActions";
export * from "./brokerCurrencyBalanceActions";
export * from "./brokerCurrencyGoalInvestmentItemUserBalanceActions";
export * from "./brokerCurrencyInvestmentItemBalanceActions";
export * from "./brokerCurrencyInvestmentItemUserBalanceActions";
export * from "./externalBalanceActions";
export * from "./brokerCurrencyGoalUserBalanceActions";
export * from "./brokerCurrencyUserBalanceActions";
export * from "./brokerFollowerActions";
export * from "./brokerMentionListItemActions";
export * from "./brokerViewActions";
export * from "./cafciMonedaActions";
export * from "./cafciClaseActions";
export * from "./cafciClaseViewActions";
export * from "./cafciHorizonteActions";
export * from "./cafciSociedadGerenteActions";
export * from "./cafciCarteraActions";
export * from "./cafciPrecioActions";
export * from "./cafciPrecioLineChartActions";
export * from "./campaignActions";
export * from "./cartViewActions";
export * from "./cityActions";
export * from "./clickActions";
export * from "./commissionTypeActions";
export * from "./contactActions";
export * from "./countryActions";
export * from "./currencyActions";
export * from "./currencyBalanceActions";
export * from "./currencyGoalInvestmentItemUserBalanceActions";
export * from "./currencyInvestmentItemUserBalanceActions";
export * from "./currencyGoalUserBalanceActions";
export * from "./currencyGoalUserBalanceLineChartActions";
export * from "./currencyUserBalanceActions";
export * from "./currencyVariantInvestmentItemActions";
export * from "./dashboardActions";
export * from "./dashboardStatsActions";
export * from "./displayActions";
export * from "./distributionTabActions";
export * from "./documentActions";
export * from "./documentTypesActions";
export * from "./employeeActions";
export * from "./employeeRowActions";
export * from "./entityActions";
export * from "./entityMentionPreferenceActions";
export * from "./eventActions";
export * from "./eventListItemActions";
export * from "./eventReactionActions";
export * from "./eventTypeActions";
export * from "./fileActions";
export * from "./formActions";
export * from "./genderActions";
export * from "./goalActions";
export * from "./goalFollowerActions";
export * from "./goalMentionListItemActions";
export * from "./goalTypeActions";
export * from "./goalViewActions";
export * from "./hashtagActions";
export * from "./hashtagFollowerActions";
export * from "./hashtagMentionListItemActions";
export * from "./hashtagViewActions";
export * from "./incomeTypeActions";
export * from "./industryActions";
export * from "./invertirOnlinePriceActions";
export * from "./invertirOnlinePriceLineChartActions";
export * from "./invertirOnlineTitleActions";
export * from "./invertirOnlineTitleViewActions";
export * from "./investmentAccountActions";
export * from "./investmentAccountRowActions";
export * from "./investmentItemActions";
export * from "./investmentItemAvailabilityActions";
export * from "./investmentItemListItemActions";
export * from "./investmentItemFollowerActions";
export * from "./investmentItemMentionListItemActions";
export * from "./investmentItemActions";
export * from "./investmentItemsViewActions";
export * from "./investmentItemViewActions";
export * from "./investmentItemTypeActions";
export * from "./investmentPartyActions";
export * from "./investmentPartyViewActions";
export * from "./investmentPlanActions";
export * from "./investmentPreferenceActions";
export * from "./ivaTaxpayerTypeActions";
export * from "./keyPhraseActions";
export * from "./keyPhraseMentionPreferenceActions";
export * from "./languageActions";
export * from "./localityActions";
export * from "./maritalStatusActions";
export * from "./marketVariantInvestmentItemActions";
export * from "./mediaActions";
export * from "./memberActions";
export * from "./memberListItemActions";
export * from "./membersTabActions";
export * from "./mentionActions";
export * from "./boostsViewActions";
export * from "./myBrokersDashboardActions";
export * from "./myBrokersDashboardStatsActions";
export * from "./myBrokersEmployeeRowActions";
export * from "./myBrokersInvestmentAccountRowActions";
export * from "./myBrokersPriceRowActions";
export * from "./myBrokersOperationRowActions";
export * from "./eventsAsideViewActions";
export * from "./eventsViewActions";
export * from "./membersViewActions";
export * from "./notificationActions";
export * from "./notificationListItemActions";
export * from "./notificationTabActions";
export * from "./numericConventionActions";
export * from "./operationActions";
export * from "./operationFormActions";
export * from "./operationRowActions";
export * from "./participantActions";
export * from "./pendingDepositsViewActions";
export * from "./personActions";
export * from "./pickActions";
export * from "./postListItemActions";
export * from "./postActions";
export * from "./postReactionActions";
export * from "./postReactionListItemActions";
export * from "./postRowActions";
export * from "./provinceActions";
export * from "./priceActions";
export * from "./priceForecastActions";
export * from "./priceLineChartDatasetActions";
export * from "./priceRowActions";
export * from "./quoteActions";
export * from "./riskProfileActions";
export * from "./sentimentScorePreferenceActions";
export * from "./sentimentTypeActions";
export * from "./signupActions";
export * from "./timeZoneActions";
export * from "./transactionActions";
export * from "./uiPreferencesActions";
export * from "./unitDecimalScaleActions";
export * from "./userActions";
export * from "./userFollowerActions";
export * from "./userFollowerListItemActions";
export * from "./userMentionListItemActions";
export * from "./usersViewActions";
export * from "./userViewActions";
export * from "./advisorAsideViewActions";
export * from "./validateDocumentActions";
export * from "./variantInvestmentItemActions";
export * from "./walletViewActions";
export * from "./watchlistItemActions";
export * from "./watchlistItemListItemActions";
export * from "./wireTransferActions";
export * from "./workingStatusActions";