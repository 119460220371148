function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// Creates a reducer managing status, given the action types to handle,
// and a function telling how to extract the key from an action.
var status = function status(_ref) {
  var types = _ref.types,
    mapActionToKey = _ref.mapActionToKey;
  if (!Array.isArray(types) || types.length < 4) {
    throw new Error("Expected types to be an array of four or more elements.");
  }
  if (!types.every(function (t) {
    return typeof t === "string";
  })) {
    throw new Error("Expected types to be strings.");
  }
  if (typeof mapActionToKey !== "function") {
    throw new Error("Expected mapActionToKey to be a function.");
  }
  var _types = _slicedToArray(types, 14),
    requestType = _types[0],
    successType = _types[1],
    failureType = _types[2],
    resetType = _types[3],
    forceUpdateType = _types[4],
    forceUpdateType1 = _types[5],
    forceUpdateType2 = _types[6],
    forceUpdateType3 = _types[7],
    forceUpdateType4 = _types[8],
    forceUpdateType5 = _types[9],
    forceUpdateType6 = _types[10],
    forceUpdateType7 = _types[11],
    forceUpdateType8 = _types[12],
    forceUpdateType9 = _types[13];
  var updateStatus = function updateStatus() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      isFetching: false,
      fetchedSuccessfulAtLeastOnce: false,
      message: undefined,
      success: undefined,
      errorCode: undefined,
      forceUpdate: false
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case requestType:
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: true
        });
      case successType:
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          fetchedSuccessfulAtLeastOnce: true,
          message: action.response.message,
          success: action.response.success,
          errorCode: action.response.errorCode,
          forceUpdate: false
        });
      case failureType:
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          message: action.response ? action.response.message : "Ups. Tu pedido no pudo ser procesado en este " + "momento. Por favor volvé a intentarlo en unos minutos. Recordá que podés escribirnos por Whatsapp " + "para ayudarte en caso de ser necesario.",
          success: false,
          errorCode: action.response ? action.response.errorCode : undefined,
          forceUpdate: false
        });
      case resetType:
        return _objectSpread(_objectSpread({}, state), {}, {
          isFetching: false,
          message: undefined,
          success: undefined,
          errorCode: undefined
        });
      case forceUpdateType:
      case forceUpdateType1:
      case forceUpdateType2:
      case forceUpdateType3:
      case forceUpdateType4:
      case forceUpdateType5:
      case forceUpdateType6:
      case forceUpdateType7:
      case forceUpdateType8:
      case forceUpdateType9:
        return _objectSpread(_objectSpread({}, state), {}, {
          forceUpdate: true
        });
      default:
        return state;
    }
  };
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    // Update status status by key
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
      case resetType:
      case forceUpdateType:
      case forceUpdateType1:
      case forceUpdateType2:
      case forceUpdateType3:
      case forceUpdateType4:
      case forceUpdateType5:
      case forceUpdateType6:
      case forceUpdateType7:
      case forceUpdateType8:
      case forceUpdateType9:
        var key = mapActionToKey(action);
        if (typeof key !== "string") {
          throw new Error("Expected key to be a string.");
        }
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, key, updateStatus(state[key], action)));
      default:
        return state;
    }
  };
};
export default status;