import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var afipActivityApiCalls = {
  fetchAfipActivitiesByIdIn: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_AFIPACTIVITIESBYIDIN_REQUEST, ActionTypes.GET_AFIPACTIVITIESBYIDIN_SUCCESS, ActionTypes.GET_AFIPACTIVITIESBYIDIN_FAILURE, ActionTypes.RESET_GET_AFIPACTIVITIES]
  })
};
export default afipActivityApiCalls;