import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var memberListItemApiCalls = {
  fetchMemberListItemsByMemberUserIdAndMemberStates: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.memberUserId, action.memberStates].join("-");
    },
    types: [ActionTypes.GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_REQUEST, ActionTypes.GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_SUCCESS, ActionTypes.GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_FAILURE, ActionTypes.RESET_GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES]
  })
};
export default memberListItemApiCalls;