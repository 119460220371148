import * as ActionTypes from "../actions";
import { reducer as formReducer } from "redux-form";
var form = formReducer.plugin({
  bankAccount: function bankAccount(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BANKACCOUNT:
        return undefined;
      default:
        return state;
    }
  },
  broker: function broker(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BROKER:
        return undefined;
      default:
        return state;
    }
  },
  goalWizard: function goalWizard(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_GOALWIZARD:
        return undefined;
      default:
        return state;
    }
  },
  cashOperation: function cashOperation(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_CASHOPERATION:
        return undefined;
      default:
        return state;
    }
  },
  contactUs: function contactUs(state, action) {
    switch (action.type) {
      case ActionTypes.POST_CONTACTUS_SUCCESS:
        return undefined;
      default:
        return state;
    }
  },
  signup: function signup(state, action) {
    switch (action.type) {
      case ActionTypes.POST_SIGNUP_SUCCESS:
        return undefined;
      default:
        return state;
    }
  },
  riskProfileWizard: function riskProfileWizard(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_RISKPROFILEWIZARD:
        return undefined;
      default:
        return state;
    }
  },
  price: function price(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_PRICE:
        return undefined;
      default:
        return state;
    }
  },
  investmentAccount: function investmentAccount(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_INVESTMENTACCOUNT:
        return undefined;
      default:
        return state;
    }
  },
  investmentItem: function investmentItem(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_INVESTMENTITEM:
        return undefined;
      default:
        return state;
    }
  },
  currencyFilter: function currencyFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_CURRENCYFILTER:
        return undefined;
      default:
        return state;
    }
  },
  advisorWizardForm: function advisorWizardForm(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_ADVISORWIZARD:
        return undefined;
      default:
        return state;
    }
  },
  employee: function employee(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_EMPLOYEE:
        return undefined;
      default:
        return state;
    }
  },
  myBrokersEmployee: function myBrokersEmployee(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_MYBROKERSEMPLOYEE:
        return undefined;
      default:
        return state;
    }
  },
  bankHoliday: function bankHoliday(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BANKHOLIDAY:
        return undefined;
      default:
        return state;
    }
  },
  riskProfileAndSignupWizard: function riskProfileAndSignupWizard(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_RISKPROFILEANDSIGNUPWIZARD:
        return undefined;
      default:
        return state;
    }
  },
  investmentItemCardFilterForm: function investmentItemCardFilterForm(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_INVESTMENTITEMLISTITEMFILTERFORM:
        return undefined;
      default:
        return state;
    }
  },
  investmentAccountReadinessWizard: function investmentAccountReadinessWizard(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_INVESTMENTACCOUNTREADINESSBYUSERIDWIZARD:
        return undefined;
      default:
        return state;
    }
  },
  boostCurrencyBalanceSearch: function boostCurrencyBalanceSearch(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BOOSTCURRENCYBALANCESEARCH:
        return undefined;
      default:
        return state;
    }
  },
  brokerCurrencyUserBalanceSearch: function brokerCurrencyUserBalanceSearch(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BROKERCURRENCYUSERBALANCESEARCH:
        return undefined;
      default:
        return state;
    }
  },
  currencyGoalUserBalanceSearch: function currencyGoalUserBalanceSearch(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_CURRENCYGOALUSERBALANCESEARCH:
        return undefined;
      default:
        return state;
    }
  },
  operationFilter: function operationFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_OPERATIONFILTER:
        return undefined;
      default:
        return state;
    }
  },
  userFilter: function userFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_USERFILTER:
        return undefined;
      default:
        return state;
    }
  },
  goalFilter: function goalFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_GOALFILTER:
        return undefined;
      default:
        return state;
    }
  },
  goalType: function goalType(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_GOALTYPE:
        return undefined;
      default:
        return state;
    }
  },
  boostFilter: function boostFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BOOSTFILTER:
        return undefined;
      default:
        return state;
    }
  },
  boost: function boost(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_BOOST:
        return undefined;
      default:
        return state;
    }
  },
  investmentAccountFilter: function investmentAccountFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_INVESTMENTACCOUNTFILTER:
        return undefined;
      default:
        return state;
    }
  },
  post: function post(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_POST:
        return undefined;
      default:
        return state;
    }
  },
  goalTypeFilter: function goalTypeFilter(state, action) {
    switch (action.type) {
      case ActionTypes.RESET_FORM_GOALTYPEFILTER:
        return undefined;
      default:
        return state;
    }
  }
});
export default form;