import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var hashtagViewApiCalls = {
  fetchHashtagViewByHashtagText: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.hashtagText, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_HASHTAGVIEWBYHASHTAGTEXTANDAUTHUSERID_REQUEST, ActionTypes.GET_HASHTAGVIEWBYHASHTAGTEXTANDAUTHUSERID_SUCCESS, ActionTypes.GET_HASHTAGVIEWBYHASHTAGTEXTANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_HASHTAGVIEWBYHASHTAGTEXTANDAUTHUSERID]
  })
};
export default hashtagViewApiCalls;