function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";
export var GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export var GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";
export var loadEmployees = function loadEmployees(page, size, idFilterValue, userIdFilterValue, userIdFilterMode, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var _urlSearchParams;
    var queryString = urlSearchParams((_urlSearchParams = {
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "withBrokerAndUserAndRoles"
    }, _defineProperty(_urlSearchParams, "page", page), _defineProperty(_urlSearchParams, "size", size), _defineProperty(_urlSearchParams, "idFilterValue", idFilterValue), _defineProperty(_urlSearchParams, "userIdFilterValue", userIdFilterValue), _defineProperty(_urlSearchParams, "userIdFilterMode", userIdFilterMode), _defineProperty(_urlSearchParams, "startCreatedAtFilterValue", startCreatedAtFilterValue), _defineProperty(_urlSearchParams, "endCreatedAtFilterValue", endCreatedAtFilterValue), _urlSearchParams));
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_EMPLOYEES_REQUEST, GET_EMPLOYEES_SUCCESS, GET_EMPLOYEES_FAILURE],
      endpoint: "employees/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_EMPLOYEESBYBROKERID_REQUEST = "GET_EMPLOYEESBYBROKERID_REQUEST";
export var GET_EMPLOYEESBYBROKERID_SUCCESS = "GET_EMPLOYEESBYBROKERID_SUCCESS";
export var GET_EMPLOYEESBYBROKERID_FAILURE = "GET_EMPLOYEESBYBROKERID_FAILURE";
export var RESET_GET_EMPLOYEESBYBROKERID = "RESET_GET_EMPLOYEESBYBROKERID";
export var loadEmployeesByBrokerId = function loadEmployeesByBrokerId(page, size, brokerId, idFilterValue, userIdFilterValue, userIdFilterMode, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "withBrokerAndUserAndRoles",
      brokerId: brokerId,
      idFilterValue: idFilterValue,
      userIdFilterValue: userIdFilterValue,
      userIdFilterMode: userIdFilterMode,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({
      brokerId: brokerId
    }, CALL_API, {
      types: [GET_EMPLOYEESBYBROKERID_REQUEST, GET_EMPLOYEESBYBROKERID_SUCCESS, GET_EMPLOYEESBYBROKERID_FAILURE],
      endpoint: "employees/search/findAllByBroker_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_EMPLOYEE_REQUEST = "POST_EMPLOYEE_REQUEST";
export var POST_EMPLOYEE_SUCCESS = "POST_EMPLOYEE_SUCCESS";
export var POST_EMPLOYEE_FAILURE = "POST_EMPLOYEE_FAILURE";
export var RESET_POST_EMPLOYEE = "RESET_POST_EMPLOYEE";
export var postEmployee = function postEmployee(employee) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      employee: employee
    }, CALL_API, {
      types: [POST_EMPLOYEE_REQUEST, POST_EMPLOYEE_SUCCESS, POST_EMPLOYEE_FAILURE],
      endpoint: "employees",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(employee),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostEmployee = function resetPostEmployee() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_EMPLOYEE
    });
  };
};
export var DELETE_EMPLOYEES_REQUEST = "DELETE_EMPLOYEES_REQUEST";
export var DELETE_EMPLOYEES_SUCCESS = "DELETE_EMPLOYEES_SUCCESS";
export var DELETE_EMPLOYEES_FAILURE = "DELETE_EMPLOYEES_FAILURE";
export var RESET_DELETE_EMPLOYEES = "RESET_DELETE_EMPLOYEES";
export var deleteEmployees = function deleteEmployees(employees) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      employees: employees
    }, CALL_API, {
      types: [DELETE_EMPLOYEES_REQUEST, DELETE_EMPLOYEES_SUCCESS, DELETE_EMPLOYEES_FAILURE],
      endpoint: "employees",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(employees),
        credentials: "same-origin"
      }
    }));
  };
};