import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemTypeApiCalls = {
  fetchInvestmentItemTypes: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTITEMTYPES_REQUEST, ActionTypes.GET_INVESTMENTITEMTYPES_SUCCESS, ActionTypes.GET_INVESTMENTITEMTYPES_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMTYPES]
  }),
  fetchInvestmentItemTypesByInvestmentItemsMarketCountryIdIn: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTITEMTYPESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_REQUEST, ActionTypes.GET_INVESTMENTITEMTYPESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_SUCCESS, ActionTypes.GET_INVESTMENTITEMTYPESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMTYPESBYINVESMTENTITEMSMARKETCOUNTRYIDIN]
  })
};
export default investmentItemTypeApiCalls;