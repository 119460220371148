import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var cafciCarteraApiCalls = {
  loadCafciCarterasbyInfoSemanalClaseId: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CAFCICARTERASBYINFOSEMANALCLASEID_REQUEST, ActionTypes.GET_CAFCICARTERASBYINFOSEMANALCLASEID_SUCCESS, ActionTypes.GET_CAFCICARTERASBYINFOSEMANALCLASEID_FAILURE, ActionTypes.RESET_GET_CAFCICARTERASBYINFOSEMANALCLASEID]
  })
};
export default cafciCarteraApiCalls;