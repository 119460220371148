function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT = "RESET_GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT";

// Fetches balances for a particular goal and user and date from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrencyUserBalancesByCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat = function fetchCurrencyUserBalancesByCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat(currencyId, userId, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    projection: "chart",
    currencyId: currencyId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    currencyId: currencyId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat
  }, CALL_API, {
    types: [GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "currencyUserBalances/search/findByCurrency_IdAndUser_IdAndInstantBetweenGroupByDateFormatOrderByInstantDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular goal and user and date unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCurrencyUserBalancesByCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat = function loadCurrencyUserBalancesByCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat(currencyId, userId, fromInstant, toInstant, dateFormat, timeZoneName, forceUpdate) {
  return function (dispatch, getState) {
    var key = [currencyId, userId, fromInstant, toInstant, dateFormat].join("-");
    var balances = getState().entities.currencyUserBalances.byCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat[key];
    if (balances && !forceUpdate) return null;
    return dispatch(fetchCurrencyUserBalancesByCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat(currencyId, userId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};