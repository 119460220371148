import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var hashtagApiCalls = {
  fetchHashtagsByOrderByMentionsCountDesc: status({
    mapActionToKey: function mapActionToKey(action) {
      return "";
    },
    types: [ActionTypes.GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_REQUEST, ActionTypes.GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_SUCCESS, ActionTypes.GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_FAILURE, ActionTypes.RESET_GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC]
  })
};
export default hashtagApiCalls;