function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";
import { eventTypeNames, defaultPageSize } from "../../constants";

// Updates an entity cache in response to any action with response.entities.
var eventListItemEntities = function eventListItemEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byUserIdAndEventTypeNameIn: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byUserIdAndEventTypeNameIn = action.response.eventListItemsByUserIdAndEventTypeNameIn;
      return merge({}, state, {
        byUserIdAndEventTypeNameIn: byUserIdAndEventTypeNameIn
      });
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byUserIdAndEventTypeNameIn = _objectSpread({}, state.byUserIdAndEventTypeNameIn);
      var key = [action.userId, action.eventTypeNames].join("-");
      if (byUserIdAndEventTypeNameIn[key] && action.sinceId !== undefined) {
        byUserIdAndEventTypeNameIn[key] = action.response.reverse().concat(byUserIdAndEventTypeNameIn[key]);
      } else {
        var eventListItems = byUserIdAndEventTypeNameIn[key] ? _toConsumableArray(byUserIdAndEventTypeNameIn[key]) : [];
        var postReplyEventListItems = (byUserIdAndEventTypeNameIn[key] ? byUserIdAndEventTypeNameIn[key].concat(action.response) : action.response).filter(function (eventListItem) {
          return eventListItem.eventTypeName === eventTypeNames.POSTREPLY_CREATED;
        });
        var postParentList = postReplyEventListItems.map(function (eventListItem) {
          var eventId = eventListItem.eventId;
          var event = eventListItem.eventById[eventId];
          var postId = event.objects[0].objectId;
          var post = eventListItem.postListItemByPostId[postId].postById[postId];
          return _objectSpread(_objectSpread({}, post.parent), {}, {
            eventId: eventId
          });
        });
        action.response.forEach(function (eventListItem) {
          if (eventListItem.eventTypeName === eventTypeNames.POST_CREATED) {
            var eventId = eventListItem.eventId;
            var event = eventListItem.eventById[eventId];
            var postId = event.objects[0].objectId;
            var post = eventListItem.postListItemByPostId[postId].postById[postId];
            if (postParentList.some(function (postParent) {
              return post.id === postParent.id;
            })) return;
          }
          if (eventListItem.eventTypeName === eventTypeNames.POSTREPLY_CREATED) {
            var _eventId = eventListItem.eventId;
            var _event = eventListItem.eventById[_eventId];
            var _postId = _event.objects[0].objectId;
            var _post = eventListItem.postListItemByPostId[_postId].postById[_postId];
            eventListItem.collapseParent = postParentList.some(function (postParent) {
              return _eventId < postParent.eventId && _post.parent.id === postParent.id;
            });
          }
          eventListItems.push(eventListItem);
        });
        if (byUserIdAndEventTypeNameIn[key] && action.maxId !== undefined) {
          if (action.response.length == defaultPageSize) {
            var min = 1;
            var max = Math.ceil(action.response.length / 2);
            var pos = Math.random() * (max - min) + min;
            action.response.splice(pos, 0, {
              ad: true
            });
          }
          byUserIdAndEventTypeNameIn[key] = eventListItems;
        } else {
          byUserIdAndEventTypeNameIn[key] = eventListItems;
        }
      }
      return Object.assign({}, state, {
        byUserIdAndEventTypeNameIn: byUserIdAndEventTypeNameIn
      });
    case ActionTypes.DELETE_POST_SUCCESS:
      var byUserIdAndEventTypeNameIn = _objectSpread({}, state.byUserIdAndEventTypeNameIn);
      Object.keys(state.byUserIdAndEventTypeNameIn).map(function (key) {
        byUserIdAndEventTypeNameIn[key] = state.byUserIdAndEventTypeNameIn[key].filter(function (eventListItem) {
          return !((eventListItem.eventTypeName === eventTypeNames.POST_CREATED || eventListItem.eventTypeName === eventTypeNames.POSTREPLY_CREATED || eventListItem.eventTypeName === eventTypeNames.POSTREPUBLISHER_CREATED) && eventListItem.eventById[eventListItem.eventId].objects[0].objectId === action.post.id) && !((eventListItem.eventTypeName === eventTypeNames.POST_CREATED || eventListItem.eventTypeName === eventTypeNames.POSTREPLY_CREATED || eventListItem.eventTypeName === eventTypeNames.POSTREPUBLISHER_CREATED) && Object.keys(eventListItem.postListItemByPostId).filter(function (key) {
            var postId = eventListItem.postListItemByPostId[key].postId;
            return eventListItem.postListItemByPostId[key].postById[postId].parent && eventListItem.postListItemByPostId[key].postById[postId].parent.id === action.post.id || eventListItem.postListItemByPostId[key].postById[postId].postRepublishee && eventListItem.postListItemByPostId[key].postById[postId].postRepublishee.id === action.post.id;
          }).length > 0);
        });
      });
      return Object.assign({}, state, {
        byUserIdAndEventTypeNameIn: byUserIdAndEventTypeNameIn
      });
    default:
      return state;
  }
};
export default eventListItemEntities;