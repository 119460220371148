import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var cafciClaseApiCalls = {
  collectCafciClasesFromFront: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTCAFCICLASESFROMFRONT_REQUEST, ActionTypes.COLLECTCAFCICLASESFROMFRONT_SUCCESS, ActionTypes.COLLECTCAFCICLASESFROMFRONT_FAILURE, ActionTypes.RESET_COLLECTCAFCICLASESFROMFRONT]
  }),
  abortCollectCafciClasesFromFront: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTCAFCICLASESFROMFRONT_REQUEST, ActionTypes.ABORTCOLLECTCAFCICLASESFROMFRONT_SUCCESS, ActionTypes.ABORTCOLLECTCAFCICLASESFROMFRONT_FAILURE, ActionTypes.RESET_ABORTCOLLECTCAFCICLASESFROMFRONT]
  })
};
export default cafciClaseApiCalls;