function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_TRANSACTION_REQUEST = "POST_TRANSACTION_REQUEST";
export var POST_TRANSACTION_SUCCESS = "POST_TRANSACTION_SUCCESS";
export var POST_TRANSACTION_FAILURE = "POST_TRANSACTION_FAILURE";
export var RESET_POST_TRANSACTION = "RESET_POST_TRANSACTION";
export var postTransaction = function postTransaction(transaction) {
  return function (dispatch, getState) {
    var endpoint = "transactions";
    if (transaction.id) endpoint += "/".concat(transaction.id);
    return dispatch(_defineProperty({
      transaction: transaction
    }, CALL_API, {
      types: [POST_TRANSACTION_REQUEST, POST_TRANSACTION_SUCCESS, POST_TRANSACTION_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(transaction),
        credentials: "same-origin"
      }
    }));
  };
};
export var postTransactionFailure = function postTransactionFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_TRANSACTION_FAILURE
    });
  };
};
export var resetPostTransaction = function resetPostTransaction() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_TRANSACTION
    });
  };
};
export var GET_TRANSACTIONBYTOKEN_REQUEST = "GET_TRANSACTIONBYTOKEN_REQUEST";
export var GET_TRANSACTIONBYTOKEN_SUCCESS = "GET_TRANSACTIONBYTOKEN_SUCCESS";
export var GET_TRANSACTIONBYTOKEN_FAILURE = "GET_TRANSACTIONBYTOKEN_FAILURE";
export var RESET_GET_TRANSACTIONBYTOKEN = "RESET_GET_TRANSACTIONBYTOKEN";

// Fetches the logged user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchTransactionByToken = function fetchTransactionByToken(token) {
  return _defineProperty({
    token: token
  }, CALL_API, {
    types: [GET_TRANSACTIONBYTOKEN_REQUEST, GET_TRANSACTIONBYTOKEN_SUCCESS, GET_TRANSACTIONBYTOKEN_FAILURE],
    endpoint: "transactions/search/findByToken?token=".concat(token, "&projection=full"),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches a particular user by token from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadTransactionByToken = function loadTransactionByToken(token) {
  return function (dispatch, getState) {
    var transaction = getState().entities.users.byToken[token];
    if (transaction) return null;
    return dispatch(fetchTransactionByToken(token));
  };
};