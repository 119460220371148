function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_POSTREACTION_REQUEST = "POST_POSTREACTION_REQUEST";
export var POST_POSTREACTION_SUCCESS = "POST_POSTREACTION_SUCCESS";
export var POST_POSTREACTION_FAILURE = "POST_POSTREACTION_FAILURE";
export var RESET_POST_POSTREACTION = "RESET_POST_POSTREACTION";
export var postPostReaction = function postPostReaction(postReaction) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      postReaction: postReaction
    }, CALL_API, {
      types: [POST_POSTREACTION_REQUEST, POST_POSTREACTION_SUCCESS, POST_POSTREACTION_FAILURE],
      endpoint: "postReactions",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postReaction),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostPostReaction = function resetPostPostReaction() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_POSTREACTION
    });
  };
};
export var DELETE_POSTREACTION_REQUEST = "DELETE_POSTREACTION_REQUEST";
export var DELETE_POSTREACTION_SUCCESS = "DELETE_POSTREACTION_SUCCESS";
export var DELETE_POSTREACTION_FAILURE = "DELETE_POSTREACTION_FAILURE";
export var RESET_DELETE_POSTREACTION = "RESET_DELETE_POSTREACTION";
export var deletePostReaction = function deletePostReaction(postReaction) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      postReaction: postReaction
    }, CALL_API, {
      types: [DELETE_POSTREACTION_REQUEST, DELETE_POSTREACTION_SUCCESS, DELETE_POSTREACTION_FAILURE],
      endpoint: "reactions/".concat(postReaction.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var resetDeletePostReaction = function resetDeletePostReaction() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DELETE_POSTREACTION
    });
  };
};