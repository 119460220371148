function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var POST_NOTIFICATION_REQUEST = "POST_NOTIFICATION_REQUEST";
export var POST_NOTIFICATION_SUCCESS = "POST_NOTIFICATION_SUCCESS";
export var POST_NOTIFICATION_FAILURE = "POST_NOTIFICATION_FAILURE";
export var RESET_POST_NOTIFICATION = "RESET_POST_NOTIFICATION";
export var postNotification = function postNotification(notification) {
  return _defineProperty({
    notification: notification
  }, CALL_API, {
    types: [POST_NOTIFICATION_REQUEST, POST_NOTIFICATION_SUCCESS, POST_NOTIFICATION_FAILURE],
    endpoint: "notifications/".concat(notification.id),
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(notification),
      credentials: "same-origin"
    }
  });
};
export var postNotificationFailure = function postNotificationFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_NOTIFICATION_FAILURE
    });
  };
};
export var resetPostNotifications = function resetPostNotifications() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_NOTIFICATION
    });
  };
};
export var GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export var GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export var GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";
export var loadNotifications = function loadNotifications(page, size, userIdFilterValue, userIdFilterMode, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      userIdFilterValue: userIdFilterValue,
      userIdFilterMode: userIdFilterMode,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE],
      endpoint: "notifications/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};