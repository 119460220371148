import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var priceForecastApiCalls = {
  fetchPriceForecastsByVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.variantInvestmentItemId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_PRICEFORECASTSBYVARIANTINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_PRICEFORECASTSBYVARIANTINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_PRICEFORECASTSBYVARIANTINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_PRICEFORECASTSBYVARIANTINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default priceForecastApiCalls;