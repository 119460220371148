function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var POST_WATCHLISTITEM_REQUEST = "POST_WATCHLISTITEM_REQUEST";
export var POST_WATCHLISTITEM_SUCCESS = "POST_WATCHLISTITEM_SUCCESS";
export var POST_WATCHLISTITEM_FAILURE = "POST_WATCHLISTITEM_FAILURE";
export var RESET_POST_WATCHLISTITEM = "RESET_POST_WATCHLISTITEM";
export var postWatchlistItem = function postWatchlistItem(watchlistItem) {
  return _defineProperty({
    watchlistItem: watchlistItem
  }, CALL_API, {
    types: [POST_WATCHLISTITEM_REQUEST, POST_WATCHLISTITEM_SUCCESS, POST_WATCHLISTITEM_FAILURE],
    endpoint: "watchlistItems",
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(watchlistItem),
      credentials: "same-origin"
    }
  });
};
export var resetPostWatchlistItem = function resetPostWatchlistItem() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_WATCHLISTITEM
    });
  };
};
export var DELETE_WATCHLISTITEM_REQUEST = "DELETE_WATCHLISTITEM_REQUEST";
export var DELETE_WATCHLISTITEM_SUCCESS = "DELETE_WATCHLISTITEM_SUCCESS";
export var DELETE_WATCHLISTITEM_FAILURE = "DELETE_WATCHLISTITEM_FAILURE";
export var RESET_DELETE_WATCHLISTITEM = "RESET_DELETE_WATCHLISTITEM";
export var deleteWatchlistItem = function deleteWatchlistItem(watchlistItem) {
  return _defineProperty({
    watchlistItem: watchlistItem
  }, CALL_API, {
    types: [DELETE_WATCHLISTITEM_REQUEST, DELETE_WATCHLISTITEM_SUCCESS, DELETE_WATCHLISTITEM_FAILURE],
    endpoint: "watchlistItems/".concat(watchlistItem.id),
    init: {
      method: "DELETE",
      credentials: "same-origin"
    }
  });
};
export var resetDeleteWatchlistItem = function resetDeleteWatchlistItem() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DELETE_WATCHLISTITEM
    });
  };
};