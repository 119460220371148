import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bookmarkApiCalls = {
  postBookmark: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.bookmark.postId.toString();
    },
    types: [ActionTypes.POST_BOOKMARK_REQUEST, ActionTypes.POST_BOOKMARK_SUCCESS, ActionTypes.POST_BOOKMARK_FAILURE, ActionTypes.RESET_POST_BOOKMARK]
  }),
  deleteBookmark: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.bookmark.id.toString();
    },
    types: [ActionTypes.DELETE_BOOKMARK_REQUEST, ActionTypes.DELETE_BOOKMARK_SUCCESS, ActionTypes.DELETE_BOOKMARK_FAILURE, ActionTypes.RESET_DELETE_BOOKMARK]
  })
};
export default bookmarkApiCalls;