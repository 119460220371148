import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var priceStatsEntities = function priceStatsEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byInvestmentItemId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS:
      var byInvestmentItemId = action.response.priceStatsByInvestmentItemId;
      return merge({}, state, {
        byInvestmentItemId: byInvestmentItemId
      });
    default:
      return state;
  }
};
export default priceStatsEntities;