import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var boostBrokerCurrencyBalanceApiCalls = {
  loadBoostBrokerCurrencyBalancesByBoostIdAndCurrencyIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.boostId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_BOOSTBROKERCURRENCYBALANCESBYBOOSTIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_BOOSTBROKERCURRENCYBALANCESBYBOOSTIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_BOOSTBROKERCURRENCYBALANCESBYBOOSTIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_BOOSTBROKERCURRENCYBALANCESBYBOOSTIDANDCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default boostBrokerCurrencyBalanceApiCalls;