function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_REQUEST = "GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_REQUEST";
export var GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_SUCCESS = "GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_SUCCESS";
export var GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_FAILURE = "GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_FAILURE";
export var RESET_GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID = "RESET_GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID";

// Fetches Investment tabs for a particular currency, goal, user and balance instant time from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchPriceLineChartDatasetsByInvestmentItemId = function fetchPriceLineChartDatasetsByInvestmentItemId(investmentItemId) {
  var queryString = urlSearchParams({
    investmentItemId: investmentItemId
  });
  return _defineProperty({
    investmentItemId: investmentItemId
  }, CALL_API, {
    types: [GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_REQUEST, GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_SUCCESS, GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_FAILURE],
    endpoint: "priceLineChartDatasets/search/findByInvestmentItemId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};