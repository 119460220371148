import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var participantApiCalls = {
  loadParticipantByInvestmentPartyIdAndUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.investmentPartyId, action.userId].join("-");
    },
    types: [ActionTypes.GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_REQUEST, ActionTypes.GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_SUCCESS, ActionTypes.GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID_FAILURE, ActionTypes.RESET_GET_PARTICIPANTBYINVESTMENTPARTYIDANDUSERID]
  }),
  postParticipant: status({
    mapActionToKey: function mapActionToKey() {
      return ["undefined"].join("-");
    },
    types: [ActionTypes.POST_PARTICIPANT_REQUEST, ActionTypes.POST_PARTICIPANT_SUCCESS, ActionTypes.POST_PARTICIPANT_FAILURE, ActionTypes.RESET_POST_PARTICIPANT]
  }),
  deleteParticipant: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.paticipant.investmentParty.id].join("-");
    },
    types: [ActionTypes.DELETE_PARTICIPANT_REQUEST, ActionTypes.DELETE_PARTICIPANT_SUCCESS, ActionTypes.DELETE_PARTICIPANT_FAILURE, ActionTypes.RESET_DELETE_PARTICIPANT]
  })
};
export default participantApiCalls;