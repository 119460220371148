import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var notificationApiCalls = {
  postNotification: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.notification.id.toString();
    },
    types: [ActionTypes.POST_NOTIFICATION_REQUEST, ActionTypes.POST_NOTIFICATION_SUCCESS, ActionTypes.POST_NOTIFICATION_FAILURE, ActionTypes.RESET_POST_NOTIFICATION]
  })
};
export default notificationApiCalls;