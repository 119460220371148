import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyBalanceApiCalls = {
  loadCurrencyBalancesByCurrencyIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_CURRENCYBALANCESBYCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_CURRENCYBALANCESBYCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_CURRENCYBALANCESBYCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_CURRENCYBALANCESBYCURRENCYIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default currencyBalanceApiCalls;