import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentPartyApiCalls = {
  loadInvestmentParty: status({
    mapActionToKey: function mapActionToKey(action) {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTPARTY_REQUEST, ActionTypes.GET_INVESTMENTPARTY_SUCCESS, ActionTypes.GET_INVESTMENTPARTY_FAILURE, ActionTypes.RESET_GET_INVESTMENTPARTY]
  }),
  postInvestmentParty: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_INVESTMENTPARTY_REQUEST, ActionTypes.POST_INVESTMENTPARTY_SUCCESS, ActionTypes.POST_INVESTMENTPARTY_FAILURE, ActionTypes.RESET_POST_INVESTMENTPARTY]
  })
};
export default investmentPartyApiCalls;