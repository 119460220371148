import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerMentionListItemApiCalls = {
  fetchBrokerMentionListItemsByBrokerMentionBrokerIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerMentionBrokerId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_REQUEST, ActionTypes.GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID]
  })
};
export default brokerMentionListItemApiCalls;