function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_REQUEST = "GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_REQUEST";
export var GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_SUCCESS = "GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_SUCCESS";
export var GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_FAILURE = "GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_FAILURE";
export var RESET_GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN = "RESET_GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN";

// Fetches currencyVariantInvestmentItems by ids from Front API.
// Relies on Redux Thunk middleware.
export var fetchCurrencyVariantInvestmentItemsByIdIn = function fetchCurrencyVariantInvestmentItemsByIdIn(ids) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      ids: ids
    });
    return dispatch(_defineProperty({
      ids: ids
    }, CALL_API, {
      types: [GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_REQUEST, GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_SUCCESS, GET_CURRENCYVARIANTINVESTMENTITEMSBYIDIN_FAILURE],
      endpoint: "currencyVariantInvestmentItems/search/findByIdIn".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};

// Fetches currencyVariantInvestmentItems by ids unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCurrencyVariantInvestmentItemsByIdIn = function loadCurrencyVariantInvestmentItemsByIdIn(ids, forceUpdate) {
  return function (dispatch, getState) {
    var key = ids.join(",");
    var currencyVariantInvestmentItems = getState().entities.CurrencyVariantInvestmentItems.byIdIn[key];
    if (currencyVariantInvestmentItems && !forceUpdate) return null;
    return dispatch(fetchCurrencyVariantInvestmentItemsByIdIn(ids));
  };
};
export var GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_REQUEST = "GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_REQUEST";
export var GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_SUCCESS = "GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_SUCCESS";
export var GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_FAILURE = "GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_FAILURE";
export var RESET_GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY = "RESET_GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY";

// Fetches currencyVariantInvestmentItems by base investment item equals currency Front API.
// Relies on Redux Thunk middleware.
export var loadCurrencyVariantInvestmentItemsByBaseInvestmentItemEqualsCurrency = function loadCurrencyVariantInvestmentItemsByBaseInvestmentItemEqualsCurrency() {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "full"
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_REQUEST, GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_SUCCESS, GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_FAILURE],
      endpoint: "currencyVariantInvestmentItems/search/findByBaseInvestmentItemEqualsCurrency".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};