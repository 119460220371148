import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var operationApiCalls = {
  loadOperationById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_OPERATIONBYID_REQUEST, ActionTypes.GET_OPERATIONBYID_SUCCESS, ActionTypes.GET_OPERATIONBYID_FAILURE, ActionTypes.RESET_GET_OPERATIONBYID]
  }),
  loadOperationRawJsonById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_OPERATIONRAWJSONBYID_REQUEST, ActionTypes.GET_OPERATIONRAWJSONBYID_SUCCESS, ActionTypes.GET_OPERATIONRAWJSONBYID_FAILURE, ActionTypes.RESET_GET_OPERATIONRAWJSONBYID]
  }),
  generateOpeningBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GENERATEOPENINGBALANCES_REQUEST, ActionTypes.GENERATEOPENINGBALANCES_SUCCESS, ActionTypes.GENERATEOPENINGBALANCES_FAILURE, ActionTypes.RESET_GENERATEOPENINGBALANCES]
  }),
  abortGenerateOpeningBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTGENERATEOPENINGBALANCES_REQUEST, ActionTypes.ABORTGENERATEOPENINGBALANCES_SUCCESS, ActionTypes.ABORTGENERATEOPENINGBALANCES_FAILURE, ActionTypes.RESET_ABORTGENERATEOPENINGBALANCES]
  }),
  findRebalanceOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.FINDREBALANCEOPERATIONS_REQUEST, ActionTypes.FINDREBALANCEOPERATIONS_SUCCESS, ActionTypes.FINDREBALANCEOPERATIONS_FAILURE, ActionTypes.RESET_FINDREBALANCEOPERATIONS]
  }),
  abortFindRebalanceOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTFINDREBALANCEOPERATIONS_REQUEST, ActionTypes.ABORTFINDREBALANCEOPERATIONS_SUCCESS, ActionTypes.ABORTFINDREBALANCEOPERATIONS_FAILURE, ActionTypes.RESET_ABORTFINDREBALANCEOPERATIONS]
  }),
  findSplitOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.FINDSPLITOPERATIONS_REQUEST, ActionTypes.FINDSPLITOPERATIONS_SUCCESS, ActionTypes.FINDSPLITOPERATIONS_FAILURE, ActionTypes.RESET_FINDSPLITOPERATIONS]
  }),
  abortFindSplitOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTFINDSPLITOPERATIONS_REQUEST, ActionTypes.ABORTFINDSPLITOPERATIONS_SUCCESS, ActionTypes.ABORTFINDSPLITOPERATIONS_FAILURE, ActionTypes.RESET_ABORTFINDSPLITOPERATIONS]
  }),
  postOperations: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.form;
    },
    types: [ActionTypes.POST_OPERATIONS_REQUEST, ActionTypes.POST_OPERATIONS_SUCCESS, ActionTypes.POST_OPERATIONS_FAILURE, ActionTypes.RESET_POST_OPERATIONS]
  }),
  postOperation: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.form, action.operation.id].join("-");
    },
    types: [ActionTypes.POST_OPERATION_REQUEST, ActionTypes.POST_OPERATION_SUCCESS, ActionTypes.POST_OPERATION_FAILURE, ActionTypes.RESET_POST_OPERATION]
  }),
  deleteOperation: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.operation.id + "";
    },
    types: [ActionTypes.DELETE_OPERATION_REQUEST, ActionTypes.DELETE_OPERATION_SUCCESS, ActionTypes.DELETE_OPERATION_FAILURE, ActionTypes.RESET_DELETE_OPERATION]
  }),
  loadOperationsByGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN]
  }),
  loadOperationsByGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN]
  }),
  loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN]
  }),
  loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN]
  }),
  fetchDepositsByUserIdAndStateId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.stateId].join("-");
    },
    types: [ActionTypes.GET_DEPOSITSBYUSERIDANDSTATEID_REQUEST, ActionTypes.GET_DEPOSITSBYUSERIDANDSTATEID_SUCCESS, ActionTypes.GET_DEPOSITSBYUSERIDANDSTATEID_FAILURE, ActionTypes.RESET_GET_DEPOSITSBYUSERIDANDSTATEID]
  }),
  loadOperationsByUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.stateIds].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEIDINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_REQUEST, ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEIDINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS, ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEIDINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYUSERIDANDSTATEIDINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT]
  })
};
export default operationApiCalls;