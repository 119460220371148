import * as ActionTypes from "../../actions";
var investmentItemRows = function investmentItemRows() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_INVESTMENTITEMS_SUCCESS:
      state = [];
      if (action.response._embedded.investmentItems) state.push.apply(state, action.response._embedded.investmentItems);
      if (action.response._embedded.currencies) state.push.apply(state, action.response._embedded.currencies);
      return state;
    default:
      return state;
  }
};
export default investmentItemRows;