function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTPARTY_REQUEST = "GET_INVESTMENTPARTY_REQUEST";
export var GET_INVESTMENTPARTY_SUCCESS = "GET_INVESTMENTPARTY_SUCCESS";
export var GET_INVESTMENTPARTY_FAILURE = "GET_INVESTMENTPARTY_FAILURE";
export var RESET_GET_INVESTMENTPARTY = "GET_INVESTMENTPARTY_FAILURE";

// Fetches investment party from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvestmentParty = function fetchInvestmentParty(id) {
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_INVESTMENTPARTY_REQUEST, GET_INVESTMENTPARTY_SUCCESS, GET_INVESTMENTPARTY_FAILURE],
    endpoint: "investmentParties/".concat(id)
  });
};

// Fetches a investment party unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvestmentParty = function loadInvestmentParty(id, forceUpdate) {
  return function (dispatch, getState) {
    var investmentParty = getState().entities.investmentParties.byId[id];
    if (investmentParty && !forceUpdate) return null;
    return dispatch(fetchInvestmentParty(id));
  };
};
export var GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_REQUEST = "GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_REQUEST";
export var GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_SUCCESS = "GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_SUCCESS";
export var GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_FAILURE = "GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_FAILURE";
export var RESET_GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC = "RESET_GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC";

// Fetches the latest investment party for a particular end date time unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvestmentPartyByStartInstantAfterOrderByStartInstantAsc = function loadInvestmentPartyByStartInstantAfterOrderByStartInstantAsc(startInstant) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      startInstant: startInstant
    }, CALL_API, {
      types: [GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_REQUEST, GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_SUCCESS, GET_FIRSTINVESTMENTPARTYBYSTARTINSTANTBEFOREORDERBYSTARTINSTANTASC_FAILURE],
      endpoint: "investmentParties/search/findFirstByStartInstantAfterOrderByStartInstantAsc?startInstant=".concat(startInstant),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTPARTIES_REQUEST = "GET_INVESTMENTPARTIES_REQUEST";
export var GET_INVESTMENTPARTIES_SUCCESS = "GET_INVESTMENTPARTIES_SUCCESS";
export var GET_INVESTMENTPARTIES_FAILURE = "GET_INVESTMENTPARTIES_FAILURE";
export var loadInvestmentParties = function loadInvestmentParties(page, size, idFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "full",
      idFilterValue: idFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTPARTIES_REQUEST, GET_INVESTMENTPARTIES_SUCCESS, GET_INVESTMENTPARTIES_FAILURE],
      endpoint: "investmentParties/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var CHECK_INVESTMENTPARTIES = "CHECK_INVESTMENTPARTIES";
export var checkInvestmentParty = function checkInvestmentParty(id) {
  return {
    id: id,
    type: CHECK_INVESTMENTPARTIES
  };
};
export var UNCHECK_INVESTMENTPARTIES = "UNCHECK_INVESTMENTPARTIES";
export var uncheckInvestmentParty = function uncheckInvestmentParty(id) {
  return {
    id: id,
    type: UNCHECK_INVESTMENTPARTIES
  };
};
export var CHECK_ALL_INVESTMENTPARTIES = "CHECK_ALL_INVESTMENTPARTIES";
export var checkAllInvestmentParty = function checkAllInvestmentParty() {
  return {
    type: CHECK_ALL_INVESTMENTPARTIES
  };
};
export var UNCHECK_ALL_INVESTMENTPARTIES = "UNCHECK_ALL_INVESTMENTPARTIES";
export var uncheckAllInvestmentParty = function uncheckAllInvestmentParty() {
  return {
    type: UNCHECK_ALL_INVESTMENTPARTIES
  };
};
export var POST_INVESTMENTPARTY_REQUEST = "POST_INVESTMENTPARTY_REQUEST";
export var POST_INVESTMENTPARTY_SUCCESS = "POST_INVESTMENTPARTY_SUCCESS";
export var POST_INVESTMENTPARTY_FAILURE = "POST_INVESTMENTPARTY_FAILURE";
export var RESET_POST_INVESTMENTPARTY = "RESET_POST_INVESTMENTPARTY";
export var postInvestmentParty = function postInvestmentParty(investmentParty) {
  return function (dispatch, getState) {
    var endpoint = "investmentParties";
    if (investmentParty.id) endpoint += "/".concat(investmentParty.id);
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_INVESTMENTPARTY_REQUEST, POST_INVESTMENTPARTY_SUCCESS, POST_INVESTMENTPARTY_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(investmentParty),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostInvestmentParty = function resetPostInvestmentParty() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_INVESTMENTPARTY
    });
  };
};
export var DELETE_INVESTMENTPARTIES_REQUEST = "DELETE_INVESTMENTPARTIES_REQUEST";
export var DELETE_INVESTMENTPARTIES_SUCCESS = "DELETE_INVESTMENTPARTIES_SUCCESS";
export var DELETE_INVESTMENTPARTIES_FAILURE = "DELETE_INVESTMENTPARTIES_FAILURE";
export var RESET_DELETE_INVESTMENTPARTY = "RESET_DELETE_INVESTMENTPARTY";
export var deleteInvestmentParties = function deleteInvestmentParties(investmentParties) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_INVESTMENTPARTIES_REQUEST, DELETE_INVESTMENTPARTIES_SUCCESS, DELETE_INVESTMENTPARTIES_FAILURE],
      endpoint: "investmentParties",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(investmentParties),
        credentials: "same-origin"
      }
    }));
  };
};