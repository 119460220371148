import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var campaignApiCalls = {
  postCampaign: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_CAMPAIGN_REQUEST, ActionTypes.POST_CAMPAIGN_SUCCESS, ActionTypes.POST_CAMPAIGN_FAILURE, ActionTypes.RESET_POST_CAMPAIGN]
  })
};
export default campaignApiCalls;