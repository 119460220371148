import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyVariantInvestmentItemApiCalls = {
  loadCurrencyVariantInvestmentItemsByBaseInvestmentItemEqualsCurrency: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_REQUEST, ActionTypes.GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_SUCCESS, ActionTypes.GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY_FAILURE, ActionTypes.RESET_GET_CURRENCYVARIANTINVESTMENTITEMSBYBASEINVESTMENTITEMEQUALSCURRENCY]
  })
};
export default currencyVariantInvestmentItemApiCalls;