import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var hashtagMentionListItemApiCalls = {
  fetchHashtagMentionListItemsByHashtagMentionHashtagId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.hashtagMentionHashtagId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID_REQUEST, ActionTypes.GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID_SUCCESS, ActionTypes.GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID_FAILURE, ActionTypes.RESET_GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID]
  })
};
export default hashtagMentionListItemApiCalls;