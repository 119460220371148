import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemsViewApiCalls = {
  fetchInvestmentItemsViewByMarketCountryIdIn: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.marketCountryIds.join(",");
    },
    types: [ActionTypes.GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_REQUEST, ActionTypes.GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_SUCCESS, ActionTypes.GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN]
  })
};
export default investmentItemsViewApiCalls;