import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var genderApiCalls = {
  fetchGenders: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_GENDERS_REQUEST, ActionTypes.GET_GENDERS_SUCCESS, ActionTypes.GET_GENDERS_FAILURE, ActionTypes.RESET_GET_GENDERS]
  })
};
export default genderApiCalls;