function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_REQUEST = "GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_REQUEST";
export var GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_SUCCESS = "GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_SUCCESS";
export var GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_FAILURE = "GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_FAILURE";
export var RESET_GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID = "RESET_GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID";

// Fetches the investmentPreference for a particular user and goal from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchInvestmentPreferenceByGoalIdAndUserId = function fetchInvestmentPreferenceByGoalIdAndUserId(goalId, userId) {
  var queryString = urlSearchParams({
    goalId: goalId,
    userId: userId,
    projection: "full"
  });
  return _defineProperty({
    goalId: goalId,
    userId: userId
  }, CALL_API, {
    types: [GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_REQUEST, GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_SUCCESS, GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_FAILURE],
    endpoint: "investmentPreferences/search/findByGoal_IdAndUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches the investmentPreference for a particular user and goal unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvestmentPreferenceByGoalIdAndUserId = function loadInvestmentPreferenceByGoalIdAndUserId(goalId, userId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [goalId, userId].join("-");
    var investmentPreferences = getState().entities.investmentPreferences.byGoalIdAndUserId[key];
    if (investmentPreferences && !forceUpdate) return null;
    return dispatch(fetchInvestmentPreferenceByGoalIdAndUserId(goalId, userId));
  };
};
export var POST_INVESTMENTPREFERENCE_REQUEST = "POST_INVESTMENTPREFERENCE_REQUEST";
export var POST_INVESTMENTPREFERENCE_SUCCESS = "POST_INVESTMENTPREFERENCE_SUCCESS";
export var POST_INVESTMENTPREFERENCE_FAILURE = "POST_INVESTMENTPREFERENCE_FAILURE";
export var RESET_POST_INVESTMENTPREFERENCE = "RESET_POST_INVESTMENTPREFERENCE";
export var postInvestmentPreference = function postInvestmentPreference(investmentPreference) {
  var endpoint = "investmentPreferences";
  if (investmentPreference.id) endpoint += "/".concat(investmentPreference.id);
  return _defineProperty({}, CALL_API, {
    types: [POST_INVESTMENTPREFERENCE_REQUEST, POST_INVESTMENTPREFERENCE_SUCCESS, POST_INVESTMENTPREFERENCE_FAILURE],
    endpoint: endpoint,
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(investmentPreference),
      credentials: "same-origin"
    }
  });
};
export var postInvestmentPreferenceFailure = function postInvestmentPreferenceFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_INVESTMENTPREFERENCE_FAILURE
    });
  };
};
export var resetPostPreference = function resetPostPreference() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_INVESTMENTPREFERENCE
    });
  };
};