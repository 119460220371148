import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bankHolidaysApiCalls = {
  postBankHoliday: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_BANKHOLIDAY_REQUEST, ActionTypes.POST_BANKHOLIDAY_SUCCESS, ActionTypes.POST_BANKHOLIDAY_FAILURE, ActionTypes.RESET_POST_BANKHOLIDAY]
  })
};
export default bankHolidaysApiCalls;