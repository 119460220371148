function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_PICK_REQUEST = "POST_PICK_REQUEST";
export var POST_PICK_SUCCESS = "POST_PICK_SUCCESS";
export var POST_PICK_FAILURE = "POST_PICK_FAILURE";
export var RESET_POST_PICK = "RESET_POST_PICK";
export var postPick = function postPick(pick) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      pick: pick
    }, CALL_API, {
      types: [POST_PICK_REQUEST, POST_PICK_SUCCESS, POST_PICK_FAILURE],
      endpoint: "picks",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(pick),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostPick = function resetPostPick() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_PICK
    });
  };
};