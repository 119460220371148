import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyGoalUserBalanceApiCalls = {
  loadFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.goalId || "null", action.userId].join("-");
    },
    types: [ActionTypes.GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_REQUEST, ActionTypes.GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_SUCCESS, ActionTypes.GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_FAILURE, ActionTypes.RESET_GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC]
  }),
  loadCurrencyGoalUserBalancesByGoalIdAndUserIdAndInstant: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.instant].join("-");
    },
    types: [ActionTypes.GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST, ActionTypes.GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS, ActionTypes.GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE, ActionTypes.RESET_GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT]
  }),
  loadCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.goalId || "null", action.userIds.join(","), action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT]
  }),
  loadCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.goalId || "null", action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default currencyGoalUserBalanceApiCalls;