function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_REQUEST = "GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_REQUEST";
export var GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_SUCCESS = "GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_SUCCESS";
export var GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_FAILURE = "GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_FAILURE";
export var RESET_GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID = "RESET_GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID";

// Fetches unitDecimalScale by broker id and investment item id from Front API.
// Relies on Redux Thunk middleware.
export var loadUnitDecimalScaleByBrokerIdAndInvestmentItemId = function loadUnitDecimalScaleByBrokerIdAndInvestmentItemId(brokerId, investmentItemId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      brokerId: brokerId,
      investmentItemId: investmentItemId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_REQUEST, GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_SUCCESS, GET_UNITDECIMALSCALEBYBROKERIDANDINVESTMENTITEMID_FAILURE],
      endpoint: "unitDecimalScales/search/findByBrokerIdAndInvestmentItemId".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_REQUEST = "GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_REQUEST";
export var GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_SUCCESS = "GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_SUCCESS";
export var GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_FAILURE = "GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_FAILURE";
export var RESET_GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN = "RESET_GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN";

// Fetches unitDecimalScaleS by investment item id from Front API.
// Relies on Redux Thunk middleware.
export var fetchUnitDecimalScalesByInvestmentItemIdIn = function fetchUnitDecimalScalesByInvestmentItemIdIn(investmentItemIds) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      investmentItemIds: investmentItemIds
    });
    return dispatch(_defineProperty({
      investmentItemIds: investmentItemIds
    }, CALL_API, {
      types: [GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_REQUEST, GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_SUCCESS, GET_UNITDECIMALSCALESBYINVESTMENTITEMIDIN_FAILURE],
      endpoint: "unitDecimalScales/search/findByInvestmentItemIdIn".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};

// Fetches unitDecimalScales by investmentItemIds unless it is cached.
// Relies on Redux Thunk middleware.
export var loadUnitDecimalScalesByInvestmentItemIdIn = function loadUnitDecimalScalesByInvestmentItemIdIn(investmentItemIds, forceUpdate) {
  return function (dispatch, getState) {
    var isCached = true;
    investmentItemIds.forEach(function (investmentItemId) {
      if (!getState().entities.unitDecimalScales.byInvestmentItemId[investmentItemId]) isCached = false;
    });
    if (isCached && !forceUpdate) return null;
    return dispatch(fetchUnitDecimalScalesByInvestmentItemIdIn(investmentItemIds));
  };
};