export var CHECK_EMPLOYEEROW = "CHECK_EMPLOYEEROW";
export var checkEmployeeRow = function checkEmployeeRow(id) {
  return {
    id: id,
    type: CHECK_EMPLOYEEROW
  };
};
export var UNCHECK_EMPLOYEEROW = "UNCHECK_EMPLOYEEROW";
export var uncheckEmployeeRow = function uncheckEmployeeRow(id) {
  return {
    id: id,
    type: UNCHECK_EMPLOYEEROW
  };
};
export var CHECK_ALL_EMPLOYEEROWS = "CHECK_ALL_EMPLOYEEROWS";
export var checkAllEmployeeRows = function checkAllEmployeeRows() {
  return {
    type: CHECK_ALL_EMPLOYEEROWS
  };
};
export var UNCHECK_ALL_EMPLOYEEROWS = "UNCHECK_ALL_EMPLOYEEROWS";
export var uncheckAllEmployeeRows = function uncheckAllEmployeeRows() {
  return {
    type: UNCHECK_ALL_EMPLOYEEROWS
  };
};