import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var membersViewApiCalls = {
  loadMembersViewByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_MEMBERSVIEWBYUSERID_REQUEST, ActionTypes.GET_MEMBERSVIEWBYUSERID_SUCCESS, ActionTypes.GET_MEMBERSVIEWBYUSERID_FAILURE, ActionTypes.RESET_GET_MEMBERSVIEWBYUSERID]
  })
};
export default membersViewApiCalls;