function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createTheme } from "@mui/material/styles";
import { pink, yellow, red, blue, green } from "@mui/material/colors";
import { brandPrimary, brandPrimaryFocus, brandSecondary, brandSecondaryFocus } from "../constants";
var _createTheme = createTheme(),
  palette = _createTheme.palette;
export default (function (customColors) {
  return createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: "none",
            "& .MuiDataGrid-withBorderColor": {
              borderColor: "#dee2e6"
            }
          }
        }
      }
    },
    shape: {
      borderRadius: 12
    },
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        }
      },
      MuiButton: {
        borderRadius: "50rem"
      },
      Select: {
        controlStyles: {
          borderRadius: 12,
          padding: "7px 4px"
        },
        menuStyle: {
          backgroundColor: "#ffffff",
          borderRadius: 12
        }
      },
      AsyncCreatable: {
        controlStyles: {
          borderRadius: 12,
          padding: "7px 4px"
        },
        menuStyle: {
          backgroundColor: "#ffffff",
          borderRadius: 12
        }
      },
      Async: {
        controlStyles: {
          borderRadius: 12,
          padding: "7px 4px"
        },
        menuStyle: {
          backgroundColor: "#ffffff",
          borderRadius: 12
        }
      }
    },
    palette: _objectSpread({
      pinky: palette.augmentColor({
        color: pink
      }),
      summer: palette.augmentColor({
        color: yellow
      }),
      primary: {
        main: brandPrimary,
        focus: brandPrimaryFocus,
        dark: brandPrimaryFocus,
        contrastText: "#ffffff",
        text: "#212529"
      },
      secondary: {
        main: brandSecondary,
        focus: brandSecondaryFocus,
        contrastText: "#ffffff"
      },
      success: palette.augmentColor({
        color: green
      }),
      warning: palette.augmentColor({
        color: yellow
      }),
      danger: palette.augmentColor({
        color: red
      }),
      link: palette.augmentColor({
        color: blue
      }),
      light: {
        main: "#ffffff",
        focus: "#ffffff",
        dark: "#ffffff",
        contrastText: "#212529"
      },
      dark: {
        main: "#212529",
        focus: "#212529",
        dark: "#212529",
        contrastText: "#212529",
        text: "#212529"
      },
      grey: {
        main: "rgba(23, 23, 23, 0.1)",
        focus: "rgba(23, 23, 23, 0.1)",
        dark: "rgba(23, 23, 23, 0.1)",
        contrastText: "rgba(23, 23, 23, 0.1)",
        text: "rgba(23, 23, 23, 0.1)"
      },
      white: {
        main: "#ffffff",
        focus: "#ffffff",
        dark: "#ffffff",
        contrastText: "#ffffff",
        text: "#ffffff"
      },
      mode: "light",
      type: "light",
      background: {
        default: "#ffffff",
        paper: "rgb(239, 241, 244)"
      },
      mixins: {
        toolbar: {
          minHeight: 64
        }
      }
    }, customColors)
  });
});