import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var uiPreferencesApiCalls = {
  loadUiPreferencesByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_UIPREFERENCESBYUSERID_REQUEST, ActionTypes.GET_UIPREFERENCESBYUSERID_SUCCESS, ActionTypes.GET_UIPREFERENCESBYUSERID_FAILURE, ActionTypes.RESET_GET_UIPREFERENCESBYUSERID]
  }),
  postUiPreferences: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_UIPREFERENCES_REQUEST, ActionTypes.POST_UIPREFERENCES_SUCCESS, ActionTypes.POST_UIPREFERENCES_FAILURE, ActionTypes.RESET_POST_UIPREFERENCES]
  })
};
export default uiPreferencesApiCalls;