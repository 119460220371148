import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var invertirOnlinePriceApiCalls = {
  collectInvertirOnlinePrices: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTINVERTIRONLINEPRICES_REQUEST, ActionTypes.COLLECTINVERTIRONLINEPRICES_SUCCESS, ActionTypes.COLLECTINVERTIRONLINEPRICES_FAILURE, ActionTypes.RESET_COLLECTINVERTIRONLINEPRICES]
  }),
  abortCollectInvertirOnlinePrices: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTINVERTIRONLINEPRICES_REQUEST, ActionTypes.ABORTCOLLECTINVERTIRONLINEPRICES_SUCCESS, ActionTypes.ABORTCOLLECTINVERTIRONLINEPRICES_FAILURE, ActionTypes.RESET_ABORTCOLLECTINVERTIRONLINEPRICES]
  }),
  loadInvertirOnlinePricesByTitleIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEEN]
  })
};
export default invertirOnlinePriceApiCalls;