import * as ActionTypes from "../../actions";
var eventRows = function eventRows() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTS_SUCCESS:
      state = [];
      if (action.response._embedded) state.push.apply(state, action.response._embedded.events);
      return state;
    default:
      return state;
  }
};
export default eventRows;