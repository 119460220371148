function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_NOTIFICATIONTOASTSBYUSERID_REQUEST = "GET_NOTIFICATIONTOASTSBYUSERID_REQUEST";
export var GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS = "GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS";
export var GET_NOTIFICATIONTOASTSBYUSERID_FAILURE = "GET_NOTIFICATIONTOASTSBYUSERID_FAILURE";
export var RESET_GET_NOTIFICATIONTOASTSBYUSERID = "RESET_GET_NOTIFICATIONTOASTSBYUSERID";

// Fetches event cards for a particular event state and user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchNotificationListItemsByUserId = function fetchNotificationListItemsByUserId(userId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_NOTIFICATIONTOASTSBYUSERID_REQUEST, GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS, GET_NOTIFICATIONTOASTSBYUSERID_FAILURE],
    endpoint: "notificationListItems/search/findByUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var loadNotificationListItemsByUserId = function loadNotificationListItemsByUserId(userId, maxId, sinceId, count) {
  return function (dispatch, getState) {
    var call = getState().apiCalls.loadNotificationListItemsByUserId[userId];
    if (call && call.isFetching) return null;
    return dispatch(fetchNotificationListItemsByUserId(userId, maxId, sinceId, count));
  };
};
export var GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_REQUEST = "GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_REQUEST";
export var GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS = "GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS";
export var GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_FAILURE = "GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_FAILURE";
export var RESET_GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL = "RESET_GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL";

// Fetches notification toasts for a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchNotificationListItemsByUserIdAndNotificationViewedAtIsNull = function fetchNotificationListItemsByUserIdAndNotificationViewedAtIsNull(userId) {
  var queryString = urlSearchParams({
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_REQUEST, GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS, GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_FAILURE],
    endpoint: "notificationListItems/search/findByUserIdAndNotificationViewedAtIsNull".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches notification toasts for a particular user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadNotificationListItemsByUserIdAndNotificationViewedAtIsNull = function loadNotificationListItemsByUserIdAndNotificationViewedAtIsNull(userId, forceUpdate) {
  return function (dispatch, getState) {
    var toasts = getState().entities.notificationListItems.byUserIdAndNotificationViewedAtIsNull[userId];
    if (toasts && !forceUpdate) return null;
    var call = getState().apiCalls.loadNotificationListItemsByUserIdAndNotificationViewedAtIsNull[userId];
    if (call && call.isFetching) return null;
    return dispatch(fetchNotificationListItemsByUserIdAndNotificationViewedAtIsNull(userId));
  };
};