import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemApiCalls = {
  loadInvestmentItemById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_INVESTMENTITEMBYID_REQUEST, ActionTypes.GET_INVESTMENTITEMBYID_SUCCESS, ActionTypes.GET_INVESTMENTITEMBYID_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMBYID]
  }),
  loadInvestmentItemsByAvailabilitiesBrokerId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerId.toString();
    },
    types: [ActionTypes.GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_REQUEST, ActionTypes.GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_SUCCESS, ActionTypes.GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID]
  }),
  postInvestmentItem: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_INVESTMENTITEM_REQUEST, ActionTypes.POST_INVESTMENTITEM_SUCCESS, ActionTypes.POST_INVESTMENTITEM_FAILURE, ActionTypes.RESET_POST_INVESTMENTITEM]
  })
};
export default investmentItemApiCalls;