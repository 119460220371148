import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var postListItemApiCalls = {
  fetchPostListItemsByPostParentIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postParentId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_REQUEST, ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID]
  }),
  fetchPostListItemsByPostUserIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postUserId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_REQUEST, ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID]
  }),
  fetchPostListItemsByPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postUserId, action.postGeolocationCountryIds.join(",")].join("-");
    },
    types: [ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_REQUEST, ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_SUCCESS, ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_FAILURE, ActionTypes.RESET_GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN]
  }),
  fetchPostListItemsByPostIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postId, action.userId].join("-");
    },
    types: [ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_REQUEST, ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID]
  })
};
export default postListItemApiCalls;