function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
export var GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export var GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";
export var fetchCampaigns = function fetchCampaigns(page, size, sort, projection, filterItems) {
  var queryString = urlSearchParams({
    page: page,
    size: size,
    sort: sort,
    projection: projection
  });
  return _defineProperty({}, CALL_API, {
    types: [GET_CAMPAIGNS_REQUEST, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAILURE],
    endpoint: "campaigns/search/findAll".concat(queryString),
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(filterItems),
      credentials: "same-origin"
    }
  });
};
export var GET_CAMPAIGNSBYNAMECONTAINING_REQUEST = "GET_CAMPAIGNSBYNAMECONTAINING_REQUEST";
export var GET_CAMPAIGNSBYNAMECONTAINING_SUCCESS = "GET_CAMPAIGNSBYNAMECONTAINING_SUCCESS";
export var GET_CAMPAIGNSBYNAMECONTAINING_FAILURE = "GET_CAMPAIGNSBYNAMECONTAINING_FAILURE";
export var RESET_GET_CAMPAIGNSBYNAMECONTAINING = "RESET_GET_CAMPAIGNSBYNAMECONTAINING";

// Fetches campaigns by name containing value from Front API.
// Relies on Redux Thunk middleware.
export var loadCampaignsByNameContaining = function loadCampaignsByNameContaining(page, size, name) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CAMPAIGNSBYNAMECONTAINING_REQUEST, GET_CAMPAIGNSBYNAMECONTAINING_SUCCESS, GET_CAMPAIGNSBYNAMECONTAINING_FAILURE],
      endpoint: "campaigns/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_CAMPAIGN_REQUEST = "POST_CAMPAIGN_REQUEST";
export var POST_CAMPAIGN_SUCCESS = "POST_CAMPAIGN_SUCCESS";
export var POST_CAMPAIGN_FAILURE = "POST_CAMPAIGN_FAILURE";
export var RESET_POST_CAMPAIGN = "RESET_POST_CAMPAIGN";
export var postCampaign = function postCampaign(campaign) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_CAMPAIGN_REQUEST, POST_CAMPAIGN_SUCCESS, POST_CAMPAIGN_FAILURE],
      endpoint: "campaigns",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(campaign),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostCampaign = function resetPostCampaign() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_CAMPAIGN
    });
  };
};
export var DELETE_CAMPAIGN_REQUEST = "DELETE_CAMPAIGN_REQUEST";
export var DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export var DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";
export var RESET_DELETE_CAMPAIGN = "RESET_DELETE_CAMPAIGN";
export var deleteCampaign = function deleteCampaign(campaign) {
  return _defineProperty({
    campaign: campaign
  }, CALL_API, {
    types: [DELETE_CAMPAIGN_REQUEST, DELETE_CAMPAIGN_SUCCESS, DELETE_CAMPAIGN_FAILURE],
    endpoint: "campaigns/".concat(campaign.id),
    init: {
      method: "DELETE",
      credentials: "same-origin"
    }
  });
};
export var resetDeleteOperation = function resetDeleteOperation(campaign) {
  return {
    campaign: campaign,
    type: RESET_DELETE_CAMPAIGN
  };
};