import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var investmentPlanApiCalls = {
  fetchInvestmentPlans: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTPLANS_REQUEST, ActionTypes.GET_INVESTMENTPLANS_SUCCESS, ActionTypes.GET_INVESTMENTPLANS_FAILURE, ActionTypes.RESET_GET_INVESTMENTPLANS]
  }),
  loadInvestmentPlans: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTPLANS_REQUEST, ActionTypes.GET_INVESTMENTPLANS_SUCCESS, ActionTypes.GET_INVESTMENTPLANS_FAILURE, ActionTypes.RESET_GET_INVESTMENTPLANS]
  })
};
export default investmentPlanApiCalls;