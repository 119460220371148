import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var industryApiCalls = {
  fetchIndustries: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INDUSTRIES_REQUEST, ActionTypes.GET_INDUSTRIES_SUCCESS, ActionTypes.GET_INDUSTRIES_FAILURE, ActionTypes.RESET_GET_INDUSTRIES]
  }),
  fetchIndustriesByInvestmentItemsMarketCountryIdIn: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_REQUEST, ActionTypes.GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_SUCCESS, ActionTypes.GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_FAILURE, ActionTypes.RESET_GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN]
  })
};
export default industryApiCalls;