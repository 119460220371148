import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var countBalanceEntities = function countBalanceEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byClassNameAndInstantBetweenGroupByDateFormat: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_SUCCESS:
      var byClassNameAndInstantBetweenGroupByDateFormat = action.response.countBalancesByClassNameAndInstantBetweenGroupByDateFormat;
      return merge({}, state, {
        byClassNameAndInstantBetweenGroupByDateFormat: byClassNameAndInstantBetweenGroupByDateFormat
      });
    default:
      return state;
  }
};
export default countBalanceEntities;