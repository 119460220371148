import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var personApiCalls = {
  loadPersonByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_PERSONBYUSERID_REQUEST, ActionTypes.GET_PERSONBYUSERID_SUCCESS, ActionTypes.GET_PERSONBYUSERID_FAILURE, ActionTypes.RESET_GET_PERSONBYUSERID]
  }),
  postPerson: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_PERSON_REQUEST, ActionTypes.POST_PERSON_SUCCESS, ActionTypes.POST_PERSON_FAILURE, ActionTypes.RESET_POST_PERSON]
  }),
  postPersonAddresses: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_PERSONADDRESSES_REQUEST, ActionTypes.POST_PERSONADDRESSES_SUCCESS, ActionTypes.POST_PERSONADDRESSES_FAILURE, ActionTypes.RESET_POST_PERSONADDRESSES]
  }),
  postPersonBankAccounts: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_PERSONBANKACCOUNTS_REQUEST, ActionTypes.POST_PERSONBANKACCOUNTS_SUCCESS, ActionTypes.POST_PERSONBANKACCOUNTS_FAILURE, ActionTypes.RESET_POST_PERSONBANKACCOUNTS]
  })
};
export default personApiCalls;