function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_REQUEST = "GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_REQUEST";
export var GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_SUCCESS = "GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_SUCCESS";
export var GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_FAILURE = "GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_FAILURE";
export var RESET_GET_BROKERVIEWBYBROKERIDANDAUTHUSERID = "RESET_GET_BROKERVIEWBYBROKERIDANDAUTHUSERID";

// Fetches a brokers view for a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchBrokerViewByBrokerIdAndAuthUserId = function fetchBrokerViewByBrokerIdAndAuthUserId(brokerId, authUserId) {
  var queryString = urlSearchParams({
    brokerId: brokerId,
    authUserId: authUserId
  });
  return _defineProperty({
    brokerId: brokerId,
    authUserId: authUserId
  }, CALL_API, {
    types: [GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_REQUEST, GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_SUCCESS, GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_FAILURE],
    endpoint: "brokerViews/search/findByBrokerId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// // Fetches an brokers view for a particular user unless it is cached.
// // Relies on Redux Thunk middleware.
// export const fetchBrokerViewByBrokerIdAndAuthUserId =
//   (brokerId, authUserId, forceUpdate) => (dispatch, getState) => {
//     const key = [brokerId, authUserId].join("-");
//     const view = getState().entities.brokerViews.byBrokerIdAndAuthUserId[key];
//     if (view && !forceUpdate) return null;

//     const call = getState().apiCalls.fetchBrokerViewByBrokerIdAndAuthUserId[key];
//     if (call && call.isFetching) return null;

//     return dispatch(
//       fetchBrokerViewByBrokerIdAndAuthUserId(brokerId, authUserId)
//     );
//   };