function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_PERSONBYUSERID_REQUEST = "GET_PERSONBYUSERID_REQUEST";
export var GET_PERSONBYUSERID_SUCCESS = "GET_PERSONBYUSERID_SUCCESS";
export var GET_PERSONBYUSERID_FAILURE = "GET_PERSONBYUSERID_FAILURE";
export var RESET_GET_PERSONBYUSERID = "RESET_GET_PERSONBYUSERID";

// Fetches a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchPersonByUserId = function fetchPersonByUserId(userId) {
  var queryString = urlSearchParams({
    projection: "full",
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_PERSONBYUSERID_REQUEST, GET_PERSONBYUSERID_SUCCESS, GET_PERSONBYUSERID_FAILURE],
    endpoint: "persons/search/findByUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches a particular user by email from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadPersonByUserId = function loadPersonByUserId(userId, forceUpdate) {
  return function (dispatch, getState) {
    var user = getState().entities.persons.byUserId[userId];
    if (user && !forceUpdate) return null;
    var call = getState().apiCalls.loadPersonByUserId[userId];
    if (call && call.isFetching) return null;
    return dispatch(fetchPersonByUserId(userId));
  };
};
export var POST_PERSON_REQUEST = "POST_PERSON_REQUEST";
export var POST_PERSON_SUCCESS = "POST_PERSON_SUCCESS";
export var POST_PERSON_FAILURE = "POST_PERSON_FAILURE";
export var RESET_POST_PERSON = "RESET_POST_PERSON";
export var postPerson = function postPerson(person) {
  return function (dispatch, getState) {
    var endpoint = "persons";
    if (person.id) endpoint += "/".concat(person.id);
    return dispatch(_defineProperty({
      person: person
    }, CALL_API, {
      types: [POST_PERSON_REQUEST, POST_PERSON_SUCCESS, POST_PERSON_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(person),
        credentials: "same-origin"
      }
    }));
  };
};
export var postPersonFailure = function postPersonFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_PERSON_FAILURE
    });
  };
};
export var resetPostUser = function resetPostUser() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_PERSON
    });
  };
};
export var POST_PERSONADDRESSES_REQUEST = "POST_PERSONADDRESSES_REQUEST";
export var POST_PERSONADDRESSES_SUCCESS = "POST_PERSONADDRESSES_SUCCESS";
export var POST_PERSONADDRESSES_FAILURE = "POST_PERSONADDRESSES_FAILURE";
export var RESET_POST_PERSONADDRESSES = "RESET_POST_PERSONADDRESSES";
export var postPersonAddresses = function postPersonAddresses(id, addresses) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      id: id,
      addresses: addresses
    }, CALL_API, {
      types: [POST_PERSONADDRESSES_REQUEST, POST_PERSONADDRESSES_SUCCESS, POST_PERSONADDRESSES_FAILURE],
      endpoint: "persons/".concat(id, "/addresses"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(addresses),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostPersonAddresses = function resetPostPersonAddresses(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_PERSONADDRESSES
    });
  };
};
export var POST_PERSONBANKACCOUNTS_REQUEST = "POST_PERSONBANKACCOUNTS_REQUEST";
export var POST_PERSONBANKACCOUNTS_SUCCESS = "POST_PERSONBANKACCOUNTS_SUCCESS";
export var POST_PERSONBANKACCOUNTS_FAILURE = "POST_PERSONBANKACCOUNTS_FAILURE";
export var RESET_POST_PERSONBANKACCOUNTS = "RESET_POST_PERSONBANKACCOUNTS";
export var postPersonBankAccounts = function postPersonBankAccounts(id, bankAccounts) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      id: id,
      bankAccounts: bankAccounts
    }, CALL_API, {
      types: [POST_PERSONBANKACCOUNTS_REQUEST, POST_PERSONBANKACCOUNTS_SUCCESS, POST_PERSONBANKACCOUNTS_FAILURE],
      endpoint: "persons/".concat(id, "/bankAccounts"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(bankAccounts),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostPersonBankAccounts = function resetPostPersonBankAccounts(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_PERSONBANKACCOUNTS
    });
  };
};