function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_WIRETRANSFERSBYOPERATIONIDS_REQUEST = "GET_WIRETRANSFERSBYOPERATIONIDS_REQUEST";
export var GET_WIRETRANSFERSBYOPERATIONIDS_SUCCESS = "GET_WIRETRANSFERSBYOPERATIONIDS_SUCCESS";
export var GET_WIRETRANSFERSBYOPERATIONIDS_FAILURE = "GET_WIRETRANSFERSBYOPERATIONIDS_FAILURE";
export var RESET_GET_WIRETRANSFERSBYOPERATIONIDS = "RESET_GET_WIRETRANSFERSBYOPERATIONIDS";
export var loadWireTransfersByOperationIds = function loadWireTransfersByOperationIds(operationIds) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      operationIds: operationIds
    }, CALL_API, {
      types: [GET_WIRETRANSFERSBYOPERATIONIDS_REQUEST, GET_WIRETRANSFERSBYOPERATIONIDS_SUCCESS, GET_WIRETRANSFERSBYOPERATIONIDS_FAILURE],
      endpoint: "wireTransfers/search/findByOperationIds?operationIds=".concat(operationIds),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var resetLoadWireTransfersByOperationIds = function resetLoadWireTransfersByOperationIds() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_GET_WIRETRANSFERSBYOPERATIONIDS
    });
  };
};
export var GET_WIRETRANSFERSBYUSERID_REQUEST = "GET_WIRETRANSFERSBYUSERID_REQUEST";
export var GET_WIRETRANSFERSBYUSERID_SUCCESS = "GET_WIRETRANSFERSBYUSERID_SUCCESS";
export var GET_WIRETRANSFERSBYUSERID_FAILURE = "GET_WIRETRANSFERSBYUSERID_FAILURE";
export var RESET_GET_WIRETRANSFERSBYUSERID = "RESET_GET_WIRETRANSFERSBYUSERID";

// Fetches wireTransfers for a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchWireTransfersByUserId = function fetchWireTransfersByUserId(userId) {
  var queryString = urlSearchParams({
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_WIRETRANSFERSBYUSERID_REQUEST, GET_WIRETRANSFERSBYUSERID_SUCCESS, GET_WIRETRANSFERSBYUSERID_FAILURE],
    endpoint: "wireTransfers/search/findByPendingDepositsAndUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches wireTransfers for a particular user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadWireTransfersByUserId = function loadWireTransfersByUserId(userId, forceUpdate) {
  return function (dispatch, getState) {
    var wireTransfers = getState().entities.wireTransfers.byUserId[userId];
    if (wireTransfers && !forceUpdate) return null;
    var call = getState().apiCalls.loadWireTransfersByUserId["undefined"];
    if (call && call.isFetching) return null;
    return dispatch(fetchWireTransfersByUserId(userId));
  };
};