import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerCurrencyInvestmentItemBalanceApiCalls = {
  loadBrokerCurrencyInvestmentItemBalancesByBrokerIdAndCurrencyIdAndDate: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.date].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYINVESTMENTITEMBALANCESBYBROKERIDANDCURRENCYIDANDDATE_REQUEST, ActionTypes.GET_BROKERCURRENCYINVESTMENTITEMBALANCESBYBROKERIDANDCURRENCYIDANDDATE_SUCCESS, ActionTypes.GET_BROKERCURRENCYINVESTMENTITEMBALANCESBYBROKERIDANDCURRENCYIDANDDATE_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYINVESTMENTITEMBALANCESBYBROKERIDANDCURRENCYIDANDDATE]
  })
};
export default brokerCurrencyInvestmentItemBalanceApiCalls;