export var CHECK_INVESTMENTACCOUNTROWS = "CHECK_INVESTMENTACCOUNTROWS";
export var checkInvestmentAccount = function checkInvestmentAccount(id) {
  return {
    id: id,
    type: CHECK_INVESTMENTACCOUNTROWS
  };
};
export var UNCHECK_INVESTMENTACCOUNTROWS = "UNCHECK_INVESTMENTACCOUNTROWS";
export var uncheckInvestmentAccount = function uncheckInvestmentAccount(id) {
  return {
    id: id,
    type: UNCHECK_INVESTMENTACCOUNTROWS
  };
};
export var CHECK_ALL_INVESTMENTACCOUNTROWS = "CHECK_ALL_INVESTMENTACCOUNTROWS";
export var checkAllInvestmentAccount = function checkAllInvestmentAccount() {
  return {
    type: CHECK_ALL_INVESTMENTACCOUNTROWS
  };
};
export var UNCHECK_ALL_INVESTMENTACCOUNTROWS = "UNCHECK_ALL_INVESTMENTACCOUNTROWS";
export var uncheckAllInvestmentAccount = function uncheckAllInvestmentAccount() {
  return {
    type: UNCHECK_ALL_INVESTMENTACCOUNTROWS
  };
};