function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var priceEntities = function priceEntities() {
  var _ref, _Object$assign;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (_ref = {
    byId: {},
    byBrokerIsNullAndCurrencyIdAndInvestmentItemIdAndInstantBetweenGroupByDateFormatOrderByInstantDesc: {},
    byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: {},
    firstByCurrencyIdAndInvestmentItemIdOrderByInstantDesc: {}
  }, _defineProperty(_ref, "byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat", {}), _defineProperty(_ref, "byInvestmentItemIdAndInstantBetweenGroupByDateFormat", {}), _defineProperty(_ref, "firstByBrokerIsNullAndCurrencyIdAndInvestmentItemIdOrderByInstantDesc", {}), _defineProperty(_ref, "firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc", {}), _defineProperty(_ref, "firstByVariantInvestmentItemIdOrderByInstantDesc", {}), _defineProperty(_ref, "byBrokerIdInAndCurrencyIdAndInvestmentItemIdAndInstantBetweenGroupByDateFormat", {}), _ref);
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_PRICES_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.prices.forEach(function (price) {
        return byId[price.id] = price;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_WATCHLISTITEMLISTITEMSBYUSERID_SUCCESS:
      if (!action.response) return state;
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      action.response.forEach(function (listItem) {
        return merge(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat, listItem.investmentItemListItemByInvestmentItemWatchListItemsId[listItem.watchlistItemId].pricesByVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      });
      return Object.assign({}, state, {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_INVESTMENTITEMLISTITEMSBYMARKETCOUNTRYIDIN_SUCCESS:
      if (!action.response._embedded) return state;
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      action.response._embedded.investmentItemListItems.forEach(function (listItem) {
        return merge(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat, listItem.pricesByVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      });
      return Object.assign({}, state, {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_INVESTMENTITEMSVIEWBYMARKETCOUNTRYIDIN_SUCCESS:
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      [].concat(_toConsumableArray(Object.values(action.response.investmentItemListItemsByMarketCountryIdIn)), _toConsumableArray(Object.values(action.response.investmentItemListItemsByTypeIdAndMarketCountryIdIn)), _toConsumableArray(Object.values(action.response.investmentItemListItemsByIndustryIdAndMarketCountryIdIn))).forEach(function (listItems) {
        return listItems.forEach(function (listItem) {
          return merge(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat, listItem.pricesByVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
        });
      });
      return Object.assign({}, state, {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS:
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = action.response.pricesByVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat;
      return merge({}, state, {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_OPERATIONFORMBYINVESTMENTITEMIDANDUSERID_SUCCESS:
      var firstByCurrencyIdAndInvestmentItemIdOrderByInstantDesc = action.response.firstPriceByCurrencyIdAndInvestmentItemIdOrderByInstantDesc;
      return merge({}, state, {
        firstByCurrencyIdAndInvestmentItemIdOrderByInstantDesc: firstByCurrencyIdAndInvestmentItemIdOrderByInstantDesc
      });
    case ActionTypes.GET_PRICESBYVARIANTINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS:
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      var key = [action.variantInvestmentItemId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
      byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat[key] = action.response;
      return Object.assign({}, state, {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS:
      var byInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      var key = [action.investmentItemId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
      byInvestmentItemIdAndInstantBetweenGroupByDateFormat[key] = action.response._embedded.prices;
      return Object.assign({}, state, {
        byInvestmentItemIdAndInstantBetweenGroupByDateFormat: byInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDMARKETIDORDERBYINSTANTDESC_SUCCESS:
      var firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc = _objectSpread({}, state.firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc);
      var key = [action.investmentItemId, action.currencyId, action.marketId || "null"].join("-");
      firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc[key] = action.response;
      return Object.assign({}, state, {
        firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc: firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc
      });
    case ActionTypes.GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_SUCCESS:
      var firstByVariantInvestmentItemIdOrderByInstantDesc = _objectSpread({}, state.firstByVariantInvestmentItemIdOrderByInstantDesc);
      firstByVariantInvestmentItemIdOrderByInstantDesc[action.variantInvestmentItemId] = action.response;
      return Object.assign({}, state, {
        firstByVariantInvestmentItemIdOrderByInstantDesc: firstByVariantInvestmentItemIdOrderByInstantDesc
      });
    case ActionTypes.PUSH_PRICE:
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      var keyFraction = [action.price.variantInvestmentItem.id].join("-");
      Object.keys(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat).filter(function (key) {
        return key.startsWith(keyFraction);
      }).forEach(function (key) {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat[key] = [action.price].concat(_toConsumableArray(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat[key]));
      });
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      var keyFraction = [action.price.investmentItem.id, action.price.currency.id, action.price.broker && action.price.broker.id].join("-");
      Object.keys(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat).filter(function (key) {
        return key.startsWith(keyFraction);
      }).forEach(function (key) {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat[key] = [action.price].concat(_toConsumableArray(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat[key]));
      });
      var firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc = _objectSpread({}, state.firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc);
      var keyFraction = [action.price.investmentItem.id, action.price.currency.id, action.price.broker && action.price.broker.id].join("-");
      Object.keys(firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc).filter(function (key) {
        return key.startsWith(keyFraction);
      }).forEach(function (key) {
        firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc[key] = _objectSpread({}, action.price);
      });
      return Object.assign({}, state, (_Object$assign = {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      }, _defineProperty(_Object$assign, "byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat", byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat), _defineProperty(_Object$assign, "firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc", firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc), _Object$assign));
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat = _objectSpread({}, state.byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        return Object.values(eventListItem.postListItemByPostId).forEach(function (postListItem) {
          return Object.values(postListItem.investmentItemMentionListItemsByPostId).forEach(function (mentionListItems) {
            return mentionListItems.forEach(function (mentionListItem) {
              return merge(byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat, mentionListItem.pricesByVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat);
            });
          });
        });
      });
      return Object.assign({}, state, {
        byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat: byVariantInvestmentItemIdAndInstantBetweenGroupByDateFormat
      });
    case ActionTypes.GET_PRICELINECHARTDATASETSBYINVESTMENTITEMID_SUCCESS:
      var firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc = _objectSpread({}, state.firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc);
      action.response.forEach(function (dataset) {
        merge(firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc, dataset.firstPriceByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc);
      });
      return Object.assign({}, state, {
        firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc: firstByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc
      });
    default:
      return state;
  }
};
export default priceEntities;