import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bankAccountTypeApiCalls = {
  fetchBankAccountTypesByIdIn: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BANKACCOUNTTYPESBYIDIN_REQUEST, ActionTypes.GET_BANKACCOUNTTYPESBYIDIN_SUCCESS, ActionTypes.GET_BANKACCOUNTTYPESBYIDIN_FAILURE, ActionTypes.RESET_GET_BANKACCOUNTTYPESBYIDIN]
  })
};
export default bankAccountTypeApiCalls;