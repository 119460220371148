import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var ivaTaxpayerTypeApiCalls = {
  fetchIvaTaxpayerTypes: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_IVATAXPAYERTYPES_REQUEST, ActionTypes.GET_IVATAXPAYERTYPES_SUCCESS, ActionTypes.GET_IVATAXPAYERTYPES_FAILURE, ActionTypes.RESET_GET_IVATAXPAYERTYPES]
  })
};
export default ivaTaxpayerTypeApiCalls;