import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var hashtagEntities = function hashtagEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byOrderByMentionsCountDesc: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_HASHTAGVIEWBYHASHTAGTEXTANDAUTHUSERID_SUCCESS:
      var byId = action.response.hashtagById;
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_SUCCESS:
      if (!action.response._embedded) return state;
      var byOrderByMentionsCountDesc = action.response._embedded.hashtags;
      return merge({}, state, {
        byOrderByMentionsCountDesc: byOrderByMentionsCountDesc
      });
    default:
      return state;
  }
};
export default hashtagEntities;