import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerViewApiCalls = {
  fetchBrokerViewByBrokerIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_REQUEST, ActionTypes.GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_BROKERVIEWBYBROKERIDANDAUTHUSERID]
  })
};
export default brokerViewApiCalls;