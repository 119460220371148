import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemListItemApiCalls = {
  fetchInvestmentItemListItemsByMarketCountryIdIn: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.marketCountryIds.join(",");
    },
    types: [ActionTypes.GET_INVESTMENTITEMLISTITEMSBYMARKETCOUNTRYIDIN_REQUEST, ActionTypes.GET_INVESTMENTITEMLISTITEMSBYMARKETCOUNTRYIDIN_SUCCESS, ActionTypes.GET_INVESTMENTITEMLISTITEMSBYMARKETCOUNTRYIDIN_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMLISTITEMSBYMARKETCOUNTRYIDIN]
  })
};
export default investmentItemListItemApiCalls;