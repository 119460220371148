import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerFollowerApiCalls = {
  postBrokerFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerFollower.brokerId.toString();
    },
    types: [ActionTypes.POST_BROKERFOLLOWER_REQUEST, ActionTypes.POST_BROKERFOLLOWER_SUCCESS, ActionTypes.POST_BROKERFOLLOWER_FAILURE, ActionTypes.RESET_POST_BROKERFOLLOWER]
  }),
  deleteBrokerFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerFollower.id.toString();
    },
    types: [ActionTypes.DELETE_BROKERFOLLOWER_REQUEST, ActionTypes.DELETE_BROKERFOLLOWER_SUCCESS, ActionTypes.DELETE_BROKERFOLLOWER_FAILURE, ActionTypes.RESET_DELETE_BROKERFOLLOWER]
  })
};
export default brokerFollowerApiCalls;