import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var memberApiCalls = {
  loadMemberById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_MEMBERBYID_REQUEST, ActionTypes.GET_MEMBERBYID_SUCCESS, ActionTypes.GET_MEMBERBYID_FAILURE, ActionTypes.RESET_GET_MEMBERBYID]
  }),
  loadMembersByUserIdAndStateInAndConfirmedAtNotNull: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.states].join("-");
    },
    types: [ActionTypes.GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST, ActionTypes.GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS, ActionTypes.GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE, ActionTypes.RESET_GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL
    // ActionTypes.POST_GOAL_SUCCESS,
    // ActionTypes.POST_OPERATIONS_SUCCESS,
    // ActionTypes.POST_OPERATION_SUCCESS,
    // ActionTypes.DELETE_OPERATION_SUCCESS
    ]
  }),

  // postMember: status({
  //   mapActionToKey: () => 'undefined',
  //   types: [
  //     ActionTypes.POST_MEMBER_REQUEST,
  //     ActionTypes.POST_MEMBER_SUCCESS,
  //     ActionTypes.POST_MEMBER_FAILURE,
  //     ActionTypes.RESET_POST_MEMBER
  //   ]
  // }),
  postMember: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.member.userId, action.member.followeeUsername].join("-");
    },
    types: [ActionTypes.POST_MEMBER_REQUEST, ActionTypes.POST_MEMBER_SUCCESS, ActionTypes.POST_MEMBER_FAILURE, ActionTypes.RESET_POST_MEMBER]
  }),
  deleteMember: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.member.id.toString();
    },
    types: [ActionTypes.DELETE_MEMBER_REQUEST, ActionTypes.DELETE_MEMBER_SUCCESS, ActionTypes.DELETE_MEMBER_FAILURE, ActionTypes.RESET_DELETE_MEMBER]
  }),
  postMemberExclusionPreferences: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_MEMBEREXCLUSIONPREFERENCES_REQUEST, ActionTypes.POST_MEMBEREXCLUSIONPREFERENCES_SUCCESS, ActionTypes.POST_MEMBEREXCLUSIONPREFERENCES_FAILURE, ActionTypes.RESET_POST_MEMBEREXCLUSIONPREFERENCES]
  })
};
export default memberApiCalls;