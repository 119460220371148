import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerCurrencyInvestmentItemUserBalanceApiCalls = {
  loadBrokerCurrencyInvestmentItemUserBalancesByBrokerIdAndCurrencyIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.investmentItemId, action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_BROKERCURRENCYINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_BROKERCURRENCYINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYINVESTMENTITEMUSERBALANCESBYBROKERIDANDCURRENCYIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default brokerCurrencyInvestmentItemUserBalanceApiCalls;