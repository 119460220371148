import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var balanceApiCalls = {
  generateBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GENERATEBALANCES_REQUEST, ActionTypes.GENERATEBALANCES_SUCCESS, ActionTypes.GENERATEBALANCES_FAILURE, ActionTypes.RESET_GENERATEBALANCES]
  }),
  abortGenerateBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTGENERATEBALANCES_REQUEST, ActionTypes.ABORTGENERATEBALANCES_SUCCESS, ActionTypes.ABORTGENERATEBALANCES_FAILURE, ActionTypes.RESET_ABORTGENERATEBALANCES]
  })
};
export default balanceApiCalls;