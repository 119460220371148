import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var wireTransferApiCalls = {
  loadWireTransfersByOperationIds: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_WIRETRANSFERSBYOPERATIONIDS_REQUEST, ActionTypes.GET_WIRETRANSFERSBYOPERATIONIDS_SUCCESS, ActionTypes.GET_WIRETRANSFERSBYOPERATIONIDS_FAILURE, ActionTypes.RESET_GET_WIRETRANSFERSBYOPERATIONIDS]
  }),
  loadWireTransfersByUserId: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_WIRETRANSFERSBYUSERID_REQUEST, ActionTypes.GET_WIRETRANSFERSBYUSERID_SUCCESS, ActionTypes.GET_WIRETRANSFERSBYUSERID_FAILURE, ActionTypes.RESET_GET_WIRETRANSFERSBYUSERID]
  })
};
export default wireTransferApiCalls;