import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var cafciClaseViewApiCalls = {
  fetchCafciClaseViewByInvestmentItemIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return "undefined";
    },
    types: [ActionTypes.GET_CAFCICLASEVIEWBYINVESTMENTITEMIDANDAUTHUSERID_REQUEST, ActionTypes.GET_CAFCICLASEVIEWBYINVESTMENTITEMIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_CAFCICLASEVIEWBYINVESTMENTITEMIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_CAFCICLASEVIEWBYINVESTMENTITEMIDANDAUTHUSERID]
  })
};
export default cafciClaseViewApiCalls;