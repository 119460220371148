function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTACCOUNTS_REQUEST = "GET_INVESTMENTACCOUNTS_REQUEST";
export var GET_INVESTMENTACCOUNTS_SUCCESS = "GET_INVESTMENTACCOUNTS_SUCCESS";
export var GET_INVESTMENTACCOUNTS_FAILURE = "GET_INVESTMENTACCOUNTS_FAILURE";
export var loadInvestmentAccounts = function loadInvestmentAccounts(page, size, idFilterValue, brokerIdFilterValue, userIdFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "id,desc",
      projection: "withInvestmentItemAndCurrency",
      idFilterValue: idFilterValue,
      brokerIdFilterValue: brokerIdFilterValue,
      userIdFilterValue: userIdFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTACCOUNTS_REQUEST, GET_INVESTMENTACCOUNTS_SUCCESS, GET_INVESTMENTACCOUNTS_FAILURE],
      endpoint: "investmentAccounts/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTACCOUNTSBYBROKERID_REQUEST = "GET_INVESTMENTACCOUNTSBYBROKERID_REQUEST";
export var GET_INVESTMENTACCOUNTSBYBROKERID_SUCCESS = "GET_INVESTMENTACCOUNTSBYBROKERID_SUCCESS";
export var GET_INVESTMENTACCOUNTSBYBROKERID_FAILURE = "GET_INVESTMENTACCOUNTSBYBROKERID_FAILURE";
export var loadInvestmentAccountsByBrokerId = function loadInvestmentAccountsByBrokerId(page, size, brokerId, idFilterValue, userIdFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      // projection: 'withInvestmentItemAndCurrency',
      brokerId: brokerId,
      idFilterValue: idFilterValue,
      userIdFilterValue: userIdFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({
      brokerId: brokerId
    }, CALL_API, {
      types: [GET_INVESTMENTACCOUNTSBYBROKERID_REQUEST, GET_INVESTMENTACCOUNTSBYBROKERID_SUCCESS, GET_INVESTMENTACCOUNTSBYBROKERID_FAILURE],
      endpoint: "investmentAccounts/search/findAllByBroker_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTACCOUNTSBYUSERID_REQUEST = "GET_INVESTMENTACCOUNTSBYUSERID_REQUEST";
export var GET_INVESTMENTACCOUNTSBYUSERID_SUCCESS = "GET_INVESTMENTACCOUNTSBYUSERID_SUCCESS";
export var GET_INVESTMENTACCOUNTSBYUSERID_FAILURE = "GET_INVESTMENTACCOUNTSBYUSERID_FAILURE";
export var RESET_GET_INVESTMENTACCOUNTSBYUSERID = "RESET_GET_INVESTMENTACCOUNTSBYUSERID";
var fetchInvestmentAccountsByUserId = function fetchInvestmentAccountsByUserId(userId) {
  var queryString = urlSearchParams({
    projection: "full",
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_INVESTMENTACCOUNTSBYUSERID_REQUEST, GET_INVESTMENTACCOUNTSBYUSERID_SUCCESS, GET_INVESTMENTACCOUNTSBYUSERID_FAILURE],
    endpoint: "investmentAccounts/search/findByUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var loadInvestmentAccountsByUserId = function loadInvestmentAccountsByUserId(userId) {
  return function (dispatch, getState) {
    var call = getState().apiCalls.loadInvestmentAccountsByUserId[userId];
    if (call && call.isFetching) return null;
    return dispatch(fetchInvestmentAccountsByUserId(userId));
  };
};
export var POST_INVESTMENTACCOUNT_REQUEST = "POST_INVESTMENTACCOUNT_REQUEST";
export var POST_INVESTMENTACCOUNT_SUCCESS = "POST_INVESTMENTACCOUNT_SUCCESS";
export var POST_INVESTMENTACCOUNT_FAILURE = "POST_INVESTMENTACCOUNT_FAILURE";
export var RESET_POST_INVESTMENTACCOUNT = "RESET_POST_INVESTMENTACCOUNT";
export var postInvestmentAccount = function postInvestmentAccount(investmentAccount) {
  return function (dispatch, getState) {
    var endpoint = "investmentAccounts";
    if (investmentAccount.id) endpoint += "/".concat(investmentAccount.id);
    return dispatch(_defineProperty({
      investmentAccount: investmentAccount
    }, CALL_API, {
      types: [POST_INVESTMENTACCOUNT_REQUEST, POST_INVESTMENTACCOUNT_SUCCESS, POST_INVESTMENTACCOUNT_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(investmentAccount),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostInvestmentAccount = function resetPostInvestmentAccount(investmentAccount) {
  return function (dispatch, getState) {
    return dispatch({
      investmentAccount: investmentAccount,
      type: RESET_POST_INVESTMENTACCOUNT
    });
  };
};
export var DELETE_INVESTMENTACCOUNTS_REQUEST = "DELETE_INVESTMENTACCOUNTS_REQUEST";
export var DELETE_INVESTMENTACCOUNTS_SUCCESS = "DELETE_INVESTMENTACCOUNTS_SUCCESS";
export var DELETE_INVESTMENTACCOUNTS_FAILURE = "DELETE_INVESTMENTACCOUNTS_FAILURE";
export var RESET_DELETE_INVESTMENTACCOUNT = "RESET_DELETE_INVESTMENTACCOUNT";
export var deleteInvestmentAccounts = function deleteInvestmentAccounts(investmentAccounts) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_INVESTMENTACCOUNTS_REQUEST, DELETE_INVESTMENTACCOUNTS_SUCCESS, DELETE_INVESTMENTACCOUNTS_FAILURE],
      endpoint: "investmentAccounts",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(investmentAccounts),
        credentials: "same-origin"
      }
    }));
  };
};
export var ENABLEINVESTMENTACCOUNT_REQUEST = "ENABLEINVESTMENTACCOUNT_REQUEST";
export var ENABLEINVESTMENTACCOUNT_SUCCESS = "ENABLEINVESTMENTACCOUNT_SUCCESS";
export var ENABLEINVESTMENTACCOUNT_FAILURE = "ENABLEINVESTMENTACCOUNT_FAILURE";
export var RESET_ENABLEINVESTMENTACCOUNT = "RESET_ENABLEINVESTMENTACCOUNT";
export var enableInvestmentAccount = function enableInvestmentAccount(investmentAccount) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      investmentAccount: investmentAccount
    }, CALL_API, {
      types: [ENABLEINVESTMENTACCOUNT_REQUEST, ENABLEINVESTMENTACCOUNT_SUCCESS, ENABLEINVESTMENTACCOUNT_FAILURE],
      endpoint: "investmentAccounts/".concat(investmentAccount.id, "/enable"),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var resetEnableInvestmentAccount = function resetEnableInvestmentAccount(investmentAccount) {
  return function (dispatch, getState) {
    return dispatch({
      investmentAccount: investmentAccount,
      type: RESET_ENABLEINVESTMENTACCOUNT
    });
  };
};