import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var membersTabApiCalls = {
  loadMembersTabByGoalIdAndBalanceInstant: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId, action.balanceInstant].join("-");
    },
    types: [ActionTypes.GET_MEMBERSTABBYGOALIDANDBALANCEINSTANT_REQUEST, ActionTypes.GET_MEMBERSTABBYGOALIDANDBALANCEINSTANT_SUCCESS, ActionTypes.GET_MEMBERSTABBYGOALIDANDBALANCEINSTANT_FAILURE, ActionTypes.RESET_GET_MEMBERSTABBYGOALIDANDBALANCEINSTANT]
  })
};
export default membersTabApiCalls;