import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bankApiCalls = {
  loadBanks: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BANKS_REQUEST, ActionTypes.GET_BANKS_SUCCESS, ActionTypes.GET_BANKS_FAILURE, ActionTypes.RESET_GET_BANKS]
  })
};
export default bankApiCalls;