function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BOOSTSVIEWBYUSERID_REQUEST = "GET_BOOSTSVIEWBYUSERID_REQUEST";
export var GET_BOOSTSVIEWBYUSERID_SUCCESS = "GET_BOOSTSVIEWBYUSERID_SUCCESS";
export var GET_BOOSTSVIEWBYUSERID_FAILURE = "GET_BOOSTSVIEWBYUSERID_FAILURE";
export var RESET_GET_BOOSTSVIEWBYUSERID = "RESET_GET_BOOSTSVIEWBYUSERID";

// Fetches my-boost view for a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBoostsViewByUserId = function fetchBoostsViewByUserId(userId) {
  var queryString = urlSearchParams({
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_BOOSTSVIEWBYUSERID_REQUEST, GET_BOOSTSVIEWBYUSERID_SUCCESS, GET_BOOSTSVIEWBYUSERID_FAILURE],
    endpoint: "boostsViews/search/findByUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches my-boost view for a particular user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBoostsViewByUserId = function loadBoostsViewByUserId(userId, forceUpdate) {
  return function (dispatch, getState) {
    var boostsView = getState().entities.boostsViews.byUserId[userId];
    if (boostsView && !forceUpdate) return null;
    return dispatch(fetchBoostsViewByUserId(userId));
  };
};