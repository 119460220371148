import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var goalViewApiCalls = {
  loadGoalViewByGoalIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_GOALVIEWBYGOALIDANDAUTHUSERID_REQUEST, ActionTypes.GET_GOALVIEWBYGOALIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_GOALVIEWBYGOALIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_GOALVIEWBYGOALIDANDAUTHUSERID]
  })
};
export default goalViewApiCalls;