import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var externalBalanceApiCalls = {
  loadExternalBalancesByBrokerIdAndInvestmentItemIdAndUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.investmentItemId, action.userId].join("-");
    },
    types: [ActionTypes.GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_REQUEST, ActionTypes.GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_SUCCESS, ActionTypes.GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID_FAILURE, ActionTypes.RESET_GET_BROKERINVESTMENTITEMUSEREXTERNALBALANCEBYBROKERIDANDINVESTMENTITEMIDANDUSERID]
  }),
  collectExternalBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST, ActionTypes.COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS, ActionTypes.COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE, ActionTypes.RESET_COLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES]
  }),
  abortCollectExternalBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_REQUEST, ActionTypes.ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_SUCCESS, ActionTypes.ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES_FAILURE, ActionTypes.RESET_ABORTCOLLECTBROKERINVESTMENTITEMUSEREXTERNALBALANCES]
  })
};
export default externalBalanceApiCalls;