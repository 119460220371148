import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var distributionTabApiCalls = {
  fetchDistributionTabByGoalIdAndUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.balaneDate].join("-");
    },
    types: [ActionTypes.GET_DISTRIBUTIONTABBYGOALIDANDUSERID_REQUEST, ActionTypes.GET_DISTRIBUTIONTABBYGOALIDANDUSERID_SUCCESS, ActionTypes.GET_DISTRIBUTIONTABBYGOALIDANDUSERID_FAILURE, ActionTypes.RESET_GET_DISTRIBUTIONTABBYGOALIDANDUSERID]
  })
};
export default distributionTabApiCalls;