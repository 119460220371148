function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_REQUEST = "GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_REQUEST";
export var GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS = "GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS";
export var GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_FAILURE = "GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_FAILURE";
export var RESET_GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID = "RESET_GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID";

// Fetches a particular investment item from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchInvestmentItemViewByInvestmentItemId = function fetchInvestmentItemViewByInvestmentItemId(investmentItemId, timeZoneName, authUserId) {
  var queryString = urlSearchParams({
    investmentItemId: investmentItemId,
    timeZoneName: timeZoneName,
    authUserId: authUserId
  });
  return _defineProperty({
    investmentItemId: investmentItemId,
    timeZoneName: timeZoneName,
    authUserId: authUserId
  }, CALL_API, {
    types: [GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_REQUEST, GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS, GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_FAILURE],
    endpoint: "investmentItemViews/search/findByInvestmentItemId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_REQUEST = "GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_REQUEST";
export var GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_SUCCESS = "GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_SUCCESS";
export var GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_FAILURE = "GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_FAILURE";
export var RESET_GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME = "RESET_GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME";

// Fetches a particular investment item from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvestmentItemViewByInvestmentItemUrlFriendlyName = function fetchInvestmentItemViewByInvestmentItemUrlFriendlyName(investmentItemUrlFriendlyName) {
  var queryString = urlSearchParams({
    investmentItemUrlFriendlyName: investmentItemUrlFriendlyName
  });
  return _defineProperty({
    investmentItemUrlFriendlyName: investmentItemUrlFriendlyName
  }, CALL_API, {
    types: [GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_REQUEST, GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_SUCCESS, GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_FAILURE],
    endpoint: "investmentItemViews/search/findByInvestmentItemUrlFriendlyName".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches a particular investment item by id from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemViewByInvestmentItemUrlFriendlyName = function loadInvestmentItemViewByInvestmentItemUrlFriendlyName(investmentItemUrlFriendlyName, forceUpdate) {
  return function (dispatch, getState) {
    var key = [investmentItemUrlFriendlyName].join("-");
    var view = getState().entities.investmentItemViews.byInvestmentItemUrlFriendlyName[key];
    if (view && !forceUpdate) return null;
    return dispatch(fetchInvestmentItemViewByInvestmentItemUrlFriendlyName(investmentItemUrlFriendlyName));
  };
};