import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var dashboardStatsEntity = function dashboardStatsEntity() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_SUCCESS:
      return action.response.dashboardStats;
    case ActionTypes.GET_DASHBOARDSTATS_SUCCESS:
      return action.response;
    default:
      return state;
  }
};
export default dashboardStatsEntity;