export var SET_INVERTIRONLINEPRICELINECHART_INSTANTS = "SET_INVERTIRONLINEPRICELINECHART_INSTANTS";
export var setInvertirOnlinePriceLineChartInstants = function setInvertirOnlinePriceLineChartInstants(invertirOnlineTitleId, fromInstant, toInstant) {
  return {
    invertirOnlineTitleId: invertirOnlineTitleId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    type: SET_INVERTIRONLINEPRICELINECHART_INSTANTS
  };
};
export var SET_INVERTIRONLINEPRICELINECHART_DATATYPE = "SET_INVERTIRONLINEPRICELINECHART_DATATYPE";
export var setInvertirOnlinePriceLineChartDataType = function setInvertirOnlinePriceLineChartDataType(invertirOnlineTitleId, dataType) {
  return {
    invertirOnlineTitleId: invertirOnlineTitleId,
    dataType: dataType,
    type: SET_INVERTIRONLINEPRICELINECHART_DATATYPE
  };
};