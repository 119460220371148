function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_GOALFOLLOWER_REQUEST = "POST_GOALFOLLOWER_REQUEST";
export var POST_GOALFOLLOWER_SUCCESS = "POST_GOALFOLLOWER_SUCCESS";
export var POST_GOALFOLLOWER_FAILURE = "POST_GOALFOLLOWER_FAILURE";
export var RESET_POST_GOALFOLLOWER = "RESET_POST_GOALFOLLOWER";
export var postGoalFollower = function postGoalFollower(goalFollower) {
  var endpoint = "goalFollowers";
  if (goalFollower.id) endpoint += "/".concat(goalFollower.id);
  return _defineProperty({
    goalFollower: goalFollower
  }, CALL_API, {
    types: [POST_GOALFOLLOWER_REQUEST, POST_GOALFOLLOWER_SUCCESS, POST_GOALFOLLOWER_FAILURE],
    endpoint: endpoint,
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(goalFollower),
      credentials: "same-origin"
    }
  });
};
export var DELETE_GOALFOLLOWER_REQUEST = "DELETE_GOALFOLLOWER_REQUEST";
export var DELETE_GOALFOLLOWER_SUCCESS = "DELETE_GOALFOLLOWER_SUCCESS";
export var DELETE_GOALFOLLOWER_FAILURE = "DELETE_GOALFOLLOWER_FAILURE";
export var RESET_DELETE_GOALFOLLOWER = "RESET_DELETE_GOALFOLLOWER";
export var deleteGoalFollower = function deleteGoalFollower(goalFollower) {
  return _defineProperty({
    goalFollower: goalFollower
  }, CALL_API, {
    types: [DELETE_GOALFOLLOWER_REQUEST, DELETE_GOALFOLLOWER_SUCCESS, DELETE_GOALFOLLOWER_FAILURE],
    endpoint: "goalFollowers/".concat(goalFollower.id),
    init: {
      method: "DELETE",
      credentials: "same-origin"
    }
  });
};