export var RESET_FORM_BROKER = "RESET_FORM_BROKER";
export var resetBrokerForm = function resetBrokerForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BROKER
    });
  };
};
export var RESET_FORM_CURRENCY = "RESET_FORM_CURRENCY";
export var resetCurrencyForm = function resetCurrencyForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_CURRENCY
    });
  };
};
export var RESET_FORM_CURRENCYFILTER = "RESET_FORM_CURRENCYFILTER";
export var resetCurrencyFilterForm = function resetCurrencyFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_CURRENCYFILTER
    });
  };
};
export var RESET_FORM_GOALWIZARD = "RESET_FORM_GOALWIZARD";
export var resetGoalWizardForm = function resetGoalWizardForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_GOALWIZARD
    });
  };
};
export var RESET_FORM_RISKPROFILEWIZARD = "RESET_FORM_RISKPROFILEWIZARD";
export var resetRiskProfileWizardForm = function resetRiskProfileWizardForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_RISKPROFILEWIZARD
    });
  };
};
export var RESET_FORM_BANKACCOUNT = "RESET_FORM_BANKACCOUNT";
export var resetBankAccountForm = function resetBankAccountForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BANKACCOUNT
    });
  };
};
export var RESET_FORM_PRICE = "RESET_FORM_PRICE";
export var resetPriceForm = function resetPriceForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_PRICE
    });
  };
};
export var RESET_FORM_INVESTMENTACCOUNT = "RESET_FORM_INVESTMENTACCOUNT";
export var resetInvestmentAccountForm = function resetInvestmentAccountForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTACCOUNT
    });
  };
};
export var RESET_FORM_INVESTMENTITEM = "RESET_FORM_INVESTMENTITEM";
export var resetInvestmentItemForm = function resetInvestmentItemForm() {
  return {
    type: RESET_FORM_INVESTMENTITEM
  };
};
export var RESET_FORM_ADVISORWIZARD = "RESET_FORM_ADVISORWIZARD";
export var resetAdvisorWizardForm = function resetAdvisorWizardForm() {
  return {
    type: RESET_FORM_ADVISORWIZARD
  };
};
export var RESET_FORM_EMPLOYEE = "RESET_FORM_EMPLOYEE";
export var resetEmployeeForm = function resetEmployeeForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_EMPLOYEE
    });
  };
};
export var RESET_FORM_MYBROKERSEMPLOYEE = "RESET_FORM_MYBROKERSEMPLOYEE";
export var resetMyBrokersEmployeeForm = function resetMyBrokersEmployeeForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_MYBROKERSEMPLOYEE
    });
  };
};
export var RESET_FORM_BANKHOLIDAY = "RESET_FORM_BANKHOLIDAY";
export var resetBankHolidayForm = function resetBankHolidayForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BANKHOLIDAY
    });
  };
};
export var RESET_FORM_INVESTMENTITEMLISTITEMFILTERFORM = "RESET_FORM_INVESTMENTITEMLISTITEMFILTERFORM";
export var resetInvestmentItemCardFilterForm = function resetInvestmentItemCardFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTITEMLISTITEMFILTERFORM
    });
  };
};
export var RESET_FORM_INVESTMENTACCOUNTREADINESSBYUSERIDWIZARD = "RESET_FORM_INVESTMENTACCOUNTREADINESSBYUSERIDWIZARD";
export var resetInvestmentAccountReadinessWizardForm = function resetInvestmentAccountReadinessWizardForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTACCOUNTREADINESSBYUSERIDWIZARD
    });
  };
};
export var RESET_FORM_BOOSTCURRENCYBALANCESEARCH = "RESET_FORM_BOOSTCURRENCYBALANCESEARCH";
export var resetBoostCurrencyBalanceSearchForm = function resetBoostCurrencyBalanceSearchForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BOOSTCURRENCYBALANCESEARCH
    });
  };
};
export var RESET_FORM_BROKERCURRENCYUSERBALANCESEARCH = "RESET_FORM_BROKERCURRENCYUSERBALANCESEARCH";
export var resetBrokerCurrencyUserBalanceSearchForm = function resetBrokerCurrencyUserBalanceSearchForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BROKERCURRENCYUSERBALANCESEARCH
    });
  };
};
export var RESET_FORM_CURRENCYGOALUSERBALANCESEARCH = "RESET_FORM_CURRENCYGOALUSERBALANCESEARCH";
export var resetCurrencyGoalUserBalanceSearchForm = function resetCurrencyGoalUserBalanceSearchForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_CURRENCYGOALUSERBALANCESEARCH
    });
  };
};
export var RESET_FORM_OPERATIONFILTER = "RESET_FORM_OPERATIONFILTER";
export var resetOperationFilterForm = function resetOperationFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_OPERATIONFILTER
    });
  };
};
export var RESET_FORM_USERFILTER = "RESET_FORM_USERFILTER";
export var resetUserFilterForm = function resetUserFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_USERFILTER
    });
  };
};
export var RESET_FORM_GOALFILTER = "RESET_FORM_GOALFILTER";
export var resetGoalFilterForm = function resetGoalFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_GOALFILTER
    });
  };
};
export var RESET_FORM_GOALTYPE = "RESET_FORM_GOALTYPE";
export var resetGoalTypeForm = function resetGoalTypeForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_GOALTYPE
    });
  };
};
export var RESET_FORM_BOOSTFILTER = "RESET_FORM_BOOSTFILTER";
export var resetBoostFilterForm = function resetBoostFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BOOSTFILTER
    });
  };
};
export var RESET_FORM_BOOST = "RESET_FORM_BOOST";
export var resetBoostForm = function resetBoostForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BOOST
    });
  };
};
export var RESET_FORM_INVESTMENTACCOUNTFILTER = "RESET_FORM_INVESTMENTACCOUNTFILTER";
export var resetInvestmentAccountFilterForm = function resetInvestmentAccountFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTACCOUNTFILTER
    });
  };
};
export var RESET_FORM_INVESTMENTITEMFILTER = "RESET_FORM_INVESTMENTITEMFILTER";
export var resetInvestmentItemFilterForm = function resetInvestmentItemFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTITEMFILTER
    });
  };
};
export var RESET_FORM_OPERATIONSTABOPERATIONFILTER = "RESET_FORM_OPERATIONSTABOPERATIONFILTER";
export var resetOperationsTabOperationFilterForm = function resetOperationsTabOperationFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_OPERATIONSTABOPERATIONFILTER
    });
  };
};
export var RESET_FORM_POST = "RESET_FORM_POST";
export var resetPostForm = function resetPostForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_POST
    });
  };
};
export var RESET_FORM_POSTFILTER = "RESET_FORM_POSTFILTER";
export var resetPostFilterForm = function resetPostFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_POSTFILTER
    });
  };
};
export var RESET_FORM_GOALTYPEFILTER = "RESET_FORM_GOALTYPEFILTER";
export var resetGoalTypeFilterForm = function resetGoalTypeFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_GOALTYPEFILTER
    });
  };
};
export var RESET_FORM_BROKERFILTER = "RESET_FORM_BROKERFILTER";
export var resetBrokerFilterForm = function resetBrokerFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_BROKERFILTER
    });
  };
};
export var RESET_FORM_INVESTMENTPARTYFILTER = "RESET_FORM_INVESTMENTPARTYFILTER";
export var resetInvestmentPartyFilterForm = function resetInvestmentPartyFilterForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTPARTYFILTER
    });
  };
};
export var RESET_FORM_INVESTMENTPARTY = "RESET_FORM_INVESTMENTPARTY";
export var resetInvestmentPartyForm = function resetInvestmentPartyForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_INVESTMENTPARTY
    });
  };
};
export var RESET_FORM_CASHOPERATION = "RESET_FORM_CASHOPERATION";
export var resetCashOperationForm = function resetCashOperationForm() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_FORM_CASHOPERATION
    });
  };
};