function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { camelizeKeys } from "humps";
var API_ROOT = "/api/";
//const API_ROOT = 'http://localhost:8080/api/'
//const API_ROOT = 'http://54.244.201.227/api/'

// Fetches an API response.
// This makes every API response have the same shape, regardless of how nested it was.
var callApi = function callApi(endpoint, init) {
  var fullUrl;
  if (/^(f|ht)tps?:\/\//i.test(endpoint)) fullUrl = endpoint;else fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;
  var init2 = Object.assign({}, init, {
    redirect: "manual"
  });
  return fetch(fullUrl, init2).then(function (response) {
    return response.json().then(function (json) {
      if (!response.ok) {
        json["forceLogout"] = response.status === 403;
        return Promise.reject(json);
      }

      // return (json !== null && typeof json === 'object') ? camelizeKeys(json) : { count: json }
      return json !== null && _typeof(json) === "object" ? json : {
        count: json
      };
    });
  });
};

// Action key that carries API call info interpreted by this Redux middleware.
export var CALL_API = "Call API";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (function (store) {
  return function (next) {
    return function (action) {
      var callAPI = action[CALL_API];
      if (typeof callAPI === "undefined") return next(action);
      var endpoint = callAPI.endpoint,
        init = callAPI.init;
      var types = callAPI.types;
      if (typeof endpoint === "function") endpoint = endpoint(store.getState());
      if (typeof endpoint !== "string") throw new Error("Specify a string endpoint URL.");
      if (!Array.isArray(types) || types.length !== 3) throw new Error("Expected an array of three action types.");
      if (!types.every(function (type) {
        return typeof type === "string";
      })) throw new Error("Expected action types to be strings.");
      var actionWith = function actionWith(data) {
        var finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction;
      };
      var _types = _slicedToArray(types, 3),
        requestType = _types[0],
        successType = _types[1],
        failureType = _types[2];
      next(actionWith({
        type: requestType
      }));
      return callApi(endpoint, init).then(function (response) {
        return next(actionWith({
          response: response,
          type: successType
        }));
      }, function (error) {
        return next(actionWith({
          type: failureType,
          error: error.message || "Something bad happened",
          forceLogout: error.forceLogout
        }));
      });
    };
  };
});