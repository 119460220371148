import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var displayEntities = function displayEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byAdvertisementMediaSizeAndUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS:
      return Object.assign({}, state, {
        byAdvertisementMediaSizeAndUserId: action.response.displaysByAdvertisementMediaSizeAndUserId
      });
    case ActionTypes.SET_DISPLAYBYMEDIASIZEANDUSERID:
      return Object.assign({}, state, {
        byAdvertisementMediaSizeAndUserId: action.displaysByMediaSizeAndUserId
      });
    default:
      return state;
  }
};
export default displayEntities;