export var CHECK_POSTROW = "CHECK_POSTROW";
export var checkPostRow = function checkPostRow(id) {
  return {
    id: id,
    type: CHECK_POSTROW
  };
};
export var UNCHECK_POSTROW = "UNCHECK_POSTROW";
export var uncheckPostRow = function uncheckPostRow(id) {
  return {
    id: id,
    type: UNCHECK_POSTROW
  };
};
export var CHECK_ALL_POSTROWS = "CHECK_ALL_POSTROWS";
export var checkAllPostRows = function checkAllPostRows() {
  return {
    type: CHECK_ALL_POSTROWS
  };
};
export var UNCHECK_ALL_POSTROWS = "UNCHECK_ALL_POSTROWS";
export var uncheckAllPostRows = function uncheckAllPostRows() {
  return {
    type: UNCHECK_ALL_POSTROWS
  };
};