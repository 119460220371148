import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bookmarkListItemApiCalls = {
  fetchBookmarkListItemsByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_REQUEST, ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_SUCCESS, ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_FAILURE, ActionTypes.RESET_GET_BOOKMARKLISTITEMSBYUSERID]
  })
};
export default bookmarkListItemApiCalls;