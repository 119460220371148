function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_MEMBERBYID_REQUEST = "GET_MEMBERBYID_REQUEST";
export var GET_MEMBERBYID_SUCCESS = "GET_MEMBERBYID_SUCCESS";
export var GET_MEMBERBYID_FAILURE = "GET_MEMBERBYID_FAILURE";
export var RESET_GET_MEMBERBYID = "RESET_GET_MEMBERBYID";

// Fetches a particular member from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchMemberById = function fetchMemberById(id) {
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_MEMBERBYID_REQUEST, GET_MEMBERBYID_SUCCESS, GET_MEMBERBYID_FAILURE],
    endpoint: "members/".concat(id),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches a particular member by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadMemberById = function loadMemberById(id, forceUpdate) {
  return function (dispatch, getState) {
    var member = getState().entities.members.byId[id];
    if (member && !forceUpdate) return null;
    return dispatch(fetchMemberById(id));
  };
};
export var GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST = "GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST";
export var GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS = "GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS";
export var GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE = "GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE";
export var RESET_GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL = "RESET_GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL";

// Fetches members by a particular user and states enriched for a particular boost from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchMembersByUserIdAndStateInAndConfirmedAtNotNull = function fetchMembersByUserIdAndStateInAndConfirmedAtNotNull(userId, states) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    projection: "withGoalAndRoles",
    userId: userId,
    states: states
  });
  return _defineProperty({
    userId: userId,
    states: states
  }, CALL_API, {
    types: [GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_REQUEST, GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_SUCCESS, GET_MEMBERSBYUSERIDANDSTATEINANDCONFIRMEDATISNOTNULL_FAILURE],
    endpoint: "members/search/findByUser_IdAndStateInAndConfirmedAtNotNullAndDeletedAtIsNull".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches members by a particular user and states enriched for a particular boost unless it is cached.
// Relies on Redux Thunk middleware.
export var loadMembersByUserIdAndStateInAndConfirmedAtNotNull = function loadMembersByUserIdAndStateInAndConfirmedAtNotNull(userId, states, forceUpdate) {
  return function (dispatch, getState) {
    var key = [userId, states].join("-");
    var members = getState().entities.members.byUserIdAndStateInAndConfirmedAtNotNull[key];
    if (members && !forceUpdate) return null;
    var call = getState().apiCalls.loadMembersByUserIdAndStateInAndConfirmedAtNotNull[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchMembersByUserIdAndStateInAndConfirmedAtNotNull(userId, states));
  };
};
export var GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_REQUEST = "GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_REQUEST";
export var GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_SUCCESS = "GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_SUCCESS";
export var GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_FAILURE = "GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_FAILURE";
export var RESET_GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL = "RESET_GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL";

// Fetches members by a particular user and states enriched from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchMembersByUserIdAndConfirmedAtNotNull = function fetchMembersByUserIdAndConfirmedAtNotNull(userId) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    projection: "withGoalAndRoles",
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_REQUEST, GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_SUCCESS, GET_MEMBERSBYUSERIDANDCONFIRMEDATISNOTNULL_FAILURE],
    endpoint: "members/search/findByUser_IdAndConfirmedAtNotNull".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches members by a particular user and states enriched unless it is cached.
// Relies on Redux Thunk middleware.
export var loadMembersByUserIdAndConfirmedAtNotNull = function loadMembersByUserIdAndConfirmedAtNotNull(userId, forceUpdate) {
  return function (dispatch, getState) {
    var members = getState().entities.members.byUserIdAndConfirmedAtNotNull[userId];
    if (members && !forceUpdate) return null;
    return dispatch(fetchMembersByUserIdAndConfirmedAtNotNull(userId));
  };
};
export var GET_MEMBERBYGOALIDANDUSERID_REQUEST = "GET_MEMBERBYGOALIDANDUSERID_REQUEST";
export var GET_MEMBERBYGOALIDANDUSERID_SUCCESS = "GET_MEMBERBYGOALIDANDUSERID_SUCCESS";
export var GET_MEMBERBYGOALIDANDUSERID_FAILURE = "GET_MEMBERBYGOALIDANDUSERID_FAILURE";
export var RESET_GET_MEMBERBYGOALIDANDUSERID = "RESET_GET_MEMBERBYGOALIDANDUSERID";

// Fetches a member for a particular user and goal from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchMemberByGoalIdAndUserId = function fetchMemberByGoalIdAndUserId(goalId, userId) {
  var queryString = urlSearchParams({
    projection: "full",
    goalId: goalId,
    userId: userId
  });
  return _defineProperty({
    goalId: goalId,
    userId: userId
  }, CALL_API, {
    types: [GET_MEMBERBYGOALIDANDUSERID_REQUEST, GET_MEMBERBYGOALIDANDUSERID_SUCCESS, GET_MEMBERBYGOALIDANDUSERID_FAILURE],
    endpoint: "members/search/findByGoal_IdAndUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST";
export var DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export var DELETE_MEMBER_FAILURE = "DELETE_MEMBER_FAILURE";
export var RESET_DELETE_MEMBER = "RESET_DELETE_MEMBER";
export var deleteMember = function deleteMember(member) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      member: member
    }, CALL_API, {
      types: [DELETE_MEMBER_REQUEST, DELETE_MEMBER_SUCCESS, DELETE_MEMBER_FAILURE],
      endpoint: "members/".concat(member.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var resetDeleteMember = function resetDeleteMember() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DELETE_MEMBER
    });
  };
};
export var POST_MEMBER_REQUEST = "POST_MEMBER_REQUEST";
export var POST_MEMBER_SUCCESS = "POST_MEMBER_SUCCESS";
export var POST_MEMBER_FAILURE = "POST_MEMBER_FAILURE";
export var RESET_POST_MEMBER = "RESET_POST_MEMBER";
export var postMember = function postMember(member) {
  return function (dispatch, getState) {
    var endpoint = "members";
    if (member.id) endpoint += "/".concat(member.id);
    return dispatch(_defineProperty({
      member: member
    }, CALL_API, {
      types: [POST_MEMBER_REQUEST, POST_MEMBER_SUCCESS, POST_MEMBER_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(member),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_MEMBEREXCLUSIONPREFERENCES_REQUEST = "POST_MEMBEREXCLUSIONPREFERENCES_REQUEST";
export var POST_MEMBEREXCLUSIONPREFERENCES_SUCCESS = "POST_MEMBEREXCLUSIONPREFERENCES_SUCCESS";
export var POST_MEMBEREXCLUSIONPREFERENCES_FAILURE = "POST_MEMBEREXCLUSIONPREFERENCES_FAILURE";
export var RESET_POST_MEMBEREXCLUSIONPREFERENCES = "RESET_POST_MEMBEREXCLUSIONPREFERENCES";
export var postMemberExclusionPreferences = function postMemberExclusionPreferences(member, exclusionPreferences) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      member: member,
      exclusionPreferences: exclusionPreferences
    }, CALL_API, {
      types: [POST_MEMBEREXCLUSIONPREFERENCES_REQUEST, POST_MEMBEREXCLUSIONPREFERENCES_SUCCESS, POST_MEMBEREXCLUSIONPREFERENCES_FAILURE],
      endpoint: "members/".concat(member.id, "/exclusionPreferences"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(exclusionPreferences),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostMemberExclusionPreferences = function resetPostMemberExclusionPreferences(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_MEMBEREXCLUSIONPREFERENCES
    });
  };
};