export var CHECK_MYBROKERSEMPLOYEEROW = "CHECK_MYBROKERSEMPLOYEEROW";
export var checkMyBrokersEmployeeRow = function checkMyBrokersEmployeeRow(id) {
  return {
    id: id,
    type: CHECK_MYBROKERSEMPLOYEEROW
  };
};
export var UNCHECK_MYBROKERSEMPLOYEEROW = "UNCHECK_MYBROKERSEMPLOYEEROW";
export var uncheckMyBrokersEmployeeRow = function uncheckMyBrokersEmployeeRow(id) {
  return {
    id: id,
    type: UNCHECK_MYBROKERSEMPLOYEEROW
  };
};
export var CHECK_ALL_MYBROKERSEMPLOYEEROWS = "CHECK_ALL_MYBROKERSEMPLOYEEROWS";
export var checkAllMyBrokersEmployeeRows = function checkAllMyBrokersEmployeeRows() {
  return {
    type: CHECK_ALL_MYBROKERSEMPLOYEEROWS
  };
};
export var UNCHECK_ALL_MYBROKERSEMPLOYEEROWS = "UNCHECK_ALL_MYBROKERSEMPLOYEEROWS";
export var uncheckAllMyBrokersEmployeeRows = function uncheckAllMyBrokersEmployeeRows() {
  return {
    type: UNCHECK_ALL_MYBROKERSEMPLOYEEROWS
  };
};