import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var numericConventionApiCalls = {
  fetchNumericConventions: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_NUMERICCONVENTIONS_REQUEST, ActionTypes.GET_NUMERICCONVENTIONS_SUCCESS, ActionTypes.GET_NUMERICCONVENTIONS_FAILURE, ActionTypes.RESET_GET_NUMERICCONVENTIONS]
  })
};
export default numericConventionApiCalls;