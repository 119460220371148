export var CHECK_MYBROKERSPRICEROW = "CHECK_MYBROKERSPRICEROW";
export var checkMyBrokersPriceRow = function checkMyBrokersPriceRow(id) {
  return {
    id: id,
    type: CHECK_MYBROKERSPRICEROW
  };
};
export var UNCHECK_MYBROKERSPRICEROW = "UNCHECK_MYBROKERSPRICEROW";
export var uncheckMyBrokersPriceRow = function uncheckMyBrokersPriceRow(id) {
  return {
    id: id,
    type: UNCHECK_MYBROKERSPRICEROW
  };
};
export var CHECK_ALL_MYBROKERSPRICEROWS = "CHECK_ALL_MYBROKERSPRICEROWS";
export var checkAllMyBrokersPriceRows = function checkAllMyBrokersPriceRows() {
  return {
    type: CHECK_ALL_MYBROKERSPRICEROWS
  };
};
export var UNCHECK_ALL_MYBROKERSPRICEROWS = "UNCHECK_ALL_MYBROKERSPRICEROWS";
export var uncheckAllMyBrokersPriceRows = function uncheckAllMyBrokersPriceRows() {
  return {
    type: UNCHECK_ALL_MYBROKERSPRICEROWS
  };
};