import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var documentApiCalls = {
  loadDocumentByPersonIdAndTypeId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.personId, action.documentTypeId].join("-");
    },
    types: [ActionTypes.GET_DOCUMENTBYPERSONIDANDTYPEID_REQUEST, ActionTypes.GET_DOCUMENTBYPERSONIDANDTYPEID_SUCCESS, ActionTypes.GET_DOCUMENTBYPERSONIDANDTYPEID_FAILURE, ActionTypes.RESET_GET_DOCUMENTBYPERSONIDANDTYPEID]
  }),
  postDocument: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.document.typeId.toString();
    },
    types: [ActionTypes.POST_DOCUMENT_REQUEST, ActionTypes.POST_DOCUMENT_SUCCESS, ActionTypes.POST_DOCUMENT_FAILURE, ActionTypes.RESET_POST_DOCUMENT]
  })
};
export default documentApiCalls;