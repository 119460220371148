function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_DISPLAY_REQUEST = "POST_DISPLAY_REQUEST";
export var POST_DISPLAY_SUCCESS = "POST_DISPLAY_SUCCESS";
export var POST_DISPLAY_FAILURE = "POST_DISPLAY_FAILURE";
export var RESET_POST_DISPLAY = "RESET_POST_DISPLAY";
export var postDisplay = function postDisplay(display) {
  return _defineProperty({
    display: display
  }, CALL_API, {
    types: [POST_DISPLAY_REQUEST, POST_DISPLAY_SUCCESS, POST_DISPLAY_FAILURE],
    endpoint: "displays/".concat(display.id),
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(display),
      credentials: "same-origin"
    }
  });
};
export var postDisplayFailure = function postDisplayFailure(message) {
  return {
    response: {
      message: message
    },
    type: POST_DISPLAY_FAILURE
  };
};
export var resetPostDisplays = function resetPostDisplays() {
  return {
    type: RESET_POST_DISPLAY
  };
};
export var SET_DISPLAYBYMEDIASIZEANDUSERID = "SET_DISPLAYBYMEDIASIZEANDUSERID";
export var setDisplaysByMediaSizeAndUserId = function setDisplaysByMediaSizeAndUserId(displaysByMediaSizeAndUserId) {
  return {
    displaysByMediaSizeAndUserId: displaysByMediaSizeAndUserId,
    type: SET_DISPLAYBYMEDIASIZEANDUSERID
  };
};