function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT = "RESET_GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT";

// Fetches balances for a particular goal and user and date from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat = function fetchCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat(currencyId, goalId, userId, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    projection: "chart",
    currencyId: currencyId,
    goalId: goalId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    currencyId: currencyId,
    goalId: goalId,
    userId: userId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat
  }, CALL_API, {
    types: [GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "currencyGoalUserBalances/search/findByCurrency_IdAndGoal_IdAndUser_IdAndInstantBetweenGroupByDateFormatOrderByInstantDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular goal and user and date unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat = function loadCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat(currencyId, goalId, userId, fromInstant, toInstant, dateFormat, timeZoneName, forceUpdate) {
  return function (dispatch, getState) {
    var key = [currencyId, goalId, userId, fromInstant, toInstant, dateFormat].join("-");
    var balances = getState().entities.currencyGoalUserBalances.byCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat[key];
    if (balances && !forceUpdate) return null;
    return dispatch(fetchCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat(currencyId, goalId, userId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};
export var GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT = "RESET_GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT";

// Fetches balances for a particular curency, goal, user and instant range from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat = function fetchCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat(currencyId, goalId, userIds, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    projection: "withCurrencyAndUser",
    currencyId: currencyId,
    goalId: goalId,
    userIds: userIds.join(","),
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    currencyId: currencyId,
    goalId: goalId,
    userIds: userIds,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat
  }, CALL_API, {
    types: [GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_CURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSEREIDINANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "currencyGoalUserBalances/search/findByCurrencyIdAndGoal_IdAndUserIdInAndInstantBetweenGroupByDateFormatOrderByInstantDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches balances for a particular currency, goal, user and instant range unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat = function loadCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat(currencyId, goalId, userIds, fromInstant, toInstant, dateFormat, timeZoneName) {
  return function (dispatch, getState) {
    var key = [currencyId, goalId || "null", userIds.join(","), fromInstant, toInstant, dateFormat].join("-");
    var currencyGoalUserBalances = getState().entities.currencyGoalUserBalances.byCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat[key];
    if (currencyGoalUserBalances) return null;
    return dispatch(fetchCurrencyGoalUserBalancesByCurrencyIdAndGoalIdAndUserIdInAndInstantBetweenGroupByDateFormat(currencyId, goalId, userIds, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};
export var GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_REQUEST = "GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_REQUEST";
export var GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_SUCCESS = "GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_SUCCESS";
export var GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_FAILURE = "GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_FAILURE";
export var RESET_GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC = "RESET_GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC";

// Fetches most recent balance for a particular goal and user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc = function fetchFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc(currencyId, goalId, userId) {
  var queryString = urlSearchParams({
    projection: "withCurrencyAndUser",
    currencyId: currencyId,
    goalId: goalId,
    userId: userId
  });
  return _defineProperty({
    currencyId: currencyId,
    goalId: goalId,
    userId: userId
  }, CALL_API, {
    types: [GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_REQUEST, GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_SUCCESS, GET_FIRSTCURRENCYGOALUSERBALANCESBYCURRENCYIDANDGOALIDANDUSERIDORDERBYDATEDESC_FAILURE],
    endpoint: "currencyGoalUserBalances/search/findFirstByCurrency_IdAndGoal_IdAndUser_IdOrderByInstantDesc".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches Balances for a particular goal and user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc = function loadFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc(currencyId, goalId, userId, forceUpdate) {
  return function (dispatch, getState) {
    var currencyGoalUserBalance = getState().entities.currencyGoalUserBalances.firstByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc[currencyId + "-" + goalId + "-" + userId];
    if (currencyGoalUserBalance && !forceUpdate) return null;
    var call = getState().apiCalls.loadFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc[currencyId + "-" + goalId + "-" + userId];
    if (call && call.isFetching) return null;
    return dispatch(fetchFirstCurrencyGoalUserBalanceByCurrencyIdAndGoalIdAndUserIdOrderByInstantDesc(currencyId, goalId, userId));
  };
};
export var GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST = "GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST";
export var GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS = "GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS";
export var GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE = "GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE";
export var RESET_GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT = "RESET_GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT";

// Fetches Balances for a particular goal, user and instant from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrencyGoalUserBalancesByGoalIdAndUserIdAndInstant = function fetchCurrencyGoalUserBalancesByGoalIdAndUserIdAndInstant(goalId, userId, instant) {
  var queryString = urlSearchParams({
    size: 9999,
    sort: "instant,desc",
    projection: "withCurrencyAndUser",
    goalId: goalId,
    userId: userId,
    instant: instant
  });
  return _defineProperty({
    goalId: goalId,
    userId: userId,
    instant: instant
  }, CALL_API, {
    types: [GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_REQUEST, GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS, GET_CURRENCYGOALUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_FAILURE],
    endpoint: "currencyGoalUserBalances/search/findByGoal_IdAndUser_IdAndInstant".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches Balances for a particular goal, user and instant unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCurrencyGoalUserBalancesByGoalIdAndUserIdAndInstant = function loadCurrencyGoalUserBalancesByGoalIdAndUserIdAndInstant(goalId, userId, instant) {
  return function (dispatch, getState) {
    var currencyGoalUserBalance = getState().entities.currencyGoalUserBalances.byGoalIdAndUserIdAndInstant[goalId + "-" + userId + "-" + instant];
    if (currencyGoalUserBalance) return null;
    return dispatch(fetchCurrencyGoalUserBalancesByGoalIdAndUserIdAndInstant(goalId, userId, instant));
  };
};