import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerCurrencyGoalUserBalanceApiCalls = {
  loadBrokerCurrencyGoalUserBalancesByBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.goalId === -1 ? null : action.goalId, action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYGOALUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_BROKERCURRENCYGOALUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_BROKERCURRENCYGOALUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYGOALUSERBALANCESBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default brokerCurrencyGoalUserBalanceApiCalls;