function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export var GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export var GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
export var RESET_GET_DASHBOARD = "RESET_GET_DASHBOARD";

// Fetches my-broker dashboard for a particular broker from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchDashboard = function fetchDashboard() {
  return _defineProperty({}, CALL_API, {
    types: [GET_DASHBOARD_REQUEST, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILURE],
    endpoint: "dashboards/search/",
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches my-brokers dashboard for a particular broker unless it is cached.
// Relies on Redux Thunk middleware.
export var loadDashboard = function loadDashboard(forceUpdate) {
  return function (dispatch, getState) {
    var dashboard = getState().entities.dashboards;
    if (dashboard && !forceUpdate) return null;
    return dispatch(fetchDashboard());
  };
};