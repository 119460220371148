import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var languageApiCalls = {
  fetchLanguages: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_LANGUAGES_REQUEST, ActionTypes.GET_LANGUAGES_SUCCESS, ActionTypes.GET_LANGUAGES_FAILURE, ActionTypes.RESET_GET_LANGUAGES]
  })
};
export default languageApiCalls;