import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var transactionApiCalls = {
  postTransaction: status({
    mapActionToKey: function mapActionToKey(action) {
      return "undefined";
    },
    types: [ActionTypes.POST_TRANSACTION_REQUEST, ActionTypes.POST_TRANSACTION_SUCCESS, ActionTypes.POST_TRANSACTION_FAILURE, ActionTypes.RESET_POST_TRANSACTION]
  }),
  loadTransactionByToken: status({
    mapActionToKey: function mapActionToKey(action) {
      return "undefined";
    },
    types: [ActionTypes.GET_TRANSACTIONBYTOKEN_REQUEST, ActionTypes.GET_TRANSACTIONBYTOKEN_SUCCESS, ActionTypes.GET_TRANSACTIONBYTOKEN_FAILURE, ActionTypes.RESET_GET_TRANSACTIONBYTOKEN]
  })
};
export default transactionApiCalls;