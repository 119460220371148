import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var userFollowerApiCalls = {
  postUserFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userFollower.userId, action.userFollower.followeeUsername].join("-");
    },
    types: [ActionTypes.POST_USERFOLLOWER_REQUEST, ActionTypes.POST_USERFOLLOWER_SUCCESS, ActionTypes.POST_USERFOLLOWER_FAILURE, ActionTypes.RESET_POST_USERFOLLOWER]
  }),
  deleteUserFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userFollower.id.toString();
    },
    types: [ActionTypes.DELETE_USERFOLLOWER_REQUEST, ActionTypes.DELETE_USERFOLLOWER_SUCCESS, ActionTypes.DELETE_USERFOLLOWER_FAILURE, ActionTypes.RESET_DELETE_USERFOLLOWER]
  })
};
export default userFollowerApiCalls;