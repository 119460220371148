function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CAFCICLASESBYNAMECONTAINING_REQUEST = "GET_CAFCICLASESBYNAMECONTAINING_REQUEST";
export var GET_CAFCICLASESBYNAMECONTAINING_SUCCESS = "GET_CAFCICLASESBYNAMECONTAINING_SUCCESS";
export var GET_CAFCICLASESBYNAMECONTAINING_FAILURE = "GET_CAFCICLASESBYNAMECONTAINING_FAILURE";

//Fetches all CAFCI fund classes from Front CAFCI API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadCafciClasesByNameContaining = function loadCafciClasesByNameContaining() {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CAFCICLASESBYNAMECONTAINING_REQUEST, GET_CAFCICLASESBYNAMECONTAINING_SUCCESS, GET_CAFCICLASESBYNAMECONTAINING_FAILURE],
      endpoint: "cafciClases/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTCAFCICLASESFROMFRONT_REQUEST = "COLLECTCAFCICLASESFROMFRONT_REQUEST";
export var COLLECTCAFCICLASESFROMFRONT_SUCCESS = "COLLECTCAFCICLASESFROMFRONT_SUCCESS";
export var COLLECTCAFCICLASESFROMFRONT_FAILURE = "COLLECTCAFCICLASESFROMFRONT_FAILURE";
export var RESET_COLLECTCAFCICLASESFROMFRONT = "RESET_COLLECTCAFCICLASESFROMFRONT";
export var collectCafciClasesFromFront = function collectCafciClasesFromFront(cafciClaseIds, fromInstant, toInstant) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      cafciClaseIds: cafciClaseIds,
      fromInstant: fromInstant,
      toInstant: toInstant
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTCAFCICLASESFROMFRONT_REQUEST, COLLECTCAFCICLASESFROMFRONT_SUCCESS, COLLECTCAFCICLASESFROMFRONT_FAILURE],
      endpoint: "cafciClases/collectFromFront".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTCAFCICLASESFROMFRONT_REQUEST = "ABORTCOLLECTCAFCICLASESFROMFRONT_REQUEST";
export var ABORTCOLLECTCAFCICLASESFROMFRONT_SUCCESS = "ABORTCOLLECTCAFCICLASESFROMFRONT_SUCCESS";
export var ABORTCOLLECTCAFCICLASESFROMFRONT_FAILURE = "ABORTCOLLECTCAFCICLASESFROMFRONT_FAILURE";
export var RESET_ABORTCOLLECTCAFCICLASESFROMFRONT = "RESET_ABORTCOLLECTCAFCICLASESFROMFRONT";
export var abortCollectCafciClasesFromFront = function abortCollectCafciClasesFromFront() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTCAFCICLASESFROMFRONT_REQUEST, ABORTCOLLECTCAFCICLASESFROMFRONT_SUCCESS, ABORTCOLLECTCAFCICLASESFROMFRONT_FAILURE],
      endpoint: "cafciClases/abortCollectFromFront",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_REQUEST = "GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_REQUEST";
export var GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_SUCCESS = "GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_SUCCESS";
export var GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_FAILURE = "GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_FAILURE";
export var RESET_GET_COLLECTCAFCICLASESFROMFRONTCONTEXT = "RESET_GET_COLLECTCAFCICLASESFROMFRONTCONTEXT";
export var loadCollectCafciClasesFromFrontContext = function loadCollectCafciClasesFromFrontContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_REQUEST, GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_SUCCESS, GET_COLLECTCAFCICLASESFROMFRONTCONTEXT_FAILURE],
      endpoint: "cafciClases/collectFromFrontContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};