function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var postListItemEntities = function postListItemEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byPostParentIdAndAuthUserId: {},
    byPostUserUsernameAndAuthUserId: {},
    byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn: {},
    byPostIdAndAuthUserId: {},
    byPostBookmarksId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS:
      var byPostParentIdAndAuthUserId = _objectSpread({}, state.byPostParentIdAndAuthUserId);
      var key = [action.postParentId, action.authUserId].join("-");
      if (byPostParentIdAndAuthUserId[key] && action.sinceId !== undefined) byPostParentIdAndAuthUserId[key] = action.response.reverse().concat(byPostParentIdAndAuthUserId[key]);else if (byPostParentIdAndAuthUserId[key] && action.maxId !== undefined) byPostParentIdAndAuthUserId[key] = byPostParentIdAndAuthUserId[key].concat(action.response);else byPostParentIdAndAuthUserId[key] = action.response;
      return Object.assign({}, state, {
        byPostParentIdAndAuthUserId: byPostParentIdAndAuthUserId
      });
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS:
      var byPostUserUsernameAndAuthUserId = _objectSpread({}, state.byPostUserUsernameAndAuthUserId);
      var key = [action.postUserId, action.authUserId].join("-");
      if (byPostUserUsernameAndAuthUserId[key] && action.sinceId !== undefined) byPostUserUsernameAndAuthUserId[key] = action.response.reverse().concat(byPostUserUsernameAndAuthUserId[key]);else if (byPostUserUsernameAndAuthUserId[key] && action.maxId !== undefined) byPostUserUsernameAndAuthUserId[key] = byPostUserUsernameAndAuthUserId[key].concat(action.response);else byPostUserUsernameAndAuthUserId[key] = action.response;
      // var byId = { ...state.byId };
      // action.response.forEach((post) => (byId[post.id] = post));
      return Object.assign({}, state, {
        byPostUserUsernameAndAuthUserId: byPostUserUsernameAndAuthUserId
      });
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_SUCCESS:
      var byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn = _objectSpread({}, state.byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn);
      var key = [action.postUserId, action.postGeolocationCountryIds.join(",")].join("-");
      if (byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key] && action.sinceId !== undefined) byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key] = action.response.reverse().concat(byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key]);else if (byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key] && action.maxId !== undefined) byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key] = byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key].concat(action.response);else byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn[key] = action.response;
      // var byId = { ...state.byId };
      // action.response.forEach((post) => (byId[post.id] = post));
      return Object.assign({}, state, {
        byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn: byPostUserIdNotAndPostParentIsNullAndPostEventIsNullAndPostGeolocationCountryIdIn
      });
    case ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS:
      var byPostIdAndAuthUserId = _objectSpread({}, state.byPostIdAndAuthUserId);
      var key = [action.postId, action.authUserId].join("-");
      byPostIdAndAuthUserId[key] = action.response;
      return Object.assign({}, state, {
        byPostIdAndAuthUserId: byPostIdAndAuthUserId
      });
    case ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_SUCCESS:
      var byPostBookmarksId = _objectSpread({}, state.byPostBookmarksId);
      action.response.forEach(function (bookmarkListItem) {
        return merge(byPostBookmarksId, bookmarkListItem.postListItemByPostBookmarksId);
      });
      return merge({}, state, {
        byPostBookmarksId: byPostBookmarksId
      });
    default:
      return state;
  }
};
export default postListItemEntities;