export var CHECK_MYBROKERSOPERATIONROW = "CHECK_MYBROKERSOPERATIONROW";
export var checkMyBrokersOperationRow = function checkMyBrokersOperationRow(id) {
  return {
    id: id,
    type: CHECK_MYBROKERSOPERATIONROW
  };
};
export var UNCHECK_MYBROKERSOPERATIONROW = "UNCHECK_MYBROKERSOPERATIONROW";
export var uncheckMyBrokersOperationRow = function uncheckMyBrokersOperationRow(id) {
  return {
    id: id,
    type: UNCHECK_MYBROKERSOPERATIONROW
  };
};
export var CHECK_ALL_MYBROKERSOPERATIONROWS = "CHECK_ALL_MYBROKERSOPERATIONROWS";
export var checkAllMyBrokersOperationRows = function checkAllMyBrokersOperationRows() {
  return {
    type: CHECK_ALL_MYBROKERSOPERATIONROWS
  };
};
export var UNCHECK_ALL_MYBROKERSOPERATIONROWS = "UNCHECK_ALL_MYBROKERSOPERATIONROWS";
export var uncheckAllMyBrokersOperationRows = function uncheckAllMyBrokersOperationRows() {
  return {
    type: UNCHECK_ALL_MYBROKERSOPERATIONROWS
  };
};
export var CHECK_MYBROKERSOPERATIONROWSBYSTATE = "CHECK_MYBROKERSOPERATIONROWSBYSTATE";
export var checkMyBrokersOperationRowsByState = function checkMyBrokersOperationRowsByState(state) {
  return {
    state: state,
    type: CHECK_MYBROKERSOPERATIONROWSBYSTATE
  };
};