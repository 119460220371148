import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyGoalInvestmentItemUserBalanceApiCalls = {
  loadCurrencyGoalInvestmentItemUserBalancesByCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.goalId || "null", action.investmentItemId, action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_CURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_CURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_CURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_CURRENCYGOALINVESTMENTITEMUSERBALANCESBYCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default currencyGoalInvestmentItemUserBalanceApiCalls;