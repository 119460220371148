import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var goalTypeApiCalls = {
  postGoalType: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_GOALTYPE_REQUEST, ActionTypes.POST_GOALTYPE_SUCCESS, ActionTypes.POST_GOALTYPE_FAILURE, ActionTypes.RESET_POST_GOALTYPE]
  })
};
export default goalTypeApiCalls;