import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var postReactionListItemApiCalls = {
  fetchPostReactionListItemsByPostIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.postId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_POSTREACTIONLISTITEMSBYPOSTIDANDAUTHUSERID_REQUEST, ActionTypes.GET_POSTREACTIONLISTITEMSBYPOSTIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_POSTREACTIONLISTITEMSBYPOSTIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_POSTREACTIONLISTITEMSBYPOSTIDANDAUTHUSERID]
  })
};
export default postReactionListItemApiCalls;