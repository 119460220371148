function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// export const primary = ({ theme }) => ({
//   color: "white",
//   boxShadow: "none",
//   textTransform: "none",
//   fontSize: 16,
//   padding: "6px 12px",
//   border: '1px solid',
//   lineHeight: 1.5,
//   backgroundColor: brandPrimary,
//   borderColor: brandPrimary,
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   "&:hover": {
//     color: "white",
//     backgroundColor: brandPrimaryFocus,
//     borderColor: brandPrimaryFocus,
//     boxShadow: "none",
//   },
//   "&:active": {
//     color: "white",
//     boxShadow: "none",
//     backgroundColor: brandPrimaryFocus,
//     borderColor: brandPrimaryFocus,
//   },
//   "&:focus": {
//     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
//   },
// });

var StyledButton = styled(Button, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== "icon";
  }
})(function (_ref) {
  var theme = _ref.theme,
    icon = _ref.icon;
  var style = _objectSpread({
    textTransform: "none"
  }, theme.overrides.MuiButton);
  if (icon) {
    style.minWidth = "inherit";
    style["& .MuiButton-startIcon"] = {
      marginLeft: 0,
      marginRight: 0
    };
  } else {
    style.paddingRight = "16px";
    style.paddingLeft = "16px";
  }
  return style;
});
StyledButton.defaultProps = {
  disableElevation: true
};
export default StyledButton;