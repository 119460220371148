import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var timeZoneApiCalls = {
  fetchTimeZones: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_TIMEZONES_REQUEST, ActionTypes.GET_TIMEZONES_SUCCESS, ActionTypes.GET_TIMEZONES_FAILURE, ActionTypes.RESET_GET_TIMEZONES]
  })
};
export default timeZoneApiCalls;