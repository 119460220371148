import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var countryApiCalls = {
  fetchCountries: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_COUNTRIES_REQUEST, ActionTypes.GET_COUNTRIES_SUCCESS, ActionTypes.GET_COUNTRIES_FAILURE, ActionTypes.RESET_GET_COUNTRIES]
  }),
  fetchCountriesByMarketsNotEmpty: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_COUNTRIESBYMARKETSNOTEMPTY_REQUEST, ActionTypes.GET_COUNTRIESBYMARKETSNOTEMPTY_SUCCESS, ActionTypes.GET_COUNTRIESBYMARKETSNOTEMPTY_FAILURE, ActionTypes.RESET_GET_COUNTRIESBYMARKETSNOTEMPTY]
  })
};
export default countryApiCalls;