function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var GET_SENTIMENTTYPES_REQUEST = "GET_SENTIMENTTYPES_REQUEST";
export var GET_SENTIMENTTYPES_SUCCESS = "GET_SENTIMENTTYPES_SUCCESS";
export var GET_SENTIMENTTYPES_FAILURE = "GET_SENTIMENTTYPES_FAILURE";
export var RESET_GET_SENTIMENTTYPES = "RESET_GET_SENTIMENTTYPES";

// Fetches all sentimentTypes from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchSentimentTypes = function fetchSentimentTypes() {
  return _defineProperty({}, CALL_API, {
    types: [GET_SENTIMENTTYPES_REQUEST, GET_SENTIMENTTYPES_SUCCESS, GET_SENTIMENTTYPES_FAILURE],
    endpoint: "sentimentTypes?sort=name,asc",
    init: {
      credentials: "same-origin"
    }
  });
};