export var CHECK_OPERATIONROW = "CHECK_OPERATIONROW";
export var checkOperationRow = function checkOperationRow(id) {
  return {
    id: id,
    type: CHECK_OPERATIONROW
  };
};
export var UNCHECK_OPERATIONROW = "UNCHECK_OPERATIONROW";
export var uncheckOperationRow = function uncheckOperationRow(id) {
  return {
    id: id,
    type: UNCHECK_OPERATIONROW
  };
};
export var CHECK_ALL_OPERATIONROWS = "CHECK_ALL_OPERATIONROWS";
export var checkAllOperationRows = function checkAllOperationRows() {
  return {
    type: CHECK_ALL_OPERATIONROWS
  };
};
export var UNCHECK_ALL_OPERATIONROWS = "UNCHECK_ALL_OPERATIONROWS";
export var uncheckAllOperationRows = function uncheckAllOperationRows() {
  return {
    type: UNCHECK_ALL_OPERATIONROWS
  };
};
export var CHECK_OPERATIONROWSBYSTATE = "CHECK_OPERATIONROWSBYSTATE";
export var checkOperationRowsByState = function checkOperationRowsByState(state) {
  return {
    state: state,
    type: CHECK_OPERATIONROWSBYSTATE
  };
};