import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
export default styled(Stepper)(function (_ref) {
  var color = _ref.color,
    theme = _ref.theme;
  return {
    "& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active": {
      color: theme.palette[color || "primary"].main
    },
    "& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed": {
      color: theme.palette[color || "primary"].main
    },
    "& .MuiButton-containedPrimary": {
      backgroundColor: theme.palette[color || "primary"].main
    },
    "& .MuiStepLabel-label": {
      fontSize: "0.9375rem"
    }
  };
});