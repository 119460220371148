import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerCurrencyUserBalanceApiCalls = {
  loadBrokerCurrencyUserBalancesByBrokerIdAndCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_BROKERCURRENCYUSERBALANCESBYBROKERIDANDCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default brokerCurrencyUserBalanceApiCalls;