import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var noMemberListItemEntities = function noMemberListItemEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    // TODO reuse this for the sidebar icon 'Inv. General' 
    // case ActionTypes.GET_MEMBERSVIEWBYUSERID_SUCCESS:
    //   var byUserId = action.response.noMemberListItemByUserId;
    //   return Object.assign({}, state, { byUserId });

    default:
      return state;
  }
};
export default noMemberListItemEntities;