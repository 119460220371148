import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var goalFollowerApiCalls = {
  postGoalFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalFollower.userId, action.goalFollower.goalId].join("-");
    },
    types: [ActionTypes.POST_GOALFOLLOWER_REQUEST, ActionTypes.POST_GOALFOLLOWER_SUCCESS, ActionTypes.POST_GOALFOLLOWER_FAILURE, ActionTypes.RESET_POST_GOALFOLLOWER]
  }),
  deleteGoalFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.goalFollower.id.toString();
    },
    types: [ActionTypes.DELETE_GOALFOLLOWER_REQUEST, ActionTypes.DELETE_GOALFOLLOWER_SUCCESS, ActionTypes.DELETE_GOALFOLLOWER_FAILURE, ActionTypes.RESET_DELETE_GOALFOLLOWER]
  })
};
export default goalFollowerApiCalls;