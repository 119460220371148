function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_SENTIMENTSCOREPREFERENCE_REQUEST = "POST_SENTIMENTSCOREPREFERENCE_REQUEST";
export var POST_SENTIMENTSCOREPREFERENCE_SUCCESS = "POST_SENTIMENTSCOREPREFERENCE_SUCCESS";
export var POST_SENTIMENTSCOREPREFERENCE_FAILURE = "POST_SENTIMENTSCOREPREFERENCE_FAILURE";
export var RESET_POST_SENTIMENTSCOREPREFERENCE = "RESET_POST_SENTIMENTSCOREPREFERENCE";
export var postSentimentScorePreference = function postSentimentScorePreference(sentimentScorePreference) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_SENTIMENTSCOREPREFERENCE_REQUEST, POST_SENTIMENTSCOREPREFERENCE_SUCCESS, POST_SENTIMENTSCOREPREFERENCE_FAILURE],
      endpoint: "sentimentScorePreferences",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(sentimentScorePreference),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostSentimentScorePreference = function resetPostSentimentScorePreference() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_SENTIMENTSCOREPREFERENCE
    });
  };
};