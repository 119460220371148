function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_MEDIA_REQUEST = "POST_MEDIA_REQUEST";
export var POST_MEDIA_SUCCESS = "POST_MEDIA_SUCCESS";
export var POST_MEDIA_FAILURE = "POST_MEDIA_FAILURE";
export var RESET_POST_MEDIA = "RESET_POST_MEDIA";
export var postMedia = function postMedia(media, pigtail) {
  return function (dispatch, getState) {
    var endpoint = "medias";
    if (media.id) endpoint += "/".concat(media.id);
    return dispatch(_defineProperty({
      media: media,
      pigtail: pigtail
    }, CALL_API, {
      types: [POST_MEDIA_REQUEST, POST_MEDIA_SUCCESS, POST_MEDIA_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(media),
        credentials: "same-origin"
      }
    }));
  };
};
export var postMediaFailure = function postMediaFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: POST_MEDIA_FAILURE
    });
  };
};
export var resetPostMedia = function resetPostMedia() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_MEDIA
    });
  };
};