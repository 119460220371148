import * as ActionTypes from "../../actions";
var operationRows = function operationRows() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_OPERATIONS_SUCCESS:
      return action.response._embedded ? action.response._embedded.operations : [];
    case ActionTypes.POST_OPERATION_SUCCESS:
      var embedded = action.response._embedded;
      return embedded ? state.map(function (operation) {
        return operation.id === embedded.id ? embedded : operation;
      }) : state;
    case ActionTypes.POST_OPERATIONS_SUCCESS:
      var embedded = action.response._embedded;
      return embedded ? state.map(function (operation) {
        var embeddedOperation = embedded.find(function (nOperation) {
          return nOperation.id === operation.id;
        });
        return embeddedOperation || operation;
      }) : state;
    case ActionTypes.DELETE_OPERATION_SUCCESS:
      return state.filter(function (operation) {
        return operation.id !== action.operation.id;
      });
    default:
      return state;
  }
};
export default operationRows;