import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var incomeTypeApiCalls = {
  fetchIncomeTypes: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INCOMETYPES_REQUEST, ActionTypes.GET_INCOMETYPES_SUCCESS, ActionTypes.GET_INCOMETYPES_FAILURE, ActionTypes.RESET_GET_INCOMETYPES]
  })
};
export default incomeTypeApiCalls;