function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INDUSTRIES_REQUEST = "GET_INDUSTRIES_REQUEST";
export var GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
export var GET_INDUSTRIES_FAILURE = "GET_INDUSTRIES_FAILURE";
export var RESET_GET_INDUSTRIES = "RESET_GET_INDUSTRIES";

// Fetches all industries from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchIndustries = function fetchIndustries() {
  return _defineProperty({}, CALL_API, {
    types: [GET_INDUSTRIES_REQUEST, GET_INDUSTRIES_SUCCESS, GET_INDUSTRIES_FAILURE],
    endpoint: "industries?sort=name,asc",
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_REQUEST = "GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_REQUEST";
export var GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_SUCCESS = "GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_SUCCESS";
export var GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_FAILURE = "GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_FAILURE";
export var RESET_GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN = "RESET_GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN";

// Fetches all industries from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchIndustriesByInvestmentItemsMarketCountryIdIn = function fetchIndustriesByInvestmentItemsMarketCountryIdIn(investmentItemsMarketCountryIds) {
  var queryString = urlSearchParams({
    sort: "createdAt,desc",
    investmentItemsMarketCountryIds: investmentItemsMarketCountryIds
  });
  return _defineProperty({
    investmentItemsMarketCountryIds: investmentItemsMarketCountryIds
  }, CALL_API, {
    types: [GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_REQUEST, GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_SUCCESS, GET_INDUSTRIESBYINVESMTENTITEMSMARKETCOUNTRYIDIN_FAILURE],
    endpoint: "industries/search/findByInvestmentItemsMarketCountryIdIn".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_INDUSTRIESBYNAMECONTAINING_REQUEST = "GET_INDUSTRIESBYNAMECONTAINING_REQUEST";
export var GET_INDUSTRIESBYNAMECONTAINING_SUCCESS = "GET_INDUSTRIESBYNAMECONTAINING_SUCCESS";
export var GET_INDUSTRIESBYNAMECONTAINING_FAILURE = "GET_INDUSTRIESBYNAMECONTAINING_FAILURE";
export var RESET_GET_INDUSTRIESBYNAMECONTAINING = "RESET_GET_INDUSTRIESBYNAMECONTAINING";

// Fetches brokers by name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadIndustriesByNameContaining = function loadIndustriesByNameContaining(page, size, name) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INDUSTRIESBYNAMECONTAINING_REQUEST, GET_INDUSTRIESBYNAMECONTAINING_SUCCESS, GET_INDUSTRIESBYNAMECONTAINING_FAILURE],
      endpoint: "industries/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};