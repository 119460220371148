import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var maritalStatusApiCalls = {
  fetchMaritalStatuses: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_MARITALSTATUSES_REQUEST, ActionTypes.GET_MARITALSTATUSES_SUCCESS, ActionTypes.GET_MARITALSTATUSES_FAILURE, ActionTypes.RESET_GET_MARITALSTATUSES]
  })
};
export default maritalStatusApiCalls;