import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var postApiCalls = {
  fetchPostsByUserIdNotAndMediasNotEmptyOrUrlNotNull: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_REQUEST, ActionTypes.GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_SUCCESS, ActionTypes.GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_FAILURE, ActionTypes.RESET_GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL]
  }),
  loadPostsByEventIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.eventId, action.userId].join("-");
    },
    types: [ActionTypes.GET_POSTSBYEVENTIDANDAUTHUSERID_REQUEST, ActionTypes.GET_POSTSBYEVENTIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_POSTSBYEVENTIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_POSTSBYEVENTIDANDAUTHUSERID]
  }),
  postPost: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.post.id, action.post.userId].join("-");
    },
    types: [ActionTypes.POST_POST_REQUEST, ActionTypes.POST_POST_SUCCESS, ActionTypes.POST_POST_FAILURE, ActionTypes.RESET_POST_POST]
  }),
  deletePost: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.post.id.toString();
    },
    types: [ActionTypes.DELETE_POST_REQUEST, ActionTypes.DELETE_POST_SUCCESS, ActionTypes.DELETE_POST_FAILURE, ActionTypes.RESET_DELETE_POST]
  })
};
export default postApiCalls;