function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var eventEntities = function eventEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTS_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.events.forEach(function (event) {
        return byId[event.id] = event;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        return merge(byId, eventListItem.eventById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (eventListItem) {
        return merge(byId, eventListItem.eventById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_EVENTREACTION_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      if (!byId[action.eventReaction.eventId]) return state;
      byId[action.eventReaction.eventId].reactionsCount++;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.DELETE_EVENTREACTION_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      if (!byId[action.eventReaction.event.id]) return state;
      byId[action.eventReaction.event.id].reactionsCount--;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_POST_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var key = action.post.eventId;
      var event = byId[key];
      if (event) {
        var eventCopy = _objectSpread({}, event);
        eventCopy.postsCount++;
        byId[key] = eventCopy;
      }
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.DELETE_POST_SUCCESS:
      if (!action.post.event) return state;
      var byId = _objectSpread({}, state.byId);
      var key = action.post.event.id;
      var event = byId[key];
      if (event) {
        var eventCopy = _objectSpread({}, event);
        eventCopy.postsCount--;
        byId[key] = eventCopy;
      }
      return Object.assign({}, state, {
        byId: byId
      });
    default:
      return state;
  }
};
export default eventEntities;