function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_REQUEST = "GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_REQUEST";
export var GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_SUCCESS = "GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_SUCCESS";
export var GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_FAILURE = "GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_FAILURE";
export var RESET_GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID = "RESET_GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID";

// Fetches a InvertirOnline title view for a particular id from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvertirOnlineTitleViewByInvertirOnlineTitleIdAndAuthUserId = function fetchInvertirOnlineTitleViewByInvertirOnlineTitleIdAndAuthUserId(invertirOnlineTitleId, userId) {
  var queryString = urlSearchParams({
    invertirOnlineTitleId: invertirOnlineTitleId,
    userId: userId
  });
  return _defineProperty({
    invertirOnlineTitleId: invertirOnlineTitleId,
    userId: userId
  }, CALL_API, {
    types: [GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_REQUEST, GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_SUCCESS, GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_FAILURE],
    endpoint: "invertirOnlineTitleViews/search/findByInvertirOnlineTitleIdAndAuthUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches a InvertirOnline title view for a particular id unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvertirOnlineTitleViewByInvertirOnlineTitleIdAndAuthUserId = function loadInvertirOnlineTitleViewByInvertirOnlineTitleIdAndAuthUserId(invertirOnlineTitleId, userId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [invertirOnlineTitleId, userId].join("-");
    var view = getState().entities.invertirOnlineTitleViews.byInvertirOnlineTitleIdAndAuthUserId[key];
    if (view && !forceUpdate) return null;
    return dispatch(fetchInvertirOnlineTitleViewByInvertirOnlineTitleIdAndAuthUserId(invertirOnlineTitleId, userId));
  };
};