import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var hashtagFollowerApiCalls = {
  postHashtagFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.hashtagFollower.userId, action.hashtagFollower.hashtagId].join("-");
    },
    types: [ActionTypes.POST_HASHTAGFOLLOWER_REQUEST, ActionTypes.POST_HASHTAGFOLLOWER_SUCCESS, ActionTypes.POST_HASHTAGFOLLOWER_FAILURE, ActionTypes.RESET_POST_HASHTAGFOLLOWER]
  }),
  deleteHashtagFollower: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.hashtagFollower.id.toString();
    },
    types: [ActionTypes.DELETE_HASHTAGFOLLOWER_REQUEST, ActionTypes.DELETE_HASHTAGFOLLOWER_SUCCESS, ActionTypes.DELETE_HASHTAGFOLLOWER_FAILURE, ActionTypes.RESET_DELETE_HASHTAGFOLLOWER]
  })
};
export default hashtagFollowerApiCalls;