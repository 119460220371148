import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
i18n.use(initReactI18next) // passes i18n down to react-i18next
.use(LanguageDetector).use(resourcesToBackend(function (language, namespace, callback) {
  import("./locales/".concat(language, "/").concat(namespace, ".json")).then(function (resources) {
    return callback(null, resources);
  }).catch(function (error) {
    return callback(error, null);
  });
})).init({
  fallbackLng: "es-AR",
  // default language
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;