import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var watchlistItemApiCalls = {
  postWatchlistItem: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.watchlistItem.invesetmentItemId, action.watchlistItem.userId].join("-");
    },
    types: [ActionTypes.POST_WATCHLISTITEM_REQUEST, ActionTypes.POST_WATCHLISTITEM_SUCCESS, ActionTypes.POST_WATCHLISTITEM_FAILURE, ActionTypes.RESET_POST_WATCHLISTITEM]
  }),
  deleteWatchlistItem: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.watchlistItem.id].join("-");
    },
    types: [ActionTypes.DELETE_WATCHLISTITEM_REQUEST, ActionTypes.DELETE_WATCHLISTITEM_SUCCESS, ActionTypes.DELETE_WATCHLISTITEM_FAILURE, ActionTypes.RESET_DELETE_WATCHLISTITEM]
  })
};
export default watchlistItemApiCalls;