import * as ActionTypes from "../../actions";
var cafciPrecioRows = function cafciPrecioRows() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_CAFCIPRECIOS_SUCCESS:
      return action.response._embedded ? action.response._embedded.cafciPrecios : [];
    default:
      return state;
  }
};
export default cafciPrecioRows;