import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var riskProfileApiCalls = {
  loadFirstRiskProfileByGoalIdAndUserIdOrderByCreatedAtDesc: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId].join("-");
    },
    types: [ActionTypes.GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_REQUEST, ActionTypes.GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_SUCCESS, ActionTypes.GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_FAILURE, ActionTypes.RESET_GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC]
  }),
  postRiskProfile: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_RISKPROFILE_REQUEST, ActionTypes.POST_RISKPROFILE_SUCCESS, ActionTypes.POST_RISKPROFILE_FAILURE, ActionTypes.RESET_POST_RISKPROFILE]
  }),
  loadRiskProfileById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_RISKPROFILEBYID_REQUEST, ActionTypes.GET_RISKPROFILEBYID_SUCCESS, ActionTypes.GET_RISKPROFILEBYID_FAILURE, ActionTypes.RESET_GET_RISKPROFILEBYID]
  })
};
export default riskProfileApiCalls;