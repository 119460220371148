function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
export var POST_SIGNUP_REQUEST = "POST_SIGNUP_REQUEST";
export var POST_SIGNUP_SUCCESS = "POST_SIGNUP_SUCCESS";
export var POST_SIGNUP_FAILURE = "POST_SIGNUP_FAILURE";
export var RESET_POST_SIGNUP = "RESET_POST_SIGNUP";
export var postSignup = function postSignup(user) {
  return _defineProperty({
    email: user.email
  }, CALL_API, {
    types: [POST_SIGNUP_REQUEST, POST_SIGNUP_SUCCESS, POST_SIGNUP_FAILURE],
    endpoint: "signup",
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    }
  });
};
export var resetPostSignup = function resetPostSignup() {
  return {
    type: RESET_POST_SIGNUP
  };
};
export var REGISTER_REQUEST = "REGISTER_REQUEST";
export var REGISTER_SUCCESS = "REGISTER_SUCCESS";
export var REGISTER_FAILURE = "REGISTER_FAILURE";
export var RESET_REGISTER = "RESET_REGISTER";
export var register = function register(user) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      user: user
    }, CALL_API, {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE],
      endpoint: "register",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      }
    }));
  };
};
export var registerFailure = function registerFailure(message) {
  return function (dispatch, getState) {
    return dispatch({
      response: {
        message: message
      },
      type: REGISTER_FAILURE
    });
  };
};
export var resetRegister = function resetRegister() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_REGISTER
    });
  };
};