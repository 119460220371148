function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export var GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export var GET_POSTS_FAILURE = "GET_POSTS_FAILURE";
export var loadPosts = function loadPosts(page, size, idFilterValue, userIdFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      idFilterValue: idFilterValue,
      userIdFilterValue: userIdFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_POSTS_REQUEST, GET_POSTS_SUCCESS, GET_POSTS_FAILURE],
      endpoint: "posts/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_REQUEST = "GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_REQUEST";
export var GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_SUCCESS = "GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_SUCCESS";
export var GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_FAILURE = "GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_FAILURE";
export var RESET_GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL = "RESET_GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL";
export var fetchPostsByUserIdNotAndMediasNotEmptyOrUrlNotNull = function fetchPostsByUserIdNotAndMediasNotEmptyOrUrlNotNull(userId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_REQUEST, GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_SUCCESS, GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_FAILURE],
    endpoint: "posts/search/findByUserIdNotAndMediasNotEmptyOrUrlNotNull".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_POSTSBYEVENTIDANDAUTHUSERID_REQUEST = "GET_POSTSBYEVENTIDANDAUTHUSERID_REQUEST";
export var GET_POSTSBYEVENTIDANDAUTHUSERID_SUCCESS = "GET_POSTSBYEVENTIDANDAUTHUSERID_SUCCESS";
export var GET_POSTSBYEVENTIDANDAUTHUSERID_FAILURE = "GET_POSTSBYEVENTIDANDAUTHUSERID_FAILURE";
export var RESET_GET_POSTSBYEVENTIDANDAUTHUSERID = "RESET_GET_POSTSBYEVENTIDANDAUTHUSERID";
var fetchPostsbyEventIdAndAuthUserId = function fetchPostsbyEventIdAndAuthUserId(parentEventId, userId, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    parentEventId: parentEventId,
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    parentEventId: parentEventId,
    userId: userId,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_POSTSBYEVENTIDANDAUTHUSERID_REQUEST, GET_POSTSBYEVENTIDANDAUTHUSERID_SUCCESS, GET_POSTSBYEVENTIDANDAUTHUSERID_FAILURE],
    endpoint: "posts/search/findByEvent_IdAndAuthUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var loadPostsbyEventIdAndAuthUserId = function loadPostsbyEventIdAndAuthUserId(parentEventId, userId, maxId, sinceId, count) {
  return function (dispatch, getState) {
    var key = [parentEventId, userId].join("-");
    var call = getState().apiCalls.loadPostsbyEventIdAndAuthUserId[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchPostsbyEventIdAndAuthUserId(parentEventId, userId, maxId, sinceId, count));
  };
};
export var POST_POST_REQUEST = "POST_POST_REQUEST";
export var POST_POST_SUCCESS = "POST_POST_SUCCESS";
export var POST_POST_FAILURE = "POST_POST_FAILURE";
export var RESET_POST_POST = "RESET_POST_POST";
export var postPost = function postPost(post) {
  return function (dispatch, getState) {
    var endpoint = "posts";
    if (post.id) endpoint += "/".concat(post.id);
    return dispatch(_defineProperty({
      post: post
    }, CALL_API, {
      types: [POST_POST_REQUEST, POST_POST_SUCCESS, POST_POST_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(post),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_POSTMEDIAS_REQUEST = "POST_POSTMEDIAS_REQUEST";
export var POST_POSTMEDIAS_SUCCESS = "POST_POSTMEDIAS_SUCCESS";
export var POST_POSTMEDIAS_FAILURE = "POST_POSTMEDIAS_FAILURE";
export var RESET_POST_POSTMEDIAS = "RESET_POST_POSTMEDIAS";
export var postPostMedias = function postPostMedias(post, medias) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      post: post,
      medias: medias
    }, CALL_API, {
      types: [POST_POSTMEDIAS_REQUEST, POST_POSTMEDIAS_SUCCESS, POST_POSTMEDIAS_FAILURE],
      endpoint: "posts/".concat(post.id, "/medias"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(medias),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_POSTCHOICES_REQUEST = "POST_POSTCHOICES_REQUEST";
export var POST_POSTCHOICES_SUCCESS = "POST_POSTCHOICES_SUCCESS";
export var POST_POSTCHOICES_FAILURE = "POST_POSTCHOICES_FAILURE";
export var RESET_POST_POSTCHOICES = "RESET_POST_POSTCHOICES";
export var postPostChoices = function postPostChoices(post, choices) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      post: post,
      choices: choices
    }, CALL_API, {
      types: [POST_POSTCHOICES_REQUEST, POST_POSTCHOICES_SUCCESS, POST_POSTCHOICES_FAILURE],
      endpoint: "posts/".concat(post.id, "/choices"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(choices),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_POSTINVESTMENTITEMVARIANTS_REQUEST = "POST_POSTINVESTMENTITEMVARIANTS_REQUEST";
export var POST_POSTINVESTMENTITEMVARIANTS_SUCCESS = "POST_POSTINVESTMENTITEMVARIANTS_SUCCESS";
export var POST_POSTINVESTMENTITEMVARIANTS_FAILURE = "POST_POSTINVESTMENTITEMVARIANTS_FAILURE";
export var RESET_POST_POSTINVESTMENTITEMVARIANTS = "RESET_POST_POSTINVESTMENTITEMVARIANTS";
export var postPostInvestmentItems = function postPostInvestmentItems(post, investmentItems) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      post: post,
      investmentItems: investmentItems
    }, CALL_API, {
      types: [POST_POSTINVESTMENTITEMVARIANTS_REQUEST, POST_POSTINVESTMENTITEMVARIANTS_SUCCESS, POST_POSTINVESTMENTITEMVARIANTS_FAILURE],
      endpoint: "posts/".concat(investmentItems.id, "/investmentItems"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(investmentItems),
        credentials: "same-origin"
      }
    }));
  };
};
export var DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export var DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export var DELETE_POST_FAILURE = "DELETE_POST_FAILURE";
export var RESET_DELETE_POST = "RESET_DELETE_POST";
export var deletePost = function deletePost(post) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      post: post
    }, CALL_API, {
      types: [DELETE_POST_REQUEST, DELETE_POST_SUCCESS, DELETE_POST_FAILURE],
      endpoint: "posts/".concat(post.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var DELETE_POSTS_REQUEST = "DELETE_POSTS_REQUEST";
export var DELETE_POSTS_SUCCESS = "DELETE_POSTS_SUCCESS";
export var DELETE_POSTS_FAILURE = "DELETE_POSTS_FAILURE";
export var RESET_DELETE_POSTS = "RESET_DELETE_POSTS";
export var deletePosts = function deletePosts(posts) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_POSTS_REQUEST, DELETE_POSTS_SUCCESS, DELETE_POSTS_FAILURE],
      endpoint: "posts",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(posts),
        credentials: "same-origin"
      }
    }));
  };
};