function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_KEYPHRASESBYTEXTCONTAINING_REQUEST = "GET_KEYPHRASESBYTEXTCONTAINING_REQUEST";
export var GET_KEYPHRASESBYTEXTCONTAINING_SUCCESS = "GET_KEYPHRASESBYTEXTCONTAINING_SUCCESS";
export var GET_KEYPHRASESBYTEXTCONTAINING_FAILURE = "GET_KEYPHRASESBYTEXTCONTAINING_FAILURE";
export var RESET_GET_KEYPHRASESBYTEXTCONTAINING = "RESET_GET_KEYPHRASESBYTEXTCONTAINING";

// Fetches keyphrases by text containing value from Front API.
// Relies on Redux Thunk middleware.
export var loadKeyPhrasesByTextContaining = function loadKeyPhrasesByTextContaining(page, size, text) {
  var queryString = urlSearchParams({
    page: page,
    size: size,
    text: text
  });
  return _defineProperty({}, CALL_API, {
    types: [GET_KEYPHRASESBYTEXTCONTAINING_REQUEST, GET_KEYPHRASESBYTEXTCONTAINING_SUCCESS, GET_KEYPHRASESBYTEXTCONTAINING_FAILURE],
    endpoint: "keyPhrases/search/findByTextContaining".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var POST_KEYPHRASE_REQUEST = "POST_KEYPHRASE_REQUEST";
export var POST_KEYPHRASE_SUCCESS = "POST_KEYPHRASE_SUCCESS";
export var POST_KEYPHRASE_FAILURE = "POST_KEYPHRASE_FAILURE";
export var RESET_POST_KEYPHRASE = "RESET_POST_KEYPHRASE";
export var postKeyPhrase = function postKeyPhrase(keyPhrase) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_KEYPHRASE_REQUEST, POST_KEYPHRASE_SUCCESS, POST_KEYPHRASE_FAILURE],
      endpoint: "entities",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(keyPhrase),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostKeyPhrase = function resetPostKeyPhrase() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_KEYPHRASE
    });
  };
};