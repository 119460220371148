import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var countryCountBalanceEntities = function countryCountBalanceEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byClassNameAndInstantBetweenGroupByDateFormat: {},
    byClassNameAndInstant: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_SUCCESS:
      var byClassNameAndInstantBetweenGroupByDateFormat = action.response.countryCountBalancesByClassNameAndInstantBetweenGroupByDateFormat;
      var byClassNameAndInstant = action.response.dashboardStats.countryCountBalancesByClassNameAndInstant;
      return Object.assign({}, state, {
        byClassNameAndInstantBetweenGroupByDateFormat: byClassNameAndInstantBetweenGroupByDateFormat,
        byClassNameAndInstant: byClassNameAndInstant
      });
    case ActionTypes.GET_DASHBOARDSTATS_SUCCESS:
      var byClassNameAndInstant = action.response.countryCountBalancesByClassNameAndInstant;
      return Object.assign({}, state, {
        byClassNameAndInstant: byClassNameAndInstant
      });
    default:
      return state;
  }
};
export default countryCountBalanceEntities;