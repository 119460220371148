function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var bookmarkEntities = function bookmarkEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byPostIdAndUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        return eventListItem.postListItemByPostId && Object.values(eventListItem.postListItemByPostId).forEach(function (postListItem) {
          return merge(byPostIdAndUserId, postListItem.bookmarkByPostIdAndUserId);
        });
      });
      return Object.assign({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDINANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID_SUCCESS:
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (mentionListItem) {
        return mentionListItem.postListItemByPostId && Object.values(mentionListItem.postListItemByPostId).forEach(function (postListItem) {
          return merge(byPostIdAndUserId, postListItem.bookmarkByPostIdAndUserId);
        });
      });
      return merge({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_SUCCESS:
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (bookmarkListItem) {
        return bookmarkListItem.postListItemByPostBookmarksId && Object.values(bookmarkListItem.postListItemByPostBookmarksId).forEach(function (postListItem) {
          return merge(byPostIdAndUserId, postListItem.bookmarkByPostIdAndUserId);
        });
      });
      return merge({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULLANDPOSTGEOLOCATIONCOUNTRYIDIN_SUCCESS:
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (postListItem) {
        return merge(byPostIdAndUserId, postListItem.bookmarkByPostIdAndUserId);
      });
      return Object.assign({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS:
      return merge({}, state, {
        byPostIdAndUserId: action.response.bookmarkByPostIdAndUserId
      });
    case ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_SUCCESS:
      if (!action.response) return state;
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (postListItem) {
        return merge(byPostIdAndUserId, postListItem.bookmarkByPostIdAndUserId);
      });
      return Object.assign({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.POST_BOOKMARK_SUCCESS:
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      var key = [action.bookmark.postId, action.bookmark.userId].join("-");
      byPostIdAndUserId[key] = action.response._embedded;
      return Object.assign({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.DELETE_BOOKMARK_SUCCESS:
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      Object.keys(byPostIdAndUserId).forEach(function (key) {
        if (byPostIdAndUserId[key].id === action.bookmark.id) delete byPostIdAndUserId[key];
      });
      return Object.assign({}, state, {
        byPostIdAndUserId: byPostIdAndUserId
      });
    default:
      return state;
  }
};
export default bookmarkEntities;