function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var COLLECTBINDOPERATIONS_REQUEST = "COLLECTBINDOPERATIONS_REQUEST";
export var COLLECTBINDOPERATIONS_SUCCESS = "COLLECTBINDOPERATIONS_SUCCESS";
export var COLLECTBINDOPERATIONS_FAILURE = "COLLECTBINDOPERATIONS_FAILURE";
export var RESET_COLLECTBINDOPERATIONS = "RESET_COLLECTBINDOPERATIONS";
export var collectBindTransactions = function collectBindTransactions(fromInstant, toDate) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTBINDOPERATIONS_REQUEST, COLLECTBINDOPERATIONS_SUCCESS, COLLECTBINDOPERATIONS_FAILURE],
      endpoint: "bindTransactions/collect?from=".concat(fromDate, "&to=").concat(toDate),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTBINDOPERATIONS_REQUEST = "ABORTCOLLECTBINDOPERATIONS_REQUEST";
export var ABORTCOLLECTBINDOPERATIONS_SUCCESS = "ABORTCOLLECTBINDOPERATIONS_SUCCESS";
export var ABORTCOLLECTBINDOPERATIONS_FAILURE = "ABORTCOLLECTBINDOPERATIONS_FAILURE";
export var RESET_ABORTCOLLECTBINDOPERATIONS = "RESET_ABORTCOLLECTBINDOPERATIONS";
export var abortCollectBindTransactions = function abortCollectBindTransactions() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTBINDOPERATIONS_REQUEST, ABORTCOLLECTBINDOPERATIONS_SUCCESS, ABORTCOLLECTBINDOPERATIONS_FAILURE],
      endpoint: "bindTransactions/abortCollect",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTBINDOPERATIONSCONTEXT_REQUEST = "GET_COLLECTBINDOPERATIONSCONTEXT_REQUEST";
export var GET_COLLECTBINDOPERATIONSCONTEXT_SUCCESS = "GET_COLLECTBINDOPERATIONSCONTEXT_SUCCESS";
export var GET_COLLECTBINDOPERATIONSCONTEXT_FAILURE = "GET_COLLECTBINDOPERATIONSCONTEXT_FAILURE";
export var RESET_GET_COLLECTBINDOPERATIONSCONTEXT = "RESET_GET_COLLECTBINDOPERATIONSCONTEXT";
export var loadCollectBindTransactionsContext = function loadCollectBindTransactionsContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTBINDOPERATIONSCONTEXT_REQUEST, GET_COLLECTBINDOPERATIONSCONTEXT_SUCCESS, GET_COLLECTBINDOPERATIONSCONTEXT_FAILURE],
      endpoint: "bindTransactions/collectContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BINDOPERATIONS_REQUEST = "GET_BINDOPERATIONS_REQUEST";
export var GET_BINDOPERATIONS_SUCCESS = "GET_BINDOPERATIONS_SUCCESS";
export var GET_BINDOPERATIONS_FAILURE = "GET_BINDOPERATIONS_FAILURE";
export var loadBindTransactions = function loadBindTransactions(page, size, bindIdFilterValue, frontOperationIdFilterValue, fromCreatedAtFilterValue, toCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      bindIdFilterValue: bindIdFilterValue,
      frontOperationIdFilterValue: frontOperationIdFilterValue,
      fromCreatedAtFilterValue: fromCreatedAtFilterValue,
      toCreatedAtFilterValue: toCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BINDOPERATIONS_REQUEST, GET_BINDOPERATIONS_SUCCESS, GET_BINDOPERATIONS_FAILURE],
      endpoint: "bindTransactions/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};