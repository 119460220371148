import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var myBrokersDashboardStatsApiCalls = {
  loadMyBrokersDashboardStatsByBrokerId: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_MYBROKERSDASHBOARDBYBROKERID_REQUEST, ActionTypes.GET_MYBROKERSDASHBOARDBYBROKERID_SUCCESS, ActionTypes.GET_MYBROKERSDASHBOARDBYBROKERID_FAILURE, ActionTypes.RESET_GET_MYBROKERSDASHBOARDBYBROKERID]
  })
};
export default myBrokersDashboardStatsApiCalls;