function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEEN = "GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";

// Fetches InvertirOnline share prices for a particular fund class and instant between from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvertirOnlinePricesByTitleIdAndInstantBetweenGroupByDateFormat = function fetchInvertirOnlinePricesByTitleIdAndInstantBetweenGroupByDateFormat(titleId, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    size: 999,
    sort: "instant,desc",
    projection: "chart",
    titleId: titleId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    titleId: titleId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat
  }, CALL_API, {
    types: [GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_INVERTIRONLINEPRICESBYTITLEIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "invertirOnlinePrices/search/findByTitleIdAndInstantBetweenGroupByDateFormat".concat(queryString)
  });
};

// Fetches InvertirOnline share prices for a particular fund class and date between unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvertirOnlinePricesByTitleIdAndInstantBetweenGroupByDateFormat = function loadInvertirOnlinePricesByTitleIdAndInstantBetweenGroupByDateFormat(titleId, fromInstant, toInstant, dateFormat, timeZoneName) {
  return function (dispatch, getState) {
    var key = [titleId, fromInstant, toInstant, dateFormat].join("-");
    var invertirOnlinePrices = getState().entities.invertirOnlinePrices.byTitleIdAndInstantBetweenGroupByDateFormat[key];
    if (invertirOnlinePrices) return null;
    return dispatch(fetchInvertirOnlinePricesByTitleIdAndInstantBetweenGroupByDateFormat(titleId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};
export var GET_INVERTIRONLINEPRICES_REQUEST = "GET_INVERTIRONLINEPRICES_REQUEST";
export var GET_INVERTIRONLINEPRICES_SUCCESS = "GET_INVERTIRONLINEPRICES_SUCCESS";
export var GET_INVERTIRONLINEPRICES_FAILURE = "GET_INVERTIRONLINEPRICES_FAILURE";
export var loadInvertirOnlinePrices = function loadInvertirOnlinePrices(page, size, idFilterValue, fromDateFilterValue, toDateFilterValue, fromCreatedAtFilterValue, toCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "instant,desc",
      // projection: 'withFundClassAndCurrency',
      idFilterValue: idFilterValue,
      fromDateFilterValue: fromDateFilterValue,
      toDateFilterValue: toDateFilterValue,
      fromCreatedAtFilterValue: fromCreatedAtFilterValue,
      toCreatedAtFilterValue: toCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVERTIRONLINEPRICES_REQUEST, GET_INVERTIRONLINEPRICES_SUCCESS, GET_INVERTIRONLINEPRICES_FAILURE],
      endpoint: "invertirOnlinePrices/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTINVERTIRONLINEPRICES_REQUEST = "COLLECTINVERTIRONLINEPRICES_REQUEST";
export var COLLECTINVERTIRONLINEPRICES_SUCCESS = "COLLECTINVERTIRONLINEPRICES_SUCCESS";
export var COLLECTINVERTIRONLINEPRICES_FAILURE = "COLLECTINVERTIRONLINEPRICES_FAILURE";
export var RESET_COLLECTINVERTIRONLINEPRICES = "RESET_COLLECTINVERTIRONLINEPRICES";
export var collectInvertirOnlinePrices = function collectInvertirOnlinePrices(invertirOnlineTitleIds, fromInstant, toInstant) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      invertirOnlineTitleIds: invertirOnlineTitleIds,
      fromInstant: fromInstant,
      toInstant: toInstant
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTINVERTIRONLINEPRICES_REQUEST, COLLECTINVERTIRONLINEPRICES_SUCCESS, COLLECTINVERTIRONLINEPRICES_FAILURE],
      endpoint: "invertirOnlinePrices/collect".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTINVERTIRONLINEPRICES_REQUEST = "ABORTCOLLECTINVERTIRONLINEPRICES_REQUEST";
export var ABORTCOLLECTINVERTIRONLINEPRICES_SUCCESS = "ABORTCOLLECTINVERTIRONLINEPRICES_SUCCESS";
export var ABORTCOLLECTINVERTIRONLINEPRICES_FAILURE = "ABORTCOLLECTINVERTIRONLINEPRICES_FAILURE";
export var RESET_ABORTCOLLECTINVERTIRONLINEPRICES = "RESET_ABORTCOLLECTINVERTIRONLINEPRICES";
export var abortCollectInvertirOnlinePrices = function abortCollectInvertirOnlinePrices() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTINVERTIRONLINEPRICES_REQUEST, ABORTCOLLECTINVERTIRONLINEPRICES_SUCCESS, ABORTCOLLECTINVERTIRONLINEPRICES_FAILURE],
      endpoint: "invertirOnlinePrices/abortCollect",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTINVERTIRONLINEPRICESCONTEXT_REQUEST = "GET_COLLECTINVERTIRONLINEPRICESCONTEXT_REQUEST";
export var GET_COLLECTINVERTIRONLINEPRICESCONTEXT_SUCCESS = "GET_COLLECTINVERTIRONLINEPRICESCONTEXT_SUCCESS";
export var GET_COLLECTINVERTIRONLINEPRICESCONTEXT_FAILURE = "GET_COLLECTINVERTIRONLINEPRICESCONTEXT_FAILURE";
export var RESET_GET_COLLECTINVERTIRONLINEPRICESCONTEXT = "RESET_GET_COLLECTINVERTIRONLINEPRICESCONTEXT";
export var loadCollectInvertirOnlinePricesContext = function loadCollectInvertirOnlinePricesContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTINVERTIRONLINEPRICESCONTEXT_REQUEST, GET_COLLECTINVERTIRONLINEPRICESCONTEXT_SUCCESS, GET_COLLECTINVERTIRONLINEPRICESCONTEXT_FAILURE],
      endpoint: "invertirOnlinePrices/collectContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};