import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var usersViewApiCalls = {
  fetchUsersViewByAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.authUserId.toString();
    },
    types: [ActionTypes.GET_USERSVIEWBYAUTHUSERID_REQUEST, ActionTypes.GET_USERSVIEWBYAUTHUSERID_SUCCESS, ActionTypes.GET_USERSVIEWBYAUTHUSERID_FAILURE, ActionTypes.RESET_GET_USERSVIEWBYAUTHUSERID]
  })
};
export default usersViewApiCalls;