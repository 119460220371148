import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var employeeApiCalls = {
  loadEmployeesByBrokerId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerId.toString();
    },
    types: [ActionTypes.GET_EMPLOYEESBYBROKERID_REQUEST, ActionTypes.GET_EMPLOYEESBYBROKERID_SUCCESS, ActionTypes.GET_EMPLOYEESBYBROKERID_FAILURE, ActionTypes.RESET_GET_EMPLOYEESBYBROKERID]
  }),
  postEmployee: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_EMPLOYEE_REQUEST, ActionTypes.POST_EMPLOYEE_SUCCESS, ActionTypes.POST_EMPLOYEE_FAILURE, ActionTypes.RESET_POST_EMPLOYEE]
  })
};
export default employeeApiCalls;