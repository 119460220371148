import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var currencyUserBalanceApiCalls = {
  loadCurrencyUserBalancesByCurrencyIdAndUserIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.currencyId, action.userId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_CURRENCYUSERBALANCESBYCURRENCYIDANDUSERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default currencyUserBalanceApiCalls;