import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var invertirOnlineTitleViewApiCalls = {
  loadInvertirOnlineTitleViewByInvertirOnlineTitleIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return "undefined";
    },
    types: [ActionTypes.GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_REQUEST, ActionTypes.GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_INVERTIRONLINETITLEVIEWBYINVERTIRONLINETITLEIDANDAUTHUSERID]
  })
};
export default invertirOnlineTitleViewApiCalls;