import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var balanceLineChartDatasetApiCalls = {
  fetchBalanceLineChartDatasetsByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndBalanceClassName: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.broker, action.currency, action.goalId, action.investmentItemId, action.userId, action.balanceClassName].join("-");
    },
    types: [ActionTypes.GET_BALANCELINECHARTDATASETSBYBROKERDIDANDCURRENCYIDANDGOALIDANDUSERIDANDBALANCECLASSNAME_REQUEST, ActionTypes.GET_BALANCELINECHARTDATASETSBYBROKERDIDANDCURRENCYIDANDGOALIDANDUSERIDANDBALANCECLASSNAME_SUCCESS, ActionTypes.GET_BALANCELINECHARTDATASETSBYBROKERDIDANDCURRENCYIDANDGOALIDANDUSERIDANDBALANCECLASSNAME_FAILURE, ActionTypes.RESET_GET_BALANCELINECHARTDATASETSBYBROKERDIDANDCURRENCYIDANDGOALIDANDUSERIDANDBALANCECLASSNAME]
  })
};
export default balanceLineChartDatasetApiCalls;