import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var userFollowerListItemApiCalls = {
  fetchUserFollowerListItemsByFolloweeIdAndConfirmedAtNotNullAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.followeeId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_USERFOLLOWERLISTITEMSBYFOLLOWEEIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID_REQUEST, ActionTypes.GET_USERFOLLOWERLISTITEMSBYFOLLOWEEIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID_SUCCESS, ActionTypes.GET_USERFOLLOWERLISTITEMSBYFOLLOWEEIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_USERFOLLOWERLISTITEMSBYFOLLOWEEIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID]
  }),
  fetchUserFollowerListItemsByUserIdAndConfirmedAtNotNullAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_USERFOLLOWERLISTITEMSBYUSERIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID_REQUEST, ActionTypes.GET_USERFOLLOWERLISTITEMSBYUSERIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID_SUCCESS, ActionTypes.GET_USERFOLLOWERLISTITEMSBYUSERIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_USERFOLLOWERLISTITEMSBYUSERIDANDCONFIRMEDATISNOTNULLANDAUTHUSERID]
  })
};
export default userFollowerListItemApiCalls;