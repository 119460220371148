export var SET_CURRENCYGOALUSERBALANCELINECHARTBYGOALID_PARAMS = "SET_CURRENCYGOALUSERBALANCELINECHARTBYGOALID_PARAMS";
export var setCurrencyGoalUserBalanceLineChartByGoalIdParams = function setCurrencyGoalUserBalanceLineChartByGoalIdParams(currencyId, goalId, userIds, fromInstant, toInstant, dateFormat) {
  return {
    currencyId: currencyId,
    goalId: goalId,
    userIds: userIds,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    type: SET_CURRENCYGOALUSERBALANCELINECHARTBYGOALID_PARAMS
  };
};