function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export var GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export var GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";
export var RESET_GET_COUNTRIES = "RESET_GET_COUNTRIES";

// Fetches all countries from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchCountries = function fetchCountries() {
  return _defineProperty({}, CALL_API, {
    types: [GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE],
    endpoint: "countries?sort=name,asc",
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_COUNTRIESBYNAMECONTAINING_REQUEST = "GET_COUNTRIESBYNAMECONTAINING_REQUEST";
export var GET_COUNTRIESBYNAMECONTAINING_SUCCESS = "GET_COUNTRIESBYNAMECONTAINING_SUCCESS";
export var GET_COUNTRIESBYNAMECONTAINING_FAILURE = "GET_COUNTRIESBYNAMECONTAINING_FAILURE";
export var RESET_GET_COUNTRIESBYNAMECONTAINING = "RESET_GET_COUNTRIESBYNAMECONTAINING";

//Fetches provinces by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var fetchCountriesByNameContaining = function fetchCountriesByNameContaining(page, size, name) {
  var queryString = urlSearchParams({
    page: page,
    size: size,
    projection: "public",
    name: name
  });
  return _defineProperty({}, CALL_API, {
    types: [GET_COUNTRIESBYNAMECONTAINING_REQUEST, GET_COUNTRIESBYNAMECONTAINING_SUCCESS, GET_COUNTRIESBYNAMECONTAINING_FAILURE],
    endpoint: "countries/search/findByNameContaining".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches provinces by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadCountriesByNameContaining = function loadCountriesByNameContaining(page, size, name) {
  return function (dispatch, getState) {
    return dispatch(fetchCountriesByNameContaining(page, size, name));
  };
};
export var GET_COUNTRIESBYMARKETSNOTEMPTY_REQUEST = "GET_COUNTRIESBYMARKETSNOTEMPTY_REQUEST";
export var GET_COUNTRIESBYMARKETSNOTEMPTY_SUCCESS = "GET_COUNTRIESBYMARKETSNOTEMPTY_SUCCESS";
export var GET_COUNTRIESBYMARKETSNOTEMPTY_FAILURE = "GET_COUNTRIESBYMARKETSNOTEMPTY_FAILURE";
export var RESET_GET_COUNTRIESBYMARKETSNOTEMPTY = "RESET_GET_COUNTRIESBYMARKETSNOTEMPTY";

// Fetches all banks from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchCountriesByMarketsNotEmpty = function fetchCountriesByMarketsNotEmpty() {
  return _defineProperty({}, CALL_API, {
    types: [GET_COUNTRIESBYMARKETSNOTEMPTY_REQUEST, GET_COUNTRIESBYMARKETSNOTEMPTY_SUCCESS, GET_COUNTRIESBYMARKETSNOTEMPTY_FAILURE],
    endpoint: "countries/search/findDistinctByMarketsNotEmpty?sort=createdAt,asc",
    init: {
      credentials: "same-origin"
    }
  });
};