function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CAFCICARTERASBYINFOSEMANALCLASEID_REQUEST = "GET_CAFCICARTERASBYINFOSEMANALCLASEID_REQUEST";
export var GET_CAFCICARTERASBYINFOSEMANALCLASEID_SUCCESS = "GET_CAFCICARTERASBYINFOSEMANALCLASEID_SUCCESS";
export var GET_CAFCICARTERASBYINFOSEMANALCLASEID_FAILURE = "GET_CAFCICARTERASBYINFOSEMANALCLASEID_FAILURE";
export var RESET_GET_CAFCICARTERASBYINFOSEMANALCLASEID = "RESET_GET_CAFCICARTERASBYINFOSEMANALCLASEID";

// Fetches CAFCI portfolio fractions for a particular fund class from Front CAFCI API.
// Relies on Redux Thunk middleware.
var fetchCafciCarterasbyInfoSemanalClaseId = function fetchCafciCarterasbyInfoSemanalClaseId(infoSemanalClaseId) {
  var queryString = urlSearchParams({
    infoSemanalClaseId: infoSemanalClaseId
  });
  return _defineProperty({
    infoSemanalClaseId: infoSemanalClaseId
  }, CALL_API, {
    types: [GET_CAFCICARTERASBYINFOSEMANALCLASEID_REQUEST, GET_CAFCICARTERASBYINFOSEMANALCLASEID_SUCCESS, GET_CAFCICARTERASBYINFOSEMANALCLASEID_FAILURE],
    endpoint: "cafciCarteras/search/findByInfoSemanalClaseId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches CAFCI portfolio fractions for a particular fund class unless it is cached.
// Relies on Redux Thunk middleware.
export var loadCafciCarterasbyInfoSemanalClaseId = function loadCafciCarterasbyInfoSemanalClaseId(infoWeeklyFundClassId) {
  return function (dispatch, getState) {
    var cafciCarteras = getState().entities.cafciCarteras.byInfoSemanalClaseId[infoWeeklyFundClassId];
    if (cafciCarteras) return null;
    return dispatch(fetchCafciCarterasbyInfoSemanalClaseId(infoWeeklyFundClassId));
  };
};