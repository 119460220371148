import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var eventReactionApiCalls = {
  loadEventReactionsByEventIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.eventId, action.userId].join("-");
    },
    types: [ActionTypes.GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_REQUEST, ActionTypes.GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_EVENTREACTIONSBYEVENTIDANDAUTHUSERID]
  }),
  postEventReaction: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.eventReaction.eventId].join("-");
    },
    types: [ActionTypes.POST_EVENTREACTION_REQUEST, ActionTypes.POST_EVENTREACTION_SUCCESS, ActionTypes.POST_EVENTREACTION_FAILURE, ActionTypes.RESET_POST_EVENTREACTION]
  }),
  deleteEventReaction: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.eventReaction.event.id].join("-");
    },
    types: [ActionTypes.DELETE_EVENTREACTION_REQUEST, ActionTypes.DELETE_EVENTREACTION_SUCCESS, ActionTypes.DELETE_EVENTREACTION_FAILURE, ActionTypes.RESET_DELETE_EVENTREACTION]
  })
};
export default eventReactionApiCalls;