import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var pickApiCalls = {
  postPick: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.pick.choiceId.toString();
    },
    types: [ActionTypes.POST_PICK_REQUEST, ActionTypes.POST_PICK_SUCCESS, ActionTypes.POST_PICK_FAILURE, ActionTypes.RESET_POST_PICK]
  })
};
export default pickApiCalls;