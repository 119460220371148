import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var cafciPrecioApiCalls = {
  collectCafciPreciosForAllFundClassesWithFrontInvestmentItemIdNotNull: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.COLLECTCAFCIPRECIOS_REQUEST, ActionTypes.COLLECTCAFCIPRECIOS_SUCCESS, ActionTypes.COLLECTCAFCIPRECIOS_FAILURE, ActionTypes.RESET_COLLECTCAFCIPRECIOS]
  }),
  abortCollectCafciPrecios: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTCOLLECTCAFCIPRECIOS_REQUEST, ActionTypes.ABORTCOLLECTCAFCIPRECIOS_SUCCESS, ActionTypes.ABORTCOLLECTCAFCIPRECIOS_FAILURE, ActionTypes.RESET_ABORTCOLLECTCAFCIPRECIOS]
  }),
  loadCafciPreciosByClaseIdAndFechaBetween: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_CAFCIPRECIOSBYFUNDCLASSIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default cafciPrecioApiCalls;